import { gql } from '@apollo/client'

export const shopFragment = gql`
  fragment shopParts on Shop {
    id
    name
    latitude
    longitude
    address
    desc
    code
    channel {
      id
      name
    }
    amcode {
      id
      name
    }
    region {
      id
      name
    }
    cell {
      id
      name
    }
    smd {
      id
      name
    }
    mediacenters {
      id
      mac
    }
    createdAt
    updatedAt
  }
`

export const GET_SHOPS = gql`
  query GetShops {
    shops {
      ...shopParts
    }
  }
  ${shopFragment}
`

export const SUBSCRIBE_SHOP = gql`
  subscription {
    subscribeShop {
      op
      shop {
        ...shopParts
      }
    }
  }
  ${shopFragment}
`
