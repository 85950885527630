import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { Layout, Menu, Drawer } from 'antd'
import {
  ApiOutlined,
  DeploymentUnitOutlined,
  ShopOutlined,
  TableOutlined,
  CloudOutlined,
  NodeExpandOutlined,
  ControlOutlined,
  DashboardOutlined,
  GroupOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProfileOutlined,
  ShareAltOutlined,
  TeamOutlined,
  UnorderedListOutlined,
  YoutubeOutlined,
  FileOutlined,
  LaptopOutlined,
  DatabaseOutlined,
  ContainerOutlined,
  GlobalOutlined,
  AppstoreOutlined,
} from '@ant-design/icons'
import { Box, Flex, Spacer, VStack } from "@chakra-ui/layout"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "app/reducer"
import { storeMenu } from 'features/menu'
import { MenuInfo } from 'rc-menu/lib/interface'
import fp from "lodash/fp"
import { AuthorizationLevel, logout } from "../features/auth"
import { isMobile } from 'react-device-detect'

const { Header, Sider, Content } = Layout

type MenuClickEventHandler = (info) => void

interface TitleEventEntity {
  key: string
  domEvent: Event
}

type MenuItemType = {
  type: 'item'
  key: string
  icon: JSX.Element
  title: string
  to: string
  allow: AuthorizationLevel[]
}
type SubMenuType = {
  type: 'sub-menu'
  key: string
  icon: JSX.Element
  title: string
  allow: AuthorizationLevel[]
  children: MenuItemType[]
}

type MenuType = MenuItemType | SubMenuType

export const MainLayout: FC = ({ children }) => {
  const menu = useSelector((store: RootState) => store.menu)
  const { level } = useSelector((store: RootState) => store.authReducer)

  const dispatch = useDispatch()

  const [collapsed, setCollapsed] = useState(isMobile ? true : false)
  //    const [isBreakpoint, setBreakpoint] = useState(false);

  const toggle = () => {
    setCollapsed(!collapsed)
  }

  const onMenuClick: MenuClickEventHandler = (info: MenuInfo) => {
    dispatch(storeMenu({
      openKeys: menu.openKeys as string[],
      selectedMenuKeys: [info.key] as string[]
    }))

    if (isMobile) {
      setCollapsed(true)
    }
  }

  const onSubMenuClick = (e: TitleEventEntity) => {
    if (menu.openKeys.includes(e.key)) {
      const removed = fp.remove(k => k === e.key, menu.openKeys)
      dispatch(storeMenu({
        openKeys: removed as string[],
        selectedMenuKeys: menu.selectedMenuKeys
      }))
    } else {
      dispatch(storeMenu({
        openKeys: menu.openKeys.concat(e.key) as string[],
        selectedMenuKeys: menu.selectedMenuKeys
      }))
    }
  }

  const onLogoutButtonClicked = () => {
    dispatch(logout())
  }

  const MenuOutlined: React.FC = () => {
    return (
      <Flex w="100%">
        <Box
          /*flex={1}*/
          className="trigger"
          fontSize="1.125rem"
          lineHeight={4}
          pl="1.5em"
          cursor="pointer"
          transition="color 0.3s"
          onClick={toggle}
          _hover={{color: "#1890ff"}}
        >
          {collapsed ? <MenuFoldOutlined/> : <MenuUnfoldOutlined/>}
        </Box>
        <Spacer />
        <Box
          pr="1em"
          color="#b1b1b1"
          cursor="pointer"
          onClick={() => onLogoutButtonClicked()}
        >
          로그아웃
        </Box>
      </Flex>
    )
  }

  const menuItems: MenuType[] = [
    {
      type: 'item',
      key: '/dashboard',
      icon: <DashboardOutlined/>,
      title: '대시보드',
      to: "/dashboard",
      allow: [AuthorizationLevel.ADMIN, AuthorizationLevel.OPERATOR, AuthorizationLevel.VISITOR, AuthorizationLevel.USER]
    },
    {
      type: 'sub-menu',
      key: '/status',
      icon: <UnorderedListOutlined/>,
      title: "매장 현황",
      allow: [AuthorizationLevel.ADMIN, AuthorizationLevel.OPERATOR, AuthorizationLevel.USER],
      children: [
        {
          key: '/status/shop',
          icon: <ApiOutlined/>,
          title: '접속 현황',
          to: "/status/shop",
          allow: [AuthorizationLevel.ADMIN, AuthorizationLevel.OPERATOR, AuthorizationLevel.USER],
        },
        {
          key: '/status/update',
          icon: <DeploymentUnitOutlined/>,
          title: '배포 현황',
          to: "/status/update",
          allow: [AuthorizationLevel.ADMIN, AuthorizationLevel.OPERATOR, AuthorizationLevel.USER],
        },
      ]
    } as SubMenuType,
    {
      type: 'sub-menu',
      key: '/update',
      icon: <CloudOutlined/>,
      title: "편성 및 배포",
      allow: [AuthorizationLevel.ADMIN, AuthorizationLevel.OPERATOR],
      children: [
        {
          key: '/update/pairing',
          icon: <TableOutlined/>,
          title: '편성표 관리',
          to: "/update/pairing",
          allow: [AuthorizationLevel.ADMIN, AuthorizationLevel.OPERATOR]
        },
        {
          key: '/update/dist',
          icon: <NodeExpandOutlined />,
          title: '배포 관리',
          to: "/update/dist",
          allow: [AuthorizationLevel.ADMIN, AuthorizationLevel.OPERATOR]
        },
      ]
    } as SubMenuType,
    {
      type: 'sub-menu',
      key: '/manage',
      icon: <ControlOutlined/>,
      title: "관리",
      allow: [AuthorizationLevel.ADMIN, AuthorizationLevel.OPERATOR],
      children: [
        {
          key: '/manage/shop',
          icon: <ShopOutlined/>,
          title: '매장',
          to: "/manage/shop",
          allow: [AuthorizationLevel.ADMIN, AuthorizationLevel.OPERATOR]
        },
        {
          key: '/manage/mediacenter',
          icon: <ShareAltOutlined/>,
          title: '미디어센터',
          to: "/manage/mediacenter",
          allow: [AuthorizationLevel.ADMIN]
        },
        /*
        {
          key: '/manage/smd',
          icon: <SmileOutlined />,
          title: '매장관리자',
          to: "/manage/smd",
          allow: [AuthorizationLevel.ADMIN]
        },
        */
        {
          key: '/manage/media',
          icon: <YoutubeOutlined/>,
          title: '컨텐츠',
          to: "/manage/media",
          allow: [AuthorizationLevel.ADMIN, AuthorizationLevel.OPERATOR]
        },
        {
          key: '/manage/group',
          icon: <GroupOutlined/>,
          title: 'TV 그룹',
          to: "/manage/group",
          allow: [AuthorizationLevel.ADMIN]
        },
        {
          key: '/manage/account',
          icon: <TeamOutlined/>,
          title: '사용자',
          to: "/manage/account",
          allow: [AuthorizationLevel.ADMIN]
        },
      ]
    } as SubMenuType,
    {
      type: 'sub-menu',
      key: '/system',
      icon: <LaptopOutlined />,
      title: "시스템",
      allow: [AuthorizationLevel.ADMIN],
      children: [
        {
          key: '/system/report',
          icon: <FileOutlined/>,
          title: '보고서',
          to: "/system/report",
          allow: [AuthorizationLevel.ADMIN]
        },
        /*
        {
          key: '/system/firmware',
          icon: <SaveOutlined />,
          title: '펌웨어',
          to: "/system/firmware",
          allow: [AuthorizationLevel.ADMIN]
        },
        */
      ]
    } as SubMenuType,
    {
      type: 'sub-menu',
      key: '/basedata',
      icon: <ProfileOutlined/>,
      title: "기초 데이터",
      allow: [AuthorizationLevel.ADMIN],
      children: [
        {
          key: '/basedata/division',
          icon: <DatabaseOutlined/>,
          title: '매장 분류',
          to: "/basedata/division",
          allow: [AuthorizationLevel.ADMIN]
        },
        {
          key: '/basedata/channel',
          icon: <ContainerOutlined/>,
          title: '판매 경로',
          to: "/basedata/channel",
          allow: [AuthorizationLevel.ADMIN]
        },
        {
          key: '/basedata/region',
          icon: <GlobalOutlined/>,
          title: '지역',
          to: "/basedata/region",
          allow: [AuthorizationLevel.ADMIN]
        },
        {
          key: '/basedata/cell',
          icon: <AppstoreOutlined/>,
          title: '지역셀',
          to: "/basedata/cell",
          allow: [AuthorizationLevel.ADMIN]
        },
        /*
        {
          key: '/basedata/amcode',
          icon: <BankOutlined/>,
          title: '지역셀',
          to: "/basedata/amcode",
          allow: [AuthorizationLevel.ADMIN]
        },
        */
      ]
    } as SubMenuType,
  ]

  const MenuList: React.FC = () => {
    return (
      <Menu
        theme="dark"
        mode="inline"
        defaultSelectedKeys={['/dashboard']}
        selectedKeys={[location.pathname]}
        openKeys={menu.openKeys}
      >
        {
          menuItems.map(item => {
            return item.type === 'sub-menu' ? (
              item.allow.includes(level) &&
                <Menu.SubMenu key={item.key}
                  onTitleClick={onSubMenuClick}
                  icon={item.icon}
                  title={item.title}>
                  {
                    item.children.map(child =>
                      child.allow.includes(level) && (
                        <Menu.Item key={child.key} onClick={onMenuClick} icon={child.icon}>
                          {child.title}
                          <Link to={child.to}/>
                        </Menu.Item>)
                    )
                  }
                </Menu.SubMenu>
            ) :
              (
                item.allow.includes(level) &&
                  <Menu.Item key={item.key} onClick={onMenuClick} icon={item.icon}>
                    {item.title}
                    <Link to={item.to}/>
                  </Menu.Item>
              )
          })
        }
      </Menu>
    )
  }

  return (
    <div>
      <Drawer
        placement="left"
        onClose={() => setCollapsed(true)}
        visible={isMobile && !collapsed}
        closable={false}
        bodyStyle={{ margin: 0, padding: 0, background: "#001529", overflowY: "auto" }}
      >
        <VStack m={0} p={0} border="5px solid red">
          <MenuList />
          <Box h={32} mt={16} color="#d0d0d0" textAlign="center">
            {process.env.REACT_APP_VERSION}
          </Box>
        </VStack>
      </Drawer>
      <Layout style={{height: '100vh'}}>
                
        <Sider 
          collapsedWidth="0"
          collapsible
          collapsed={isMobile || collapsed}
          trigger={null}
        >
          <Box height="32px" background="rgba(255, 255, 255, 0.2)" m={16}>
          </Box>
          <MenuList />
          <Box h={32} mt={16} color="#d0d0d0" textAlign="center">
            {process.env.REACT_APP_VERSION}
          </Box>
        </Sider>
        <Layout className="site-layout">
          <Header style={{
            background: '#fff',
            padding: 0
          }}
          >
            <MenuOutlined/>
          </Header>
          <Content
            style={{
              minHeight: "unset",
              background: '#fff',
              margin: '12px 12px',
              padding: isMobile ? 12 : 24,
              minWidth: isMobile ? "unset" : "1200px"
            }}
          >
            {children}
          </Content>
        </Layout>
      </Layout>
    </div>
  )
}
