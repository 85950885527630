import {gql} from '@apollo/client'

export const amcodeFragment = gql`
    fragment amcodeParts on Amcode {
        id
        name
        desc
        createdAt
        updatedAt
    }
`

export const GET_AMCODES = gql`
    query GetAmcodes {
        amcodes {
            ...amcodeParts
        }
    }
    ${amcodeFragment}
`

export const SUBSCRIBE_AMCODE = gql`
    subscription {
        subscribeAmcode {
            op
            amcode {
                ...amcodeParts
            }
        }
    }
    ${amcodeFragment}
`
