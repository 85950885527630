import React from 'react'
import { 
  Divider,
} from 'antd'

function ConnStatus(props) {
  const { name, color, activeCount, inactiveCount } = props
  
  return (
    <div style={{ 
      display: 'inline-block',
      height: 32,
      margin: 0,
      border: '1px solid #a0a0a0',
    }}>
      <div style={{
        display: 'table',
        backgroundColor: color,
        paddingLeft: 8,
        paddingRight: 8
      }}>
        <span style={{ 
          display: 'table-cell',
          verticalAlign: 'middle',
          fontSize: '8px',
          fontWeight: 'bold',
        }}>
          {name}
        </span>
      </div>
      <div style={{
        display: 'table',
        width: '100%',
        height: 16,
        fontSize: '8px',
      }}>
        <div style={{
          display: 'table-cell',
          verticalAlign: 'middle',
          width: '100%',
          textAlign: 'center',
        }}>
          <span style={{ color: '#808080' }}>{activeCount}</span>
          <Divider type="vertical" style={{ margin: '0px 6px 0px 6px', padding: '0px' }} />
          <span style={{ color: '#808080' }}>{inactiveCount}</span>
        </div>
      </div>
    </div>
  )
}

export { ConnStatus }