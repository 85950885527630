import React, { FC } from 'react'
import { Space, Checkbox, Empty, Tree } from 'antd'
import { ChannelDto } from "../../api/graphql/channel"
import { CheckInfo, DistManager } from "models/dist-manager"
import _ from 'lodash'
import { Center, Box } from '@chakra-ui/layout'

interface Props {
  channel?: ChannelDto
  distManagerRef: React.MutableRefObject<{ 
    update: (id: string, distManager: DistManager) => void,
    map: Map<string, DistManager>
  }>
  distManager?: DistManager
  needToRefresh: Map<string, boolean>
}

const DistEditChannelShopList: FC<Props> = ({ channel, distManagerRef, distManager }) => {
  
  function handleChannelChanged(e) {
    if (distManager) {
      const newDistManager = distManager.selectAll({checked: e.target.checked})
      setDistManager(newDistManager)
    }
  }

  function handleTreeChecked(checkedKeys, info: CheckInfo) {
    if (distManager) {
      const newDistManager = distManager.addCheckedInfo(info)
      setDistManager(newDistManager)
    }
  }

  function setDistManager(newDistManager: DistManager) {
    if (channel) {
      distManagerRef.current.update(channel.id, newDistManager)
    }
  }

  const treeData/*: DataNode[]*/ = distManager ? distManager.getTreeData() : []

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
        height: "100%",
        border: '0.5px solid #d0d0d0',
        padding: "2px"
      }}
    >
      <Center w="100%" h={32} align="center" bg="#f0f0f0">
        {
          distManager &&
            <>
              <Checkbox
                indeterminate={!distManager.isSelectAll() && distManager.hasAnyChecked()}
                checked={distManager.isSelectAll()}
                onChange={handleChannelChanged}
              >
                {channel ? channel.name : ''}
              </Checkbox>
              <Box
                as="span"
                display="block"
                fontSize="0.7rem"
              >
                ({distManager.getCheckedShopCount()} / {distManager.getShopCount()})
              </Box>
            </>
        }
      </Center>
      { treeData.length > 0 && distManager ? (
        <Tree
          checkable
          //checkStrictly
          showIcon={true}
          checkedKeys={distManager.getCheckedKeys()}
          treeData={distManager.getTreeData()}
          onCheck={(checked, info) => handleTreeChecked(checked, info)}
        />
      ) :
        <Box
          w="100%"
          h="100%"
          display="grid"
          placeItems="center"
        >
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Box>
      }
    </Space>
  )
}

export { DistEditChannelShopList }
