import { Stack } from "@chakra-ui/layout"
import React, { FC } from "react"
import Typography from "antd/lib/typography"
import { Divider } from "antd"
import { TvGroupList } from "./tv-group"
import useGroups from "../hooks/useGroups"

const PageTvGroup: FC = () => {
  const { groups } = useGroups()

  return (
    <Stack>
      <Typography.Title level={3}>
        TV 그룹 관리
      </Typography.Title>

      <Divider/>

      <TvGroupList groups={groups}/>
    </Stack>
  )
}


export { PageTvGroup }
