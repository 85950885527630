import React, { FC } from 'react'
import { Wrap } from "@chakra-ui/layout"
import { RtiMcGroup } from 'api/graphql/rti-mc'
import { GroupDto } from "api/graphql/group"
import { TvGroupStatus } from './tv-group-status'

interface Props {
  groups: RtiMcGroup[]
  allGroups: GroupDto[]
  disabled: boolean
}

const TvGroupStatusList: FC<Props> = ({ groups, allGroups, disabled }) => {

  const statuses = groups.map((group: RtiMcGroup) => {
    return <TvGroupStatus
      onlineCount={group.onlineCount}
      totalCount={group.totalCount}
      groupName={allGroups.find(g => g.id === group.id)?.name ?? ""}
      key={group.id}
      disabled={disabled}
    />
  })

  statuses.sort((g1, g2) => g1.props.groupName > g2.props.groupName ? 1 : -1)

  return <Wrap>{statuses}</Wrap>
}

export { TvGroupStatusList }