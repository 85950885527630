import {gql} from "@apollo/client"

export const ADD_DIST = gql`
    mutation AddDist(
        $distInput: DistInput!
    ) {
        addDist(
            distInput: $distInput
        ) {
            id
            name
        }
    }
`

export const UPDATE_DIST = gql`
    mutation UpdateDist(
        $distInput: DistInput!
    ) {
        updateDist(
            distInput: $distInput
        ) {
            id
            name
        }
    }
`

export const REMOVE_DIST = gql`
    mutation RemoveDist($id: ID!) {
        removeDist(
            id: $id
        )
    }
`
