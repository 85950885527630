import {Stack} from "@chakra-ui/layout"
import React, {FC} from "react"
import Typography from "antd/lib/typography"
import {Divider} from "antd"
import {FirmwareList} from "./firmware"
import useFirmwares from "../hooks/useFirmwares"

const PageFirmware: FC = () => {
  const {firmwares} = useFirmwares()

  return (
    <Stack>
      <Typography.Title level={3}>
                펌웨어 관리
      </Typography.Title>

      <Divider/>

      <FirmwareList firmwares={firmwares}/>
    </Stack>
  )
}


export {PageFirmware}
