import React, { FC, useRef } from 'react'
import { Button, Form, Space, Table, } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { ShopOutlined, } from '@ant-design/icons'
import { ShopDto } from 'api/graphql/shop'
import { Box } from '@chakra-ui/layout'
import useModalState from 'hooks/useModalState'
import { ShopDrawer } from './shop-drawer'
import usePageSize from '../../hooks/usePageSize'
import { sortByText, sortByNumber } from '../../utils'
import { createdAtColumn } from '../../common/created-at-column'
import { columnText, columnShopName, columnMacAddress } from '../../components/table'
import useChannels from '../../hooks/useChannels'
import useCells from '../../hooks/useCells'

interface Props {
  shops: ShopDto[]
}

export interface ShopFormValues {
  name: string
  channelId: string
  regionCell: [string, string]
  amcodeId?: string
  smdId?: string
  address?: string
  code?: string
  desc?: string
  mac?: string
}

const ShopList: FC<Props> = ({shops}) => {
  const [form] = Form.useForm()
  const { closeModal, modalMode, openAddModal, openEditModal, modalShowable } = useModalState()
  const { pageSize, setPageSize } = usePageSize('shop')

  const { channels } = useChannels()
  const { cells } = useCells()

  function handleItemClick(shop) {
    const formValues: ShopFormValues = {
      ...shop,
      smdId: shop.smd?.id,
      amcodeId: shop.amcode?.id,
      channelId: shop.channel.id,
      regionCell: [shop.region.id, shop.cell.id],
      // regionCell: [shop.region.name, shop.cell.name]
      mac: shop.mediacenters && shop.mediacenters.length > 0 ? shop.mediacenters[0].mac : ''
    }
    form.setFieldsValue(formValues)
    openEditModal()
  }

  const columns: ColumnsType<ShopDto> = [
    columnShopName(
      'name',
      (e1, e2) => sortByText(e1.name, e2.name),
      (value, e1) => e1.name.includes(value),
      useRef(null),
      'left',
      (record) => handleItemClick(record)
    ),
    columnMacAddress(
      ['mediacenters'],
      (e1, e2) => e1.mediacenters && e1.mediacenters.length > 0 &&
        e2.mediacenters && e2.mediacenters.length > 0 &&
        sortByText(e1.mediacenters[0].mac, e2.mediacenters[0].mac),
      (value, e1) => e1.mediacenters && e1.mediacenters.length > 0 &&
        e1.mediacenters[0].mac.includes(value),
      useRef(null),
      undefined,
    ),
    /*
    {
      title: '매장 코드',
      dataIndex: 'code',
      key: 'code',
      width: 120,
      sorter: (shopDto1, shopDto2) => sortByText(shopDto1.code, shopDto2.code),
      showSorterTooltip: false,
    },
    */
    columnText("판매 경로", ["channel", "name"], "channel", 120, "left",
      { filters: channels.map((channel) => ({ text: channel.name, value: channel.name })) }
    ),
    columnText("지역", ["cell", "name"], "cell", 100, "left",
      { filters: cells.map((cell) => ({ text: cell.name, value: cell.name })) }
    ),
    /*
    {
      title: '매장관리자',
      dataIndex: 'smd',
      key: 'smd',
      width: 80,
      render: smd => <span>{smd?.name}</span>,
      sorter: (shopDto1, shopDto2) => sortByText(shopDto1.smd?.name, shopDto2.smd?.name),
      showSorterTooltip: false,
    },
    */
    createdAtColumn('createdAt', (e1, e2) => sortByNumber(e1.createdAt, e2.createdAt)),
  ]

  const onDrawerOpen = () => {
    form.resetFields()
    openAddModal()
  }

  return (
    <>
      <Box w="100%" textAlign="right" mb="10px">
        <Space direction="horizontal">
          <Button type="primary" icon={<ShopOutlined/>}
            onClick={onDrawerOpen}
          >
            추가
          </Button>
        </Space>
      </Box>
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        scroll={{ x: 1000/*true*/ }}
        dataSource={shops}
        onChange={(pagination) => setPageSize(pagination.pageSize)}
        pagination={{ showSizeChanger: true, pageSize }}
      />
      <ShopDrawer
        shops={shops}
        form={form}
        visible={modalShowable}
        modalMode={modalMode}
        onClose={closeModal}
      />
    </>
  )
}

export { ShopList }
