import React from 'react'
import { ChannelList } from './channel'
import { Stack } from "@chakra-ui/layout"
import useChannels from "../hooks/useChannels"

function PageBasedataChannel() {
  const { allChannels, channels } = useChannels()

  return (
    <Stack>
      <ChannelList channels={channels}/>
    </Stack>
  )
}

export { PageBasedataChannel }
