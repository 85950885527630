import { gql } from '@apollo/client'

export const ADD_CHANNEL = gql`
  mutation AddChannel(
    $name: String!
    $desc: String
    $divisionId: String!
  ) {
    addChannel(
      name: $name
      desc: $desc
      divisionId: $divisionId
    ) {
      id
      name
      desc
      division {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`

export const UPDATE_CHANNEL = gql`
  mutation UpdateChannel(
    $id: ID!
    $name: String!
    $desc: String
    $divisionId: String!
  ) {
    updateChannel(
      id: $id
      name: $name
      desc: $desc
      divisionId: $divisionId
    ) {
      id
      name
      desc
      division {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`

export const REMOVE_CHANNEL = gql`
  mutation RemoveChannel($id: ID!) {
    removeChannel(
      id: $id
    )
  }
`

export const SUBSCRIBE_CHANNEL = gql`
  subscription {
    subscribeChannel {
      op
      channel {
        id
        name
        desc
        division {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`
