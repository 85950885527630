import React from 'react'
import { DivisionList } from './division'
import { Stack } from "@chakra-ui/layout"
import useDivisions from "../hooks/useDivisions"

function PageBasedataDivision() {
  const { allDivisions, divisions } = useDivisions()

  return (
    <Stack>
      <DivisionList divisions={divisions}/>
    </Stack>
  )
}

export { PageBasedataDivision }
