import {useMutation} from "@apollo/client"
import {Stack} from "@chakra-ui/layout"
import React, {FC} from "react"
import Typography from "antd/lib/typography"
import {Divider, Form, Input, Button} from "antd"
import TextArea from "antd/lib/input/TextArea"
import useRtiMcs from "../hooks/useRtiMcs"
import {SEND_TEXT_TO_MEDIACENTER} from "api/graphql/mediacenter/mutations"

const PageTest: FC = () => {
  const {rtiMcs} = useRtiMcs()
  const [sendTextToMediacenter] = useMutation(SEND_TEXT_TO_MEDIACENTER)

  const [form] = Form.useForm()

  function handleSendTextToMediacenter() {
    console.log('====> 1 ')
    const mac = form.getFieldValue('mac')
    const rtiMc = rtiMcs.find(rtiMc => rtiMc.mediacenter.mac === mac)

    if (rtiMc) {
      const params = {
        variables: {
          id: rtiMc.id,
          text: form.getFieldValue('text')
        }
      }
        
      sendTextToMediacenter(params)
        .then(() => {
          console.log('sendTextToMediacenter success')
          /*
                    setGroupManager(new GroupManager([]));
                    form.resetFields();

                    notification.success({
                        message: `성공적으로 편성표를 생성했습니다.`
                    });

                    onClose();
                    */
        })
        .catch(e => {
          /*
                    notification.error({
                        message: `편성표 생성 중에 에러가 발생했습니다.`
                    });
                    */
          console.error(e)
        })
    }
  }
  return (
    <Stack>
      <Typography.Title level={3}>
                테스트
      </Typography.Title>

      <Divider/>

      <Form
        id="form"
        layout="vertical"
        labelAlign="left"
        form={form}
        onFinish={handleSendTextToMediacenter}
      >
        <Form.Item
          name="mac"
          label="MAC"
          rules={[{required: true, message: 'MAC을 입력해 주세요.'}]}
        >
          <Input placeholder="MAC을 입력해 주세요."/>
        </Form.Item>

        <Form.Item
          name="text"
          label="텍스트"
        >
          <TextArea autoSize={{ minRows: 10, maxRows: 100 }} 
            placeholder="텍스트를 입력해 주세요."
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
                        Submit
          </Button>
        </Form.Item>
      </Form>
    </Stack>
  )
}


export {PageTest}
