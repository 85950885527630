import { gql } from '@apollo/client'

export const GET_RTI_MCS = gql`
  query GetRtiMcs {
    rtiMcs {
      id
      state
      mediacenter {
        id
        mac
        serial
        mode
        recognizedAt
        connectedAt
        disconnectedAt
        createdAt
        updatedAt
        channel {
          id
          name
        }
        region {
          id
          name
        }
        cell {
          id
          name
        }
        shop {
          id
          name
        }
        desc
      }
      isManual
      pairingId
      distId
      media {
        assignedCount
        confirmedCount
        assignedAt
        confirmedAt
        assignedMediaIds
        confirmedMediaIds
      }
      groups {
        id
        tvs {
          id
          serial
          state
        }
        totalCount
        unknownCount
        onlineCount
        offlineCount
        errorCount
      }
      isDebug
      updatedAt
    }
  }
`

export const SUBSCRIBE_RTI_MC = gql`
  subscription {
    subscribeRtiMc {
      op
      rtiMc {
        id
        state
        mediacenter {
          id
          mac
          serial
          mode
          recognizedAt
          connectedAt
          disconnectedAt
          createdAt
          updatedAt
          channel {
            id
            name
          }
          region {
            id
            name
          }
          cell {
            id
            name
          }
          shop {
            id
            name
          }
          desc
        }
        isManual
        pairingId
        distId
        media {
          assignedCount
          confirmedCount
          assignedAt
          confirmedAt
          assignedMediaIds
          confirmedMediaIds
        }
        groups {
          id
          tvs {
            id
            serial
            state
          }
          totalCount
          unknownCount
          onlineCount
          offlineCount
          errorCount
        }
        isDebug
        updatedAt
      }
    }
  }
`
