import {gql} from "@apollo/client"
import {cellFragment} from "./queries"

export const ADD_CELL = gql`
    mutation AddCell(
        $name: String!
        $regionId: String!
    ) {
        addCell(
            name: $name
            regionId: $regionId
        ) {
            ...cellParts
        }
    }
    ${cellFragment}
`

export const REMOVE_CELL = gql`
    mutation RemoveCell(
        $id: ID!
    ) {
        removeCell(
            id: $id
        ) 
    }
`

export const UPDATE_CELL = gql`
    mutation UpdateCell(
        $id: ID!
        $name: String!
        $regionId: String!
        $desc: String
    ) {
        updateCell(
            id: $id
            name: $name
            regionId: $regionId
            desc: $desc
        ) {
            ...cellParts
        }
    }
    ${cellFragment}
`
