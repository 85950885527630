import React, { FC, useRef } from 'react'
import { Button, Form, Space, Table, Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { DatabaseOutlined, } from '@ant-design/icons'
import { RegionDto } from 'api/graphql/region'
import { Box } from '@chakra-ui/layout'
import useModalState from 'hooks/useModalState'
import { RegionEditDrawer } from './edit'
import usePageSize from '../../hooks/usePageSize'
import { sortByNumber } from '../../utils'
import { createdAtColumn } from '../../common/created-at-column'
import useCells from '../../hooks/useCells'
import { columnText } from '../../components/table'

interface Props {
  regions: RegionDto[]
}

export interface RegionFormValues {
  id: string
  name: string
  desc?: string
}

const RegionList: FC<Props> = ({ regions }) => {
  const { cells } = useCells()

  const [form] = Form.useForm()
  const { closeModal, modalMode, openAddModal, openEditModal, modalShowable } = useModalState()
  const { pageSize, setPageSize } = usePageSize('region')

  const columns: ColumnsType<RegionDto> = [
    columnText("지역", "name", "name", 160, "left",
      { searchInputRef: useRef(null) },
      (region) => {
        const formValues: RegionFormValues = {
          id: region.id,
          name: region.name,
          desc: region.desc,
        }
        form.setFieldsValue(formValues)
        openEditModal()
      }
    ),
    {
      title: '지역셀 리스트',
      dataIndex: 'cells',
      key: 'cells',
      //width: 160,
      render: (_, region) => {
        return <Space size={[0, 8]} wrap>
          { cells.filter(cell => cell.region.id === region.id).map(cell => <Tag key={cell.id}>{cell.name}</Tag>) }
        </Space>
      },
      showSorterTooltip: false,
    },

    createdAtColumn('createdAt', (e1, e2) => sortByNumber(e1.createdAt, e2.createdAt)),
  ]
  
  const onDrawerOpen = () => {
    form.resetFields()
    openAddModal()
  }

  return (
    <>
      <Box w="100%" textAlign="right" mb="10px">
        <Space direction="horizontal">
          <Button type="primary" icon={<DatabaseOutlined/>}
            onClick={onDrawerOpen}
          >
            추가
          </Button>
        </Space>
      </Box>
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        scroll={{ x: 1000/*true*/ }}
        dataSource={regions}
        onChange={(pagination) => setPageSize(pagination.pageSize)}
        pagination={{ showSizeChanger: true, pageSize }}
      />
      <RegionEditDrawer
        regions={regions}
        cells={cells}
        form={form}
        visible={modalShowable}
        modalMode={modalMode}
        onClose={closeModal}
      />
    </>
  )
}

export { RegionList }
