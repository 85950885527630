import _ from "lodash"
import {BaseDataType, Mode} from "../pages/basedata/basedata-card"

interface Args {
    type: BaseDataType;
    id?: string;
    name: string;
    mode: Mode;
    additionalData?: string;
}

export function getMutationVariables({type, id, name, additionalData, mode}: Args): Record<string, string | undefined> {
  if (mode === 'delete') {
    return {id: name}
  }

  const fromBase = (base: any) => {
    if (mode === 'add') {
      return base
    } else {
      return _.merge(base, {id})
    }
  }

  switch (type) {
  case "cell": {
    const base = {name, regionId: additionalData}

    return fromBase(base)
  }
  case "division": {
    const base = {name}

    return fromBase(base)
  }
  case "channel": {
    const base = {name, divisionId: additionalData}

    return fromBase(base)
  }
  case "region": {
    const base = {name}
    return fromBase(base)
  }
  case "amcode": {
    const base = {name}
    return fromBase(base)
  }
  default:
    return {}
  }
}
