import { gql } from '@apollo/client'

export const regionFragment = gql`
  fragment regionParts on Region {
    id
    name
    desc
    createdAt
    updatedAt
  }
`

export const GET_REGIONS = gql`
  query GetRegions {
    regions {
      ...regionParts
    }
  }
  ${regionFragment}
`

export const SUBSCRIBE_REGION = gql`
  subscription {
    subscribeRegion {
      op
      region {
        id
        name
        desc
        createdAt
        updatedAt
      }
    }
  }
`
