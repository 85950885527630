import React from 'react'
import { CellList } from './cell'
import { Stack } from "@chakra-ui/layout"
import useCells from "../hooks/useCells"

function PageBasedataCell() {
  const { allCells, cells } = useCells()

  return (
    <Stack>
      <CellList cells={cells}/>
    </Stack>
  )
}

export { PageBasedataCell }
