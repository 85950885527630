import {GET_REPORTS, ReportDto, Reports, SUBSCRIBE_REPORT} from "../api/graphql/report"
import useDtos, {LoadingAndError} from "./useDtos"
import {afterProvided} from "../utils"

interface Result extends LoadingAndError {
    reports: ReportDto[];
}

const useReports = (): Result => {
  const {allDtos: reports, loading, error} = useDtos<ReportDto, Reports>({
    query: GET_REPORTS,
    queryResultKey: 'excels',
    subscription: SUBSCRIBE_REPORT,
    updateQueryFunction: afterProvided({
      queryResultKey: 'excels',
      subscriptionResultKey: 'subscribeExcel',
      dataKey: 'report'
    })
  })

  return {reports, loading, error}
}

export default useReports
