import React from 'react'
import { MediacenterList } from './mediacenter'
import { Stack } from "@chakra-ui/layout"
import useRtiMcs from "../hooks/useRtiMcs"

function PageMediacenter() {
  const { rtiMcs } = useRtiMcs()

  return (
    <Stack>
      <MediacenterList rtiMcs={rtiMcs}/>
    </Stack>
  )
}

export { PageMediacenter }
