import {Box} from "@chakra-ui/layout"
import {Alert} from "antd"
import {GraphqlErrorFormatter} from "../../utils"
import React, {FC} from "react"
import {ApolloError} from "@apollo/client"

interface Props {
    error?: ApolloError;
}

const DrawerErrorAlert: FC<Props> = ({error}) => {
  if (!error) {
    return (
      <>
      </>
    )
  }

  return (
    <Box my="8px">
      <Alert type="error" message={(new GraphqlErrorFormatter(error)).toString()}
        showIcon/>
    </Box>
  )
}

export default DrawerErrorAlert
