import React, { useRef, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { DatePicker, Drawer, Form, Input, notification, Select } from 'antd'
import { GET_PAIRINGS, Pairings, } from 'api/graphql/pairing'
import { DistDto } from 'api/graphql/dist'
import { DistEditChannel } from './edit-channel'
import { useTableContext } from "../../contexts/TableContextProvider"
import DrawerFooter from "../../components/common/drawer-footer"
import { useForm } from "antd/lib/form/Form"
import { Moment } from "moment"
import { DistManager } from "../../models/dist-manager"
import { DistDrawerId } from "./list"
import { ADD_DIST, REMOVE_DIST, UPDATE_DIST } from "../../api/graphql/dist/mutations"
import useChannels from "../../hooks/useChannels"
import { getResponsiveDrawerWidth } from 'common/layouts'
import useShops from "../../hooks/useShops"
import useCells from "../../hooks/useCells"
import useRtiMcs from "../../hooks/useRtiMcs"
import _ from 'lodash'

interface FormValues {
  id?: string
  name: string
  pairing: string
  reserveAt?: number
}

type DistManagerHandler = {
  update: (id: string, distManager: DistManager) => void,
  map: Map<string, DistManager>
}

const { Option } = Select

interface Props {
  editable: boolean
}

const DistEdit: React.FC<Props> = ({ editable }) => {

  const { allCells: cells } = useCells()
  const { allShops } = useShops()
  const { allRtiMcs } = useRtiMcs()
  const { data: dataPairings } = useQuery<Pairings>(GET_PAIRINGS)
  const { channels } = useChannels()

  const [form] = useForm()

  const [isLoaded, setIsLoaded] = useState(false)
  const [needToRefresh, setNeedToRefresh] = useState<Map<string, boolean>>(new Map())

  const updateDistManager = (channelId: string, newDistManager: DistManager) => {

    distManagerRef.current.map.set(channelId, newDistManager)

    const newNeedToRefresh = new Map()
    needToRefresh.forEach((value, key, map) => {
      if (key === channelId) {
        newNeedToRefresh.set(key, true)
      } else {
        newNeedToRefresh.set(key, false)
      }
    })
    
    setNeedToRefresh(newNeedToRefresh)
  }

  const distManagerRef = useRef<DistManagerHandler>({ update: updateDistManager, map: new Map() })

  const { drawerState, closeDrawer } = useTableContext()
  const visible = drawerState.open.id === DistDrawerId && drawerState.open.status

  React.useEffect(() => {
    if (visible) {
      const newNeedToRefresh: Map<string, boolean> = new Map()

      channels.forEach((channel) => {
        const channelShops = allShops.filter(shop => shop.channel.id === channel.id)
        const channelRtiMcs = allRtiMcs.filter(rtiMc => rtiMc.mediacenter.channel.id === channel.id)

        const tree = DistManager.constructTree(channel, cells, channelShops, channelRtiMcs)
        let newDistManager = new DistManager(channel.id, tree)

        if (drawerState.tableRow && drawerState.open?.id === DistDrawerId) {
          const data: DistDto = drawerState.tableRow.data

          if (_.uniq(data.targets.map(t => t.channel.id)).includes(channel.id)) {
            data.targets.forEach(target => {
              if (target.cell || target.shop) {
                const node = target.cell ?
                  { key: target.cell.id, checked: true, isLeaf: false }
                  : { key: target.shop!.id, checked: true, isLeaf: true }

                newDistManager = newDistManager.addCheckedInfo({
                  checked: true,
                  node,
                  checkedNodes: []
                })
              } else {
                newDistManager = newDistManager.selectAll({ checked: true })
              }
            })
          }
        }

        distManagerRef.current.map.set(channel.id, newDistManager)

        newNeedToRefresh.set(channel.id, true)
      })
      setNeedToRefresh(newNeedToRefresh)

      setIsLoaded(true)
    } else {
      distManagerRef.current.map.clear()

      setIsLoaded(false)
    }
  }, [drawerState, allShops, cells, drawerState.tableRow])

  React.useEffect(() => {
    if (visible && drawerState.tableRow) {
      const data: DistDto = drawerState.tableRow.data

      form.setFieldsValue({
        id: drawerState.tableRow.id,
        name: data.name,
        pairing: data.pairing.id,
        reservedAt: data.reserveAt
      })
    } else {
      form.resetFields()
    }
  }, [drawerState])

  const [addDist] = useMutation(ADD_DIST)
  const [updateDist] = useMutation(UPDATE_DIST)
  const [removeDist] = useMutation(REMOVE_DIST)

  function onDistAdd(values: FormValues) {
    const {name, pairing: pairingId, reserveAt} = values
    const targets = DistManager.mergeAll(distManagerRef.current.map.entries())
    const distInput = {
      variables: {
        distInput: {
          name,
          reserveAt,
          targets,
          pairingId
        }
      }
    }

    addDist(distInput)
      .then((/*result*/) => {
        notification.success({
          message: '편성표 배포',
          description: '편성표가 배포되었습니다.'
        })

        closeDrawer()
      })
      .catch((e) => {
        notification.error({
          message: `편성표 배포 중에 에러가 발생했습니다.`
        })

        console.error(e)
      })
  }

  const onDistUpdate = () => {
    const {id, name, pairing: pairingId, reserveAt} = form.getFieldsValue()
    const targets = DistManager.mergeAll(distManagerRef.current.map.entries())

    const distInput = {
      variables: {
        distInput: {
          id,
          name,
          reserveAt,
          targets,
          pairingId
        }
      }
    }

    updateDist(distInput)
      .then((/*result*/) => {
        notification.success({
          message: '편성표 배포 수정',
          description: `편성표 배포가 수정되었습니다.`
        })

        closeDrawer()
      })
      .catch((e) => {
        notification.error({
          message: `편성표 배포 수정 중에 에러가 발생했습니다.`
        })

        console.error(e)
      })
  }

  const onDistRemove = () => {
    const {id} = form.getFieldsValue()

    removeDist({variables: {id}})
      .then((/*result*/) => {
        notification.success({
          message: '편성표 배포 삭제',
          description: `편성표 배포가 삭제되었습니다.`
        })

        closeDrawer()
      })
      .catch((e) => {
        notification.error({
          message: `편성표 배포 삭제 중에 에러가 발생했습니다.`
        })

        console.error(e)
      })
  }

  const onReservedAtChange = (value?: Moment | null) => {
    console.log(value)
  }

  return (
    <Drawer
      width={getResponsiveDrawerWidth(600)}
      title="편성표 배포"
      placement="right"
      closable
      onClose={closeDrawer}
      visible={visible}
      footer={
        editable && (
          <DrawerFooter
            formId="formAddDist"
            close={() => {
              closeDrawer()
            }}
            isNew={!form.getFieldValue('id')}
            onUpdate={onDistUpdate}
            onRemove={onDistRemove}
          />
        )
      }
    >
      <Form
        id="formAddDist"
        layout="vertical"
        labelAlign="left"
        onFinish={onDistAdd}
        form={form}
      >
        <Form.Item name="id" hidden/>

        <Form.Item
          name="name"
          label="배포 식별 이름"
          rules={[{required: true, message: '배포 식별 이름을 입력해주세요.'}]}
        >
          <Input name="name" disabled={!editable} placeholder="배포 식별 이름을 입력해주세요" autoFocus/>
        </Form.Item>

        <Form.Item
          name="pairing"
          label="편성표 선택"
          rules={[{required: true, message: '편성표를 선택해 주세요.'}]}
        >
          <Select
            disabled={!editable}
            showSearch
            placeholder="편성표를 선택해 주세요."
          >
            {
              dataPairings?.pairings.map(pairing =>
                <Option key={pairing.id} value={pairing.id}>{pairing.name}</Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          name="reserveAt"
          label="예약 날짜"
        >
          <DatePicker
            disabled={!editable}
            showTime
            style={{width: "100%"}}
            placeholder='예약 날짜를 선택해 주세요.'
            onChange={onReservedAtChange}
          />
        </Form.Item>
      </Form>


      <h4>
        배포 적용 대상 선택
      </h4>

      { isLoaded &&
          <DistEditChannel
            distManagerRef={distManagerRef}
            channels={channels}
            needToRefresh={needToRefresh}
          />
      }
    </Drawer>
  )
}

export {DistEdit}
