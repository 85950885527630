import React, { useEffect } from "react"
import { Line } from "react-chartjs-2"
import * as chartjs from "chart.js"
import { ChartDataSets } from "chart.js"
import _ from "lodash"
import randomColor from "randomcolor"
import { useQuery } from "@apollo/client"
import { GET_CHANNEL_LOGS, GET_CHANNEL_DAY_LOGS, LogChDto } from "../../api/graphql/channel"
import { startOfToday } from "date-fns"
import { notification } from "antd"
import { channelColors } from 'common/colors/channel-colors'

export type StatusPeriod = 'day' | 'week' | 'month'

const getStartDate = (period: StatusPeriod): Date => {
  const date = new Date(
    (period === 'day') ? startOfToday()
      : (period === 'week') ? startOfToday().setDate(startOfToday().getDate() - 7)
        : startOfToday().setDate(startOfToday().getDate() - 30)
  )

  return date
}

const getChartOpts = (period: StatusPeriod, startDate: Date): chartjs.ChartOptions => {
  const time: chartjs.TimeScale = (period === 'day') ? {
    displayFormats: { hour: 'HH', minute: 'HH:mm' },
    tooltipFormat: 'HH:mm',
    unit: 'minute',
    minUnit: 'minute',
    stepSize: 60
  } : (period === 'week') ? {
    displayFormats: { day: 'MM-DD' },
    tooltipFormat: 'MM-DD',
    unit: 'day',
    minUnit: 'day',
    stepSize: 1/*60 * 60 * 24*/
  } : {
    displayFormats: { day: 'MM-DD' },
    tooltipFormat: 'MM-DD',
    unit: 'day',
    minUnit: 'day',
    stepSize: 1/*60 * 60 * 24*/,
  }

  const maxDate = new Date(startDate)
  const endDate = new Date(
    (period === 'day') ? maxDate.setDate(maxDate.getDate() + 1)
      : (period === 'week') ? maxDate.setDate(maxDate.getDate() + 7)
        : maxDate.setDate(maxDate.getDate() + 30)
  )

  const ticks: chartjs.TickOptions = {
    min: startDate.toISOString(),
    max: endDate.toISOString()
  }
  
  const options: chartjs.ChartOptions = {
    scales: {
      xAxes: [
        {
          type: 'time',
          time: time,
          ticks: ticks
        },
      ],
      yAxes: [
        {
          ticks: {
            min: 0,
            max: 100,
          }
        }
      ]
    },
    plugins: {
      datalabels: {
        display: false,
      }
    },
    responsive: true,
    maintainAspectRatio: false,
  }

  return options
}

const ConnectionStatus: React.FC<{ period: StatusPeriod }> = ({ period }) => {
  const query = period === 'day' ? GET_CHANNEL_LOGS : GET_CHANNEL_DAY_LOGS
  const startDate = getStartDate(period)
  const {
    data,
    error,
    // startPolling,
    // stopPolling
  } = useQuery<{ logChs?: LogChDto[], logChDays?: LogChDto[] }>(query, {
    variables: {
      logChInput: {
        start: startDate
      }
    },
    fetchPolicy: 'network-only',
    pollInterval: 10 * 60 * 1000, // 10 minutes
  })
  
  /*
  useEffect(() => {
    startPolling(10 * 60 * 1000)

    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling])
  */

  if (error) {
    notification.error({
      message: '접속 현황을 불러오는 중에 문제가 발생했습니다.'
    })
  }

  const logChs = period === 'day' ? data?.logChs : data?.logChDays

  const logsGroupByChannel = _.groupBy(logChs ?? [], log => log.channel.name)
  const dataSets: ChartDataSets[] = _.keys(logsGroupByChannel).map((channelName, index) => ({
    label: channelName,
    data: logsGroupByChannel[channelName].map((log: LogChDto) => ({x: new Date(log.createdAt), y: Math.floor(log.onlineRate)})),
    fill: false,
    borderColor: index < channelColors.length ? channelColors[index] : randomColor({format: 'rgba'}),
    //pointBorderColor: 'rgba(75,192,192,1)',
    //pointBackgroundColor: '#fff',
    //pointBorderWidth: 1,
    tension: 0,
    borderWidth: 2,
    pointHoverRadius: 3,
    pointHoverBackgroundColor: 'rgba(75,192,192,1)',
    pointHoverBorderColor: 'rgba(220,220,220,1)',
    pointHoverBorderWidth: 1,
    pointRadius: period === 'day' ? 1 : 2,
    pointHitRadius: 1,
  } as ChartDataSets))

  const chartOpts: chartjs.ChartOptions = getChartOpts(period, startDate/*getStartDate('day')*//*startDate*/)

  return (
    <Line data={{datasets: dataSets}} options={chartOpts}/>
  )
}

export default ConnectionStatus
