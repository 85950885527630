import CryptoJS from 'crypto-js'
import Axios from 'axios'
import { AuthLoginSuccess } from '../features/auth'

const login = async (url: string, userid: string, password: string): Promise<AuthLoginSuccess> => {
  try {
    const {data} = await Axios.post(
      url,
      {
        userid,
        password: CryptoJS.AES.encrypt(password, userid).toString()
      }
    )

    return data
  } catch (e) {
    if (e.message === 'Network Error') {
      throw new Error('서버에 연결할 수 없습니다.')
    }

    const errorResp = e.response.data
    if (errorResp.message.includes('userid')) {
      throw new Error('아이디가 유효하지 않습니다.')
    } else if (errorResp.message.includes('password')) {
      throw new Error('비밀번호가 잘못 되었습니다.')
    } else {
      throw new Error('알 수 없는 문제가 발생했습니다.')
    }
  }
}

const auth = {
  login
}


export { auth }
