import {GET_SMDS, SmdDto, Smds, SUBSCRIBE_SMD} from "../api/graphql/smd"
import useDtos, {LoadingAndError} from "./useDtos"
import {afterProvided} from "../utils"

interface Result extends LoadingAndError {
    smds: SmdDto[];
}

const useSmds = (): Result => {
  const {allDtos: smds, loading, error} = useDtos<SmdDto, Smds>({
    query: GET_SMDS,
    queryResultKey: 'smds',
    subscription: SUBSCRIBE_SMD,
    updateQueryFunction: afterProvided({
      queryResultKey: 'smds',
      subscriptionResultKey: 'subscribeSmd',
      dataKey: 'smd'
    })
  })

  return {smds, loading, error}
}

export default useSmds
