import React from 'react'
import {Box, Stack} from "@chakra-ui/layout"
import Typography from "antd/lib/typography"
import {Divider, Progress, Space} from "antd"
import {RtiMcDto} from "../api/graphql/rti-mc"
import useRtiMcs from "../hooks/useRtiMcs"
import UpdateStatusList from './status-update/list'
import useDists from "../hooks/useDists"
import usePairing from "../hooks/usePairing"
import {SearchItem} from "../components/common/search-item"
import fp from "lodash/fp"
import {filterForTable} from "../utils/filter"
import {useForm} from "antd/lib/form/Form"
import RtiMcViewModel from "../models/rti-mc"
import useChannels from "../hooks/useChannels"
import {ChannelDto} from "../api/graphql/channel"
import Form, {FormInstance} from "antd/lib/form"
import {Col, Row} from "antd/lib/grid"
import Button from "antd/lib/button"
import {SearchOutlined} from "@ant-design/icons"
import PairingUpdateStatus from 'models/pairing-update-status'
import { isMobile } from 'react-device-detect'

type ChannelName = "전체 경로" | string

export interface RtiMcFilter {
  filter: (rtiMcs: RtiMcDto[]) => RtiMcDto[]
}

interface ChannelCardProps {
  title: string
  updateCompletePercent: number
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
  assignedCount: number
  searchForm: FormInstance
  confirmedCount: number
  headerColor?: string
  confirmedAt?: string
}

const ChannelCard: React.FC<ChannelCardProps> = (
  {
    title,
    //headerColor,
    updateCompletePercent,
    //assignedCount,
    //confirmedCount,
    searchForm,
    //confirmedAt,
    selected,
    setSelected
  }) => {
  const onClick = () => {
    if (title === '전체 경로') {
      searchForm.setFieldsValue({channelName: undefined})
    } else {
      searchForm.setFieldsValue({channelName: title})
    }

    setSelected(title)
  }

  const isSelected = () => selected === title

  return (
    <Stack
      minWidth={108}
      h={125}
      // minH={120}
      border={isSelected() ? "0.5px solid #404040" : "0.5px solid #a0a0a0"}
      cursor="pointer"
      onClick={onClick}
      boxShadow={isSelected() ? '0px 0px 5px 3px rgba(32, 32, 32, 0.5)' : 'none' }
    >
      <Box
        w="100%"
        height="24px"
        background={isSelected() ? '#b37feb' : '#efdbff'}/*{style?.headerColor ? style.headerColor : "#1890ff"}*/
        textAlign="center"
        borderBottom={isSelected() ? "0.5px solid #404040" : "0.5px solid #a0a0a0"}
      >
        <Typography.Text strong style={{color: '#404040'/*'white'*/, fontSize: 14}}>
          {title}
        </Typography.Text>
      </Box>

      <Form.Item name="channelName" hidden>
      </Form.Item>

      <Stack align="center">
        <Box mb={12} fontSize={12} textAlign="center">
          완료율: {updateCompletePercent.toFixed(1)}%
        </Box>
        <Progress type="circle" width={44} percent={updateCompletePercent}
          strokeWidth={10}
          status='normal'
          format={percent => `${percent === 100 ? 100 : percent === 0 ? 0 : percent?.toFixed(1)}%`/*`${percent?.toFixed(1)}%`*/}
          style={{margin: 0, padding: 0}}
        />
      </Stack>
      {/*}
            <Box display="flex" justifyContent="space-around" p="0.5em" fontSize="0.8rem">
                <Stack>
                    <Box fontSize="0.625rem" textAlign="center">
                        완료율: {updateCompletePercent}%
                    </Box>
                    <Box>
                        TV 대수: {confirmedCount} / {assignedCount}
                    </Box>
                    {confirmedAt && (
                        <Box>
                            최종 업데이트: {confirmedAt}
                        </Box>
                    )}
                </Stack>


                <Progress type="circle" width={60} percent={updateCompletePercent}
                    strokeWidth={10}
                    status='normal'
                    format={percent => `${percent}%`}
                    style={{margin: 0, padding: 0}}
                />
            </Box>
*/}
    </Stack>
  )
}

function PageStatusUpdate() {
  const [searchForm] = useForm()
  const {setFilter, rtiMcs, allRtiMcs} = useRtiMcs()

  const {allDists} = useDists()
  const {allPairings} = usePairing()
  const {allChannels} = useChannels()

  const [selected, setSelected] = React.useState<ChannelName>("전체 경로")

  const searchItems = [
    <SearchItem id="shopName" key="shopName" title="매장 이름" width="100%"/>,
    <SearchItem
      id="connectionPercentage"
      key="connectionPercentage"
      title="완료율"
      placeholder="전체"
      type="dropbox"
      width="100%"
      dropboxOptions={[[100, "완료 (100%)"], [0, "미완료 (100%미만)"]]}
    />,
  ]

  const filterConnectionPercent = (searchFields: { connectionPercentage: number }) => fp.filter((rtiMcDto: RtiMcDto) => {
    const rtiMcViewModel = new RtiMcViewModel(rtiMcDto)

    if (searchFields.connectionPercentage === undefined) {
      return true
    }

    const percent = rtiMcViewModel.getUpdatePercentage()
    if (searchFields.connectionPercentage === 100) {
      return percent === 100
    }

    return percent !== 100
  })

  const filters = (searchFields) => fp.flow(
    [
      filterConnectionPercent(searchFields),
      filterForTable({fieldValue: searchFields['shopName'], type: 'text', path: 'mediacenter.shop.name'}),
      filterForTable({fieldValue: searchFields['channelName'], type: 'text_strict', path: 'mediacenter.channel.name'}),
    ],
  )

  const onSearch = () => {
    const searchFields = searchForm.getFieldsValue()
    console.log(searchFields)
    setFilter({filter: filters(searchFields)})
  }

  const findRtiMcsByChannel = (channel: ChannelDto) => {
    return allRtiMcs.filter(rtiMc => rtiMc.mediacenter.channel.id === channel.id)
  }

  return (
    <Stack>
      <Form form={searchForm}>
        <Stack spacing={16} mb={16}>
          <Typography.Title level={3}>
            배포 현황
          </Typography.Title>

          <Divider/>

          <Box display="flex">
            <Box p="8px" boxShadow='0px 0px 8px 3px rgba(192, 192, 192, 0.5)'>
              <ChannelCard
                title="전체 경로"
                headerColor="#1b90ff"
                searchForm={searchForm}
                selected={selected}
                setSelected={setSelected}
                {...PairingUpdateStatus.getUpdateStatus(allRtiMcs)}
              />
            </Box>
                        
            <Box w="100%" p="8px" ml={16} /*border="1px solid #a0a0a0"*/ justify="space-between" overflowX="scroll"
              boxShadow='0px 0px 8px 3px rgba(192, 192, 192, 0.5)'
            >
              <Space>
                {allChannels.map((channel) => (
                  <ChannelCard
                    key={channel.id}
                    title={channel.name}
                    selected={selected}
                    setSelected={setSelected}
                    searchForm={searchForm}
                    {...PairingUpdateStatus.getUpdateStatus(findRtiMcsByChannel(channel))}
                  />
                ))}
              </Space>
            </Box>
          </Box>
        </Stack>

        <UpdateStatusList rtiMcs={rtiMcs} pairings={allPairings} dists={allDists}/>
      </Form>
    </Stack>
  )
}

export {PageStatusUpdate}
