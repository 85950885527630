import {GET_CHANNELS, ChannelDto, Channels, SUBSCRIBE_CHANNEL} from "../api/graphql/channel"
import useDtos, {LoadingAndError} from "./useDtos"
import {afterProvided} from "../utils"

interface Result extends LoadingAndError {
    allChannels: ChannelDto[];
    channels: ChannelDto[];
}

const useChannels = (): Result => {
  const {allDtos: allChannels, dtos: channels, loading, error} = useDtos<ChannelDto, Channels>({
    query: GET_CHANNELS,
    queryResultKey: 'channels',
    subscription: SUBSCRIBE_CHANNEL,
    updateQueryFunction: afterProvided({
      queryResultKey: 'channels',
      subscriptionResultKey: 'subscribeChannel',
      dataKey: 'channel'
    })
  })

  return {allChannels, channels, loading, error}
}

export default useChannels
