import {gql} from '@apollo/client'

export const logMcFragment = gql`
    fragment logMcParts on LogMc {
        state
        totalCount
        unknownCount
        onlineCount
        offlineCount
        errorCount
        createdAt
        groupTvData {
            groupId
            onlineCount
            offlineCount
            errorCount
        }
    }
`

export const GET_LOG_MCS = gql`
    query GetLogMcs($logMcInput: LogMcInput!)  {
        logMcs(logMcInput: $logMcInput) {
            ...logMcParts
        }
    }
    ${logMcFragment}
`
