import {gql} from '@apollo/client'

export const smdFragment = gql`
    fragment smdParts on Smd {
        id
        name
        phoneno
        desc
        shops {
            id
            name
        }
        createdAt
        updatedAt
    }
`

export const GET_SMDS = gql`
    query GetSmds {
        smds {
            ...smdParts
        }
    }
    ${smdFragment}
`

export const SUBSCRIBE_SMD = gql`
    subscription {
        subscribeSmd {
            op
            smd {
                ...smdParts
            }
        }
    }
    ${smdFragment}
`
