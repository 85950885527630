import React, { useRef } from 'react'
import { Stack } from '@chakra-ui/layout'
import { MediaDto } from '../api/graphql/media'
import { MediaList } from './media'
import { useTableContext } from '../contexts/TableContextProvider'
import { sortByNumber, toMegabytes } from '../utils'
import { ColumnsType } from 'antd/es/table'
import format from 'date-fns/format'
import { DetailDrawerId } from './media/detail'
import useMedia from '../hooks/useMedia'
import { createdAtColumn } from '../common/created-at-column'
import { columnText } from '../components/table'    

function PageMedia() {
  const { setDrawerState } = useTableContext()

  const { mediaDtos: medias } = useMedia()

  function handleItemClick(media: MediaDto) {
    setDrawerState({ open: { status: true, id: DetailDrawerId }, tableRow: { data: media }})
  }

  const columns: ColumnsType<MediaDto> = [
    columnText("파일 이름", "filename", "channel", 0, "left",
      { searchInputRef: useRef() },
      (media) => { handleItemClick(media) }
    ),
    columnText("비디오 정보", "videoCodec", "videoCodec", 150, "left",
      { searchInputRef: useRef() },
    ),
    columnText("오디오 정보", "audioCodec", "audioCodec", 150, "left",
      { searchInputRef: useRef() },
    ),
    {
      title: '파일 크기',
      dataIndex: 'size',
      key: 'size',
      render: (size) => toMegabytes(size) + "MB",
      sorter: (mediaDto1, mediaDto2) => sortByNumber(mediaDto1.size, mediaDto2.size),
      showSorterTooltip: false,
    },
    {
      title: '사용 기한',
      dataIndex: 'licenseEnd',
      key: 'licenseEnd',
      render: licenseEnd =>
        licenseEnd && <span>{format(new Date(licenseEnd), "yyyy/MM/dd")}</span>,
      sorter: (mediaDto1, mediaDto2) => sortByNumber(mediaDto1.licenseEnd, mediaDto2.licenseEnd),
      showSorterTooltip: false,

    },
    createdAtColumn('createdAt', (e1, e2) => sortByNumber(e1.createdAt, e2.createdAt)),
  ]

  return (
    <Stack>
      <MediaList medias={medias} columns={columns}/>
    </Stack>
  )
}

export { PageMedia }
