import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { GET_PAIRING, PairingDto } from '../api/graphql/pairing'
import { Row, Col, notification, Spin } from 'antd'
import { Center } from '@chakra-ui/layout'
import { PairingEditGroupList } from '../pages/pairing/edit-group-list'
import { PairingEditGroupMediaList } from '../pages/pairing/edit-group-medialist'
import { GroupDto } from '../api/graphql/group'
import { Group, GroupManager } from '../models'
import { MediaDto } from '../api/graphql/media'

interface Props {
  groups: GroupDto[]
  pairingId: string
}
  
const PairingContentList: React.FC<Props> = ({ groups, pairingId }) => {
  const [groupManager, setGroupManager] = useState<GroupManager>(new GroupManager([]))
  const { data, loading, error } = useQuery<{ getPairing: PairingDto }>(GET_PAIRING, {
    variables: {
      id: pairingId
    }
  })
  const pairing = data?.getPairing
  const playlists = pairing?.playlists ?? []

  useEffect(() => {
    if (groups.length > 0) {
      const selectedGroups: Group[] = playlists.map(playlist => ({
        id: playlist.group.id,
        name: groups.find(g => g.id === playlist.group.id)?.name ?? '',
        creatable: true,// playlist.playlistMedias.length > 0,
        playlist: [...playlist.playlistMedias].sort((p1, p2) => (p1.order > p2.order) ? 1 : -1)
          .map(p => p.media as MediaDto)
      })) ?? []

      if (selectedGroups.length > 0) {
        const groupManager = new GroupManager(selectedGroups, selectedGroups[0].id)
        setGroupManager(groupManager)
      } else {
        setGroupManager(new GroupManager([]))
      }
    } else {
      setGroupManager(new GroupManager([]))
    }
  }, [groups, pairingId, data])

  if (loading) {
    return <Spin size="large"/>
  }
  if (error) {
    notification.error({
      message: `컨텐츠 리스트를 불러오는 중에 에러가 발생했습니다.`
    })
  }

  return (
    <Center w="100%" h={320} overflowY="scroll" border="1px solid #d0d0d0">
      <Row style={{ width: "100%", height: "100%" }}>
        <Col span={6} style={{ padding: 8 }}>
          <PairingEditGroupList
            groups={groups}
            groupManager={groupManager}
            onGroupSelected={(groupId) => setGroupManager(groupManager.select(groupId))}
          />
        </Col>
        <Col span={18} style={{ padding: 8 }}>
          <PairingEditGroupMediaList
            editable={false}
            groupManager={groupManager}
          />
        </Col>
      </Row>
    </Center>
  )
}

export default PairingContentList
