import React, { FC, useState } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import { Drawer, Form, Input, Space, Tag, notification } from 'antd'
import { ModalMode } from '../../types'
import DrawerFooter from '../../components/common/drawer-footer'
import _ from 'lodash'
import DrawerErrorAlert from '../../components/common/drawer-error-alert'
import { FormInstance } from 'antd/lib/form'
import { RegionFormValues } from './list'
import { ADD_REGION, REMOVE_REGION, UPDATE_REGION } from 'api/graphql/region/mutations'
import { GET_REGIONS, RegionDto } from '../../api/graphql/region'
import { afterRemoved } from '../../utils'
import { getResponsiveDrawerWidth } from 'common/layouts'
import TextArea from 'antd/lib/input/TextArea'
import { CellDto } from '../../api/graphql/cell'

interface Props {
  regions: RegionDto[]
  cells: CellDto[]
  form: FormInstance
  visible: boolean
  modalMode: ModalMode
  onClose: () => void
}

const RegionEditDrawer: FC<Props> = ({ 
  form, regions, cells, visible, onClose, modalMode
}) => {
  const [regionError, setMutationError] = useState<ApolloError>()
  // const { data: bundle } = useQuery(GET_CHANNELS_CELLS_AMCODES)

  const [addRegion] = useMutation(ADD_REGION)
  const [updateRegion] = useMutation(UPDATE_REGION)
  const [removeRegionMutation] = useMutation(REMOVE_REGION, {
    update: afterRemoved({query: GET_REGIONS, queryResultKey: 'regions', mutationResultKey: 'removeRegion'})
  })

  const regionId = form.getFieldValue('id')
  const cellsInRegion = regionId ? cells.filter(ch => ch.region.id === regionId) : []

  function onRegionCreate(values: RegionFormValues) {
    const variables = { ...values }

    const regionByName = regions.map(s => s.name).find(name => name === variables.name)
    if (regionByName) {
      setMutationError(new ApolloError({errorMessage: "이미 존재하는 지역입니다."}))
      return
    }

    const regionInput = {
      variables: {
        ...variables
      }
    }

    addRegion(regionInput)
      .then(() => {
        notification.success({
          message: `지역을 성공적으로 생성하였습니다.`
        })

        cleanFormData()
        onClose()
      })
      .catch((e) => {
        notification.error({
          message: `지역을 추가하는 중에 에러가 발생하였습니다.`
        })

        setMutationError(e)
      })
  }

  const onRegionUpdate = () => {
    const variables = form.getFieldsValue()

    updateRegion({
      variables: {
        ...variables
      }
    })
      .then(() => {
        notification.success({
          message: `지역을 성공적으로 수정하였습니다.`
        })

        cleanFormData()
        onClose()
      })
      .catch((e) => {
        notification.error({
          message: `지역을 수정하는 중에 에러가 발생하였습니다.`
        })

        setMutationError(e)
      })
  }

  const onRegionRemove = () => {
    if (cells.find(ch => ch.region.id === regionId)) {
      notification.error({
        message: `지역셀이 있는 지역은 삭제할 수 없습니다.`
      })
      return
    }

    removeRegionMutation({
      variables: {
        id: form.getFieldValue('id'),
      }
    })
      .then(() => {
        notification.success({
          message: `지역을 성공적으로 삭제하였습니다.`
        })

        cleanFormData()
        onClose()
      })
      .catch((e) => {
        notification.error({
          message: `지역을 삭제하는 중에 에러가 발생하였습니다.`
        })

        setMutationError(e)
      })
  }

  const cleanFormData = () => {
    form.resetFields()
    setMutationError(undefined)
  }

  return (
    <Drawer
      title={`지역 ${modalMode}`}
      width={getResponsiveDrawerWidth(400)}
      placement="right"
      closable
      onClose={onClose}
      visible={visible}
      footer={
        <DrawerFooter
          formId="formAddRegion"
          close={() => {
            onClose()
            setMutationError(undefined)
          }
          }
          isNew={modalMode === '추가'}
          onUpdate={onRegionUpdate}
          onRemove={onRegionRemove}
        />
      }
    >
      <Form
        id="formAddRegion"
        form={form}
        layout="vertical"
        labelAlign="right"
        onFinish={onRegionCreate}
        colon
      >
        <Form.Item name="id" hidden>
        </Form.Item>

        <Form.Item
          name="name"
          label="지역 이름"
          rules={[{required: true, message: '지역 이름을 입력해 주세요.'}]}
        >
          <Input placeholder="지역 이름을 입력해 주세요."/>
        </Form.Item>

        <Form.Item
          label="지역셀 리스트"
        >
          {
            cellsInRegion.length > 0 ?
              <Space size={[0, 8]} wrap>
                { cellsInRegion.map(cell => <Tag key={cell.id}>{cell.name}</Tag>) }
              </Space>
              :
              <span>-</span>
          }
        </Form.Item>

        <Form.Item
          name="desc"
          label="지역 설명"
        >
          <TextArea maxLength={255} autoSize={{ minRows: 3, maxRows: 3 }} 
            placeholder="지역 설명을 입력해 주세요."
          />
        </Form.Item>
      </Form>

      <DrawerErrorAlert error={regionError}/>
    </Drawer>
  )
}


export { RegionEditDrawer }
