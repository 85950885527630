import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IState {
  selectedMenuKeys: string[],
  openKeys: string[],
}

const initialState: IState = {
  selectedMenuKeys: [],
  openKeys: []
}

const slice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    storeMenu(state, action: PayloadAction<typeof initialState>) {
      state = action.payload
      return state
    },
  }
})

export const { storeMenu } = slice.actions
export default slice.reducer
