import React from 'react'
import {DistList} from './dist'
import {Stack} from "@chakra-ui/layout"
import Typography from "antd/lib/typography"
import {Divider} from "antd"

function PageUpdateDist() {
  return (
    <Stack spacing={16}>
      <Typography.Title level={3}>
                배포 관리
      </Typography.Title>
      <Divider/>

      <DistList/>
    </Stack>
  )
}


export {PageUpdateDist}
