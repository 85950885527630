import React, { FC, useRef } from 'react'
import { Button, Form, Space, Table, } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { DatabaseOutlined, } from '@ant-design/icons'
import { ChannelDto } from 'api/graphql/channel'
import { Box } from '@chakra-ui/layout'
import useModalState from 'hooks/useModalState'
import { ChannelEditDrawer } from './edit'
import usePageSize from '../../hooks/usePageSize'
import { sortByNumber } from '../../utils'
import { createdAtColumn } from '../../common/created-at-column'
import useRtiMcs from '../../hooks/useRtiMcs'
import useDivisions from '../../hooks/useDivisions'
import { columnText } from '../../components/table'

interface Props {
  channels: ChannelDto[]
}

export interface ChannelFormValues {
  id: string
  name: string
  divisionId: string
  desc?: string
}

const ChannelList: FC<Props> = ({ channels }) => {
  const { rtiMcs } = useRtiMcs()

  const [form] = Form.useForm()
  const { closeModal, modalMode, openAddModal, openEditModal, modalShowable } = useModalState()
  const { pageSize, setPageSize } = usePageSize('channel')

  const { divisions } = useDivisions()

  const columns: ColumnsType<ChannelDto> = [
    columnText("판매 경로", "name", "name", 160, "left",
      { searchInputRef: useRef(null) },
      (channel) => {
        const formValues: ChannelFormValues = {
          id: channel.id,
          name: channel.name,
          divisionId: channel.division.id,
          desc: channel.desc,
        }
        form.setFieldsValue(formValues)
        openEditModal()
      }
    ),
    columnText("매장 분류", ["division", "name"], "division", 160, "left",
      { filters: divisions.map(division => ({ text: division.name, value: division.name })) },
    ),
    {
      title: '매장 수',
      //dataIndex: 'shopCount',
      key: 'shopCount',
      width: 100,
      render: (_, channelDto) => {
        return (
          <span>
            { rtiMcs.filter(rtiMc => rtiMc.mediacenter.channel.id === channelDto.id).length }
          </span>
        )
      },
      sorter: (channelDto1, channelDto2) => {
        const shopCount1 = rtiMcs.filter(rtiMc => rtiMc.mediacenter.channel.id === channelDto1.id).length
        const shopCount2 = rtiMcs.filter(rtiMc => rtiMc.mediacenter.channel.id === channelDto2.id).length
        return sortByNumber(shopCount1, shopCount2)
      },
      showSorterTooltip: false,
    },

    createdAtColumn('createdAt', (e1, e2) => sortByNumber(e1.createdAt, e2.createdAt)),
  ]

  const onDrawerOpen = () => {
    form.resetFields()
    openAddModal()
  }

  return (
    <>
      <Box w="100%" textAlign="right" mb="10px">
        <Space direction="horizontal">
          <Button type="primary" icon={<DatabaseOutlined/>}
            onClick={onDrawerOpen}
          >
            추가
          </Button>
        </Space>
      </Box>
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        scroll={{ x: 1000/*true*/ }}
        dataSource={channels}
        onChange={(pagination) => setPageSize(pagination.pageSize)}
        pagination={{ showSizeChanger: true, pageSize }}
      />
      <ChannelEditDrawer
        channels={channels}
        form={form}
        visible={modalShowable}
        modalMode={modalMode}
        onClose={closeModal}
      />
    </>
  )
}

export { ChannelList }
