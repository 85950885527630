import {gql} from "@apollo/client"
import {smdFragment} from "./queries"

export const ADD_SMD = gql`
    mutation AddSmd(
        $smdInput: SmdInput!
    ) {
        addSmd(
            smdInput: $smdInput
        ) {
            id
        }
    }
`

export const UPDATE_SMD = gql`
    mutation UpdateSmd(
        $smdInput: SmdInput!
    ) {
        updateSmd(
            smdInput: $smdInput
        ) {
            ...smdParts
        }
    }
    ${smdFragment}
`

export const REMOVE_SMD = gql`
    mutation RemoveSmd(
        $id: ID!
    ) {
        removeSmd(
            id: $id
        )
    }

`
