import { gql } from "@apollo/client"

export const SET_RTI_MC_PAIRING = gql`
  mutation SetRtiMcPairing(
    $id: ID!,
    $isManual: Boolean!,
    $pairingId: String,
  ) {
    setRtiMcPairing(
      id: $id
      isManual: $isManual
      pairingId: $pairingId
    ) {
      id
      state
      mediacenter {
        id
        mac
        serial
        mode
        recognizedAt
        connectedAt
        disconnectedAt
        createdAt
        updatedAt
        channel {
          id
          name
        }
        region {
          id
          name
        }
        cell {
          id
          name
        }
        shop {
          id
          name
        }
      }
      isManual
      pairingId
      distId
      media {
        assignedCount
        confirmedCount
        assignedAt
        confirmedAt
        assignedMediaIds
        confirmedMediaIds
      }
      groups {
        id
        tvs {
          id
          serial
          state
        }
        totalCount
        unknownCount
        onlineCount
        offlineCount
        errorCount
      }
      isDebug
      updatedAt
    }
  }
`
