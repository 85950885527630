import useDtos, {DtoFilter, LoadingAndError} from "./useDtos"
import {afterProvided} from "../utils"
import {GET_MEDIAS, MediaDto, Medias, SUBSCRIBE_MEDIA} from "../api/graphql/media"
import React from "react"

interface Result extends LoadingAndError {
    mediaDtos: MediaDto[];
    allMediaDtos: MediaDto[];
    setFilter: React.Dispatch<React.SetStateAction<DtoFilter<MediaDto>>>;
}

const useMedia = (): Result => {
  const {allDtos: allMediaDtos, dtos: mediaDtos, setFilter, loading, error} = useDtos<MediaDto, Medias>({
    query: GET_MEDIAS,
    queryResultKey: 'medias',
    subscription: SUBSCRIBE_MEDIA,
    updateQueryFunction: afterProvided({
      queryResultKey: 'medias',
      subscriptionResultKey: 'subscribeMedia',
      dataKey: 'media'
    })
  })

  return {mediaDtos, allMediaDtos, setFilter, loading, error}
}

export default useMedia
