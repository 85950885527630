import React, { useEffect, useState } from 'react'
import { Drawer, Form, Input, notification, List } from 'antd'
import { Box } from "@chakra-ui/layout"
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { ADD_GROUP, GroupDto, REMOVE_GROUP, UPDATE_GROUP } from "../../api/graphql/group"
import useGroups from "../../hooks/useGroups"
import { getResponsiveDrawerWidth } from 'common/layouts'
import DrawerFooter from "../../components/common/drawer-footer"
import { RtiMcDto } from "../../api/graphql/rti-mc"
import { FormInstance } from "antd/lib/form"
import { ModalMode } from "../../types"
import TextArea from "antd/lib/input/TextArea"
import DrawerErrorAlert from "../../components/common/drawer-error-alert"
import usePairing from '../../hooks/usePairing'

export const GroupDrawerId = 'group'

interface GroupEditProps {
  rtiMcs: RtiMcDto[]
  form: FormInstance
  selectedGroup?: GroupDto
  visible: boolean
  modalMode: ModalMode
  onClose: () => void
}

export interface GroupEditFormValues {
  name: string
  regex: string
}

const GroupEdit: React.FC<GroupEditProps> = ({ rtiMcs, form, selectedGroup, visible, onClose, modalMode }) => {

  const [groupError, setMutationError] = useState<ApolloError>()

  const { groups } = useGroups()
  const { pairings } = usePairing()

  const [addGroup] = useMutation(ADD_GROUP)
  const [updateGroup] = useMutation(UPDATE_GROUP)
  const [removeGroup] = useMutation(REMOVE_GROUP)

  useEffect(() => {
    if (selectedGroup) {
      form.setFieldsValue({
        ...selectedGroup
      })
    }
  }, [selectedGroup])

  const onGroupCreate = (variables: GroupEditFormValues) => {
    const found = groups.map(g => g.name).find(name => name === variables.name)
    if (found) {
      setMutationError(new ApolloError({errorMessage: "이미 존재하는 그룹 이름입니다."}))
      return
    }

    addGroup({
      variables
    })
      .then(() => {
        notification.success({
          message: `성공적으로 그룹을 생성했습니다.`
        })

        cleanFormData()
      }).catch((e) => {
        notification.error({
          message: `그룹을 추가 중에 에러가 발생했습니다.`
        })

        setMutationError(e)
      })
  }

  const onGroupUpdate = () => {
    updateGroup({
      variables: form.getFieldsValue()
    }).then(() => {
      notification.success({
        message: `성공적으로 그룹을 업데이트했습니다.`
      })

      cleanFormData()
    })
      .catch((e) => {
        notification.error({
          message: `그룹을 업데이트 중에 에러가 발생했습니다.`
        })

        setMutationError(e)
      })
  }

  const onGroupRemove = () => {
    if (selectedGroup) {
      const pairingCount = pairings.filter(pairing => 
        pairing.playlists?.findIndex(p => p.group.id === selectedGroup.id)
      ).length
      if (pairingCount > 0) {
        notification.error({
          message: `편성표에 적용되어 있는 그룹은 삭제할 수 없습니다.`
        })
        return
      }

      const shopCount = rtiMcs.filter(rtiMc =>
        rtiMc.groups.findIndex(g => g.id === selectedGroup.id) !== -1
      ).length
      if (shopCount > 0) {
        notification.error({
          message: `매장에 적용되어 있는 그룹은 삭제할 수 없습니다.`
        })
        return
      }
    }

    removeGroup({
      variables: {
        id: form.getFieldValue('id'),
      }
    }).then(() => {
      notification.success({
        message: `성공적으로 그룹을 제거했습니다.`
      })

      cleanFormData()
    })
      .catch((e) => {
        notification.error({
          message: `그룹을 제거 중에 에러가 발생했습니다.`
        })

        setMutationError(e)
      })
  }

  const cleanFormData = () => {
    onClose()
    form.resetFields()
    setMutationError(undefined)
  }

  return (
    <Drawer
      title={`TV 그룹 ${modalMode}`}
      width={getResponsiveDrawerWidth(400)}
      placement="right"
      closable
      onClose={cleanFormData}
      visible={visible}
      footer={
        <DrawerFooter
          formId="formEditGroup"
          close={cleanFormData}
          isNew={!selectedGroup || !selectedGroup.id}
          onUpdate={onGroupUpdate}
          onRemove={onGroupRemove}
        />
      }
    >
      <Form
        id="formEditGroup"
        form={form}
        layout="vertical"
        labelAlign="right"
        colon
        onFinish={onGroupCreate}
      >
        <Form.Item name="id" hidden>
        </Form.Item>

        <Form.Item name="name" label="이름"
          rules={[{required: true, message: '\'이름\'을 입력해 주세요.'}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item name="regex" label="식별 패턴" initialValue="">
          <TextArea maxLength={255} autoSize={{ minRows: 5, maxRows: 5 }} />
        </Form.Item>
      </Form>

      {
        selectedGroup &&
          <>
            <div>
              <h4>
                그룹이 포함된 편성표 리스트
              </h4>
            </div>
            <Box w="100%">
              <List
                size="small"
                bordered
                dataSource={pairings.filter(pairing => 
                  pairing.playlists?.findIndex(p => p.group.id === selectedGroup.id) !== -1
                )}
                renderItem={(item) => <List.Item style={{ backgroundColor: "#e0e0e0" }}>{item.name}</List.Item>}
              />
            </Box>
          

            <br />
            <div>
              <h4>
                그룹이 적용된 매장 수
              </h4>
            </div>
            <Box w="100%">
              <Input
                placeholder="적용된 매장이 없습니다."
                value={rtiMcs.filter(rtiMc => 
                  rtiMc.groups.findIndex(g => g.id === selectedGroup.id) !== -1
                ).length}
                style={{ backgroundColor: "#e0e0e0" }}
              />
            </Box>
          </>
      }

      <DrawerErrorAlert error={groupError}/>
    </Drawer>
  )    
}

export { GroupEdit }
