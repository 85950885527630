import React from 'react'
import {Button, Col, Divider, Drawer, Row, Typography} from 'antd'
import {RtiMcDto, TvState} from 'api/graphql/rti-mc'
import {ConnStatus} from 'components/mediacenter/conn-status'
import {RtiMcDetailMcState} from './detail-mc-state'

const { Title } = Typography

function RtiMcDetail(props) {
  const { rtiMc }: { rtiMc: RtiMcDto } = props
  const { dataGroups } = props
  const { visible, onClose } = props

  function handleClose() {
    onClose()
  }

  return (
    <Drawer
      width={400}
      placement="right"
      closable
      onClose={handleClose}
      visible={visible}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={handleClose} style={{ marginRight: 8 }}>
            닫기
          </Button>
        </div>
      }
    >
      <Title level={2}>미디어센터 상세 정보</Title>
      <Divider style={{
        backgroundColor: '#A0A0A0',
        borderTop: '2px solid #404040',
      }} />

      <Row align="middle" justify="end">
        <Col span={12}>
          <Title level={4}>{rtiMc?.mediacenter.mac}</Title>
        </Col>
        <Col span={12}>
          <div style={{ textAlign: 'right', }}>
            <RtiMcDetailMcState state={rtiMc?.state} />
          </div>
        </Col>
      </Row>

      <Divider orientation="left" plain>접속 현황</Divider>

      <Row justify="center" style={{ width: '100%', height: 100 }}>
        <Col span={1} />
        <Col span={7}>
          {/*<ConnStatusDoughnut*/}
          {/*    onlineCount={counts?.online}*/}
          {/*    offlineCount={counts?.offline}*/}
          {/*    errorCount={counts?.error}*/}
          {/*    width={80}*/}
          {/*    height={80}*/}
          {/*/>*/}
        </Col>
        <Col span={2}>
          <Divider type="vertical" style={{ height: '100%' }} />
        </Col>
        <Col span={14}>
          {/*<ConnStatusBar*/}
          {/*    onlineCount={counts?.online}*/}
          {/*    offlineCount={counts?.offline}*/}
          {/*    errorCount={counts?.error}*/}
          {/*/>*/}
        </Col>
      </Row>

      <Divider orientation="left" plain> TV 그룹 별 접속 현황 </Divider>
      <div style={{ display: 'inline-block', width: '100%', textAlign: 'center' }}>
        {
          rtiMc?.groups?.map(group => {
            const status = group.tvs?.reduce((acc, tv) => {
              if (tv.state === TvState.ONLINE) {
                acc.active ++
              } else {
                acc.inactive ++
              }

              return acc
            }, { active: 0, inactive: 0 })

            const gr = dataGroups.groups.find(g => g.id === group.id)
            return <ConnStatus
              name={gr.name}
              color="#f0f0f0"
              activeCount={status?.active ?? 0}
              inactiveCount={status?.inactive ?? 0}
            />
          })
        }
      </div>
      <Divider orientation="left" plain> TODO </Divider>
    </Drawer>
  )
}


export { RtiMcDetail }
