import React, { FC, useMemo } from 'react'
import { Table } from 'antd'
import {ColumnsType} from 'antd/es/table'
import { ReportDto } from "../../api/graphql/report"
import usePageSize from "../../hooks/usePageSize"
import { sortByText, sortByNumber } from "../../utils"
import { createdAtColumn } from "../../common/created-at-column"
import { urlJoin } from '../../utils/url'

interface Props {
  reports: ReportDto[]
}

/*
interface FormValues {
  name: string
  filename: string
  date: string
}
*/

const ReportList: FC<Props> = ({ reports }) => {
/*
    const [form] = Form.useForm<FormValues>();

    const {modalMode, closeModal, openAddModal, openEditModal} = useModalState();
    const [reportError, setMutationError] = useState<ApolloError>();
*/
  const { pageSize, setPageSize } = usePageSize('report')

  const columns = useMemo<ColumnsType<ReportDto>>(() => [
    {
      title: '파일 이름',
      dataIndex: 'filename',
      key: 'filename',
      width: 200,
      fixed: 'left',
      render: filename => {
        const link = urlJoin(process.env.REACT_APP_URL, `/report/${filename}`)

        return (
          <a href={link}>{filename}</a>
        )
      },
      sorter: (report1, report2) => sortByText(report1.filename, report2.filename),
      showSorterTooltip: false,

    },
    {
      title: '기준 날짜',
      dataIndex: 'date',
      key: 'date',
      width: 120,
      sorter: (report1, report2) => sortByText(report1.date, report2.date),
      showSorterTooltip: false,
    },
    createdAtColumn('createdAt', (e1, e2) => sortByNumber(e1.createdAt, e2.createdAt)),
  ], [reports])
  /*
    const cleanFormData = () => {
        closeModal();
        form.resetFields();
        setMutationError(undefined);
    };
*/
  return (
    <>
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={reports}
        scroll={{ x: 1000/*true*/ }}
        onChange={(pagination) => setPageSize(pagination.pageSize)}
        pagination={{ showSizeChanger: true, pageSize }}
      />
      {/*
            <Drawer
                title={`SMD ${modalMode}`}
                width={getResponsiveDrawerWidth(400)}
                placement="right"
                closable
                onClose={cleanFormData}
                visible={!_.isEmpty(modalMode)}
                footer={
                    <DrawerFooter
                        formId="formAddSmd"
                        close={cleanFormData}
                        isNew={!form.getFieldValue('id')}
                        onUpdate={onSmdUpdate}
                        onRemove={onSmdRemove}
                    />
                }
            >
                <Form
                    id="formAddSmd"
                    form={form}
                    layout="vertical"
                    labelAlign="right"
                    colon
                    onFinish={onSmdCreate}
                >
                    <Form.Item name="id" hidden>
                    </Form.Item>

                    <Form.Item name="name" label="이름"
                               rules={[{required: true, message: '\'이름\'을 입력해 주세요.'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item name="phoneno" label="전화번호">
                        <Input maxLength={32}/>
                    </Form.Item>
                    <Form.Item name="desc" label="SMD 설명" initialValue="">
                        <TextArea maxLength={255} autoSize={{ minRows: 5, maxRows: 5 }} />
                    </Form.Item>

                </Form>

                <Table
                    size="small"
                    //rowKey="id"
                    columns={columnShops}
                    dataSource={form.getFieldValue('shopNames')?.map(v => ({ name: v}))}
                    pagination={false}
                    style={{ width: "90%" }}
                />

                <DrawerErrorAlert error={smdError}/>
            </Drawer>
*/}
    </>
  )
}

export {ReportList}
