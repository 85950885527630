import {ApolloClient, DocumentNode, useQuery, useSubscription} from "@apollo/client"
import React, {useEffect, useRef, useState} from "react"
import {sortByCreatedAt} from "../utils"
import _ from "lodash"

interface Result<TDto> {
    dtos: TDto[];
    allDtos: TDto[];
    setDtos: React.Dispatch<React.SetStateAction<TDto[]>>;
    setFilter: React.Dispatch<React.SetStateAction<DtoFilter<TDto>>>;
    loading: boolean;
    error: any;
}

export interface LoadingAndError {
   loading: boolean;
   error: any;
}

export interface DtoFilter<TDto> {
    filter: (dtos: TDto[]) => TDto[];
}

interface Props {
    query: DocumentNode;
    queryResultKey: string;
    subscription: DocumentNode;
    updateQueryFunction: (query: DocumentNode, options: { client: ApolloClient<any>, subscriptionData: { data?: any } }) => void;
}

function useDtos<TDto, TDtos>({query, queryResultKey, subscription, updateQueryFunction}: Props): Result<TDto> {
  const [dtos, setDtos] = useState<TDto[]>([])
  const [filterObj, setFilter] = useState<DtoFilter<TDto>>({filter: _.identity})

  const {data, loading, error} = useQuery<TDtos>(query)

  useSubscription(subscription, {
    onSubscriptionData: (options => {
      updateQueryFunction(query, options)
    }
    ),
  })

  const allDtos = useRef<TDto[]>([])

  useEffect(() => {
    if (data) {
      const sorted = data[queryResultKey]
      /*
      let sorted = data[queryResultKey]
      if (sorted.createdAt) {
        sorted = sortByCreatedAt(data[queryResultKey])
      }
      */
      allDtos.current = sorted
      setDtos(sorted)
    }
  }, [data])

  const filtered = filterObj.filter(dtos)
  return {dtos: filtered, allDtos: allDtos.current, setDtos, setFilter, loading, error}
}

export default useDtos
