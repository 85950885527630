import React, { FC, useState } from 'react'
import { ApolloError, useMutation } from '@apollo/client'
import { Drawer, Form, Input, Space, Tag, notification } from 'antd'
import { ModalMode } from '../../types'
import DrawerFooter from '../../components/common/drawer-footer'
import _ from 'lodash'
import DrawerErrorAlert from '../../components/common/drawer-error-alert'
import { FormInstance } from 'antd/lib/form'
import { DivisionFormValues } from './list'
import { ADD_DIVISION, REMOVE_DIVISION, UPDATE_DIVISION } from 'api/graphql/division/mutations'
import { GET_DIVISIONS, Division } from '../../api/graphql/division'
import { afterRemoved } from '../../utils'
import { getResponsiveDrawerWidth } from 'common/layouts'
import TextArea from 'antd/lib/input/TextArea'
import { ChannelDto } from '../../api/graphql/channel'

interface Props {
  divisions: Division[]
  channels: ChannelDto[]
  form: FormInstance
  visible: boolean
  modalMode: ModalMode
  onClose: () => void
}

const DivisionEditDrawer: FC<Props> = ({ 
  form, divisions, channels, visible, onClose, modalMode
}) => {
  const [divisionError, setMutationError] = useState<ApolloError>()
  // const { data: bundle } = useQuery(GET_CHANNELS_CELLS_AMCODES)

  const [addDivision] = useMutation(ADD_DIVISION)
  const [updateDivision] = useMutation(UPDATE_DIVISION)
  const [removeDivisionMutation] = useMutation(REMOVE_DIVISION, {
    update: afterRemoved({query: GET_DIVISIONS, queryResultKey: 'divisions', mutationResultKey: 'removeDivision'})
  })

  const divisionId = form.getFieldValue('id')
  const channelsInDivision = divisionId ? channels.filter(ch => ch.division.id === divisionId) : []

  function onDivisionCreate(values: DivisionFormValues) {
    const variables = { ...values }

    const divisionByName = divisions.map(s => s.name).find(name => name === variables.name)
    if (divisionByName) {
      setMutationError(new ApolloError({errorMessage: "이미 존재하는 매장 분류입니다."}))
      return
    }

    const divisionInput = {
      variables: {
        ...variables
      }
    }

    addDivision(divisionInput)
      .then(() => {
        notification.success({
          message: `매장 분류를 성공적으로 생성하였습니다.`
        })

        cleanFormData()
        onClose()
      })
      .catch((e) => {
        notification.error({
          message: `매장 분류를 추가하는 중에 에러가 발생하였습니다.`
        })

        setMutationError(e)
      })
  }

  const onDivisionUpdate = () => {
    const variables = form.getFieldsValue()

    updateDivision({
      variables: {
        ...variables
      }
    })
      .then(() => {
        notification.success({
          message: `매장 분류를 성공적으로 수정하였습니다.`
        })

        cleanFormData()
        onClose()
      })
      .catch((e) => {
        notification.error({
          message: `매장 분류를 수정하는 중에 에러가 발생하였습니다.`
        })

        setMutationError(e)
      })
  }

  const onDivisionRemove = () => {
    if (channels.find(ch => ch.division.id === divisionId)) {
      notification.error({
        message: `판매 경로가 있는 매장 분류는 삭제할 수 없습니다.`
      })
      return
    }

    removeDivisionMutation({
      variables: {
        id: form.getFieldValue('id'),
      }
    })
      .then(() => {
        notification.success({
          message: `매장 분류를 성공적으로 삭제하였습니다.`
        })

        cleanFormData()
        onClose()
      })
      .catch((e) => {
        notification.error({
          message: `매장 분류를 삭제하는 중에 에러가 발생하였습니다.`
        })

        setMutationError(e)
      })
  }

  const cleanFormData = () => {
    form.resetFields()
    setMutationError(undefined)
  }

  return (
    <Drawer
      title={`매장 분류 ${modalMode}`}
      width={getResponsiveDrawerWidth(400)}
      placement="right"
      closable
      onClose={onClose}
      visible={visible}
      footer={
        <DrawerFooter
          formId="formAddDivision"
          close={() => {
            onClose()
            setMutationError(undefined)
          }
          }
          isNew={modalMode === '추가'}
          onUpdate={onDivisionUpdate}
          onRemove={onDivisionRemove}
        />
      }
    >
      <Form
        id="formAddDivision"
        form={form}
        layout="vertical"
        labelAlign="right"
        onFinish={onDivisionCreate}
        colon
      >
        <Form.Item name="id" hidden>
        </Form.Item>

        <Form.Item
          name="name"
          label="매장 분류 이름"
          rules={[{required: true, message: '매장 분류 이름을 입력해 주세요.'}]}
        >
          <Input placeholder="매장 분류 이름을 입력해 주세요."/>
        </Form.Item>

        <Form.Item
          label="판매 경로 리스트"
        >
          {
            channelsInDivision.length > 0 ?
              <Space size={[0, 8]} wrap>
                { channelsInDivision.map(ch => <Tag key={ch.id}>{ch.name}</Tag>) }
              </Space>
              :
              <span>-</span>
          }
        </Form.Item>

        <Form.Item
          name="desc"
          label="매장 분류 설명"
        >
          <TextArea maxLength={255} autoSize={{ minRows: 3, maxRows: 3 }} 
            placeholder="매장 분류 설명을 입력해 주세요."
          />
        </Form.Item>
      </Form>

      <DrawerErrorAlert error={divisionError}/>
    </Drawer>
  )
}


export { DivisionEditDrawer }
