import React, {FC, useState} from "react"
import {DocumentNode, useMutation} from "@apollo/client"
import useForm from "antd/lib/form/hooks/useForm"
import {Button, Card, Divider, Form, Input, List, notification, Select} from "antd"
import {PlusOutlined} from "@ant-design/icons"
import {Box, Stack} from "@chakra-ui/layout"
import {getMutationVariables} from "../../utils"

export type Mode = "" | "add" | "modify" | "delete";
export type BaseDataType = "cell" | "division" | "channel" | "region" | "amcode";

interface Props {
    title: string;
    namePlaceholder: string;
    data: any[];
    type: BaseDataType;
    mutations: {
        addMutation: DocumentNode;
        updateMutation: DocumentNode;
        removeMutation: DocumentNode;
    };
    dropbox?: {
        elementKey: string;
        elements: JSX.Element[];
    }
}

interface SelectedItem {
    id: string;
    name: string;
    additionalData?: any;
}

const BaseDataCard: FC<Props> = (
  {
    type,
    title,
    mutations: {
      addMutation,
      updateMutation,
      removeMutation,
    },
    namePlaceholder,
    data,
    dropbox,
  }) => {
  const [form] = useForm()

  const dropboxElements = dropbox?.elements
  const dropboxElementKey = dropbox?.elementKey

  const [addData] = useMutation(addMutation)
  const [removeData] = useMutation(removeMutation)
  const [updateData] = useMutation(updateMutation)

  const isDuplicate = (name: string) => data.find(datum => datum.name === name) !== undefined

  const [mode, setMode] = useState<Mode>('')
  const [selectedItem, setSelectedItem] = useState<SelectedItem>()

  const onAdd = () => setMode("add")

  const onConfirm = (values: SelectedItem) => {
    const {id, name, additionalData} = values

    if (mode === 'add') {
      if (isDuplicate(name)) {
        notification.error({
          message: `중복된 '${title}'이 존재합니다.`,
        })

        return
      }

      const variables = getMutationVariables({type, name, additionalData, mode: 'add'})

      addData({variables})
        .then(() => {
          setMode('')
        })
        .catch((e) => {
          console.error(e)

          notification.error({
            message: `'${title}' 생성 중 오류가 발생했습니다.`
          })
        })
    } else {
      const variables = getMutationVariables({type, id, name, additionalData, mode: 'modify'})

      updateData({variables})
        .then(() => {
          setMode('')
        })
        .catch((e) => {
          console.error(e)

          notification.error({
            message: `'${title}' 업데이트 중 오류가 발생했습니다.`
          })
        })
    }
  }

  const onDelete = (id: string) => {
    const variables = getMutationVariables({type, name: id, mode: 'delete'})

    removeData({variables})
      .then(() => {
        setMode('')
      })
      .catch((e) => {
        console.error(e)

        notification.error({
          message: `'${title}' 삭제 중 오류가 발생했습니다.`
        })
      })
  }

  const onUpdateButtonClick = (v: SelectedItem) => {
    setMode('modify')
    setSelectedItem(v)
  }

  const loadMore = () => {
    if (mode === 'add') {
      form.resetFields()

      return (
        <Form
          layout="inline"
          form={form}
          initialValues={{name: ''}}
          style={{marginTop: 8, display: 'flex'}}
          onFinish={onConfirm}
        >
          <Form.Item style={{flex: 2, margin: 0, marginRight: 8, padding: 0}}
            name="name"
            rules={[{required: true, message: '필수 항목입니다.'}]}
          >
            <Input width="100%" placeholder={namePlaceholder} autoFocus size="small" value=""/>
          </Form.Item>

          {dropbox && (
            <Form.Item style={{margin: '0px', padding: '0px'}}
              name="additionalData"
              rules={[{required: true}]}
            >
              <Select
                size="small"
                dropdownMatchSelectWidth={false}
              >
                {dropboxElements}
              </Select>
            </Form.Item>
          )}

          <Stack flex={1} isInline>
            <Form.Item style={{margin: '0px', marginLeft: '8px', padding: '0px', width: 44}}>
              <Button type="link" htmlType="submit" size="small">
                                확인
              </Button>
            </Form.Item>

            <Form.Item style={{margin: '0px', padding: '0px', width: 1}}>
              <Divider type="vertical" style={{margin: '0px', padding: '0px'}}/>
            </Form.Item>

            <Form.Item style={{margin: '0px', padding: '0px', width: 30}}>
              <Button
                type="link"
                onClick={() => setMode('')}
                size="small"
              >
                                취소
              </Button>
            </Form.Item>
          </Stack>
        </Form>
      )
    }
  }

  return (
    <Card
      title={
        <Box textAlign="center">
          {title}
        </Box>
      }
      style={{
        width: 300,
        maxHeight: "100%",
        boxShadow: '0px 0px 8px 3px rgba(192, 192, 192, 0.2)',
      }}
      bodyStyle={
        {
          height: '300px',
          overflow: 'auto',
        }
      }
      size="small"
      actions={[
        <Button key="add-cell"
          type="primary"
          icon={<PlusOutlined style={{color: '#FFFFFF'}}/>}
          size='middle'
          style={{width: '90%'}}
          onClick={onAdd}
          disabled={mode != ''}
        >
          {title} 추가
        </Button>
      ]}
    >
      <List
        loadMore={loadMore()}
        dataSource={data}
        rowKey="id"
        renderItem={(item/*, index*/) => {
          const modifyMode = mode === "modify" && item.id === selectedItem?.id

          return (
            <List.Item
              extra={
                modifyMode ?
                  [
                    <a key="confirm" onClick={() => onConfirm(selectedItem!)} href="#/">확인</a>,
                    <Divider type="vertical" style={{margin: '0px', padding: '0px'}}/>,
                    <a key="cancel" onClick={() => setMode('')} href="#/">취소</a>
                  ] :
                  [
                    <a key="modify" onClick={() => onUpdateButtonClick({
                      id: item.id,
                      name: item.name,
                      additionalData: item[dropboxElementKey!]?.id
                    })}
                    href="#/">수정</a>,
                    <Divider type="vertical" style={{margin: '0px', padding: '0px'}}/>,
                    <a key="remove" onClick={() => onDelete(item.id)} href="#/">삭제</a>
                  ]
              }>
              <Box
                w="70%"
                alignItems="center"
                display="inline-flex"
              >
                <Box
                  w="100%"
                  mr="16px"
                >
                  {
                    modifyMode ?
                      <Input
                        width="100%"
                        name="name"
                        placeholder={namePlaceholder}
                        autoFocus
                        size="small"
                        defaultValue={item.name}
                        onChange={(v) => setSelectedItem({
                          ...selectedItem,
                          id: item.id,
                          name: v.currentTarget.value,
                        })}
                      />
                      : <span>{item.name}</span>
                  }
                </Box>
                {dropbox && (
                  <Box w="35%">
                    {
                      modifyMode ?
                        <Select
                          onSelect={(v) => setSelectedItem({
                            id: item.id,
                            name: item.name,
                            additionalData: v,
                          })}
                          defaultValue={item[dropbox.elementKey].id}
                          size="small"
                          dropdownMatchSelectWidth={false}
                        >
                          {dropbox.elements}
                        </Select>
                        : <span>{item[dropbox.elementKey].name}</span>
                    }
                  </Box>
                )}
              </Box>
            </List.Item>
          )
        }
        }
      />
    </Card>
  )
  
}

export default BaseDataCard
