import React /* { useState, useEffect }*/ from 'react'
// import { useQuery, useMutation } from '@apollo/client'
import { 
  Tag
} from 'antd'
import {
  McState
} from 'api/graphql/rti-mc'

// const { Title, Text } = Typography

function RtiMcDetailMcState(props) {
  const { state } = props
  let tag

  if (state === McState.UNKNOWN || state === McState.OFFLINE) {
    tag = (<Tag color='#bfbfbf'>OFFLINE</Tag>) 
  } else if (state === McState.ONLINE) {
    tag = (<Tag color='#52c41a'>ONLINE</Tag>) 
  } else// if (state === McState.ERROR)
  {
    tag = (<Tag color='#f5222d'>ERROR</Tag>) 
  }

  return (
    tag
  )
}


export { RtiMcDetailMcState }