import React, {FC, useState} from "react"
import {Box, Stack} from "@chakra-ui/layout"
import {Col, Row, Space, Typography, Progress} from "antd"
import {RtiMcDto} from "api/graphql/rti-mc"
import {chartColors} from "common/chart-colors"
import _ from "lodash"
import TvConnection from "models/tv-connection"
import Form, {FormInstance} from "antd/lib/form"
import { CaretRightOutlined } from '@ant-design/icons'
import useChannels from "../../hooks/useChannels"

type ChannelName = "전체 경로" | string

interface ChannelCardProps {
  title: ChannelName
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
  form: FormInstance
  totalTvCount: number
  connectCount: number
  unconnectCount: number
  connectPercent: number
  connectRtiMcsCount: number
  connectRtiMcsPercent: number
  totalRtiMcsCount: number
  unconnectPercent: number
  style?: { headerColor: string }
}

const ChannelCard: FC<ChannelCardProps> = (
  {
    title,
    selected,
    setSelected,
    form,
    //style,
    totalTvCount,
    connectCount,
    connectPercent,
    connectRtiMcsCount,
    connectRtiMcsPercent,
    totalRtiMcsCount,
  }) => {
  const data = {
    labels: ['접속', '미접속'],
    datasets: [{
      data: [connectPercent, 100 - connectPercent],
      backgroundColor: chartColors,
    }],
    text: `${connectPercent}%`,
  }
  /*
  const option = {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false
    },
    cutoutPercentage: 70,
    plugins: {
      datalabels: {
        display: false,
      }
    }
  }
  */
  const [isFirstPage, setFirstPage] = useState(false)

  if (isFirstPage) {
    data.datasets[0].data = [connectRtiMcsPercent, 100 - connectRtiMcsPercent]
  }

  const onClick = () => {
    if (title === '전체 경로') {
      form.setFieldsValue({channelName: undefined})
    } else {
      form.setFieldsValue({channelName: title})
    }

    setSelected(title)
  }

  const onCaretButtonClicked = (e) => {
    e.stopPropagation()
    setFirstPage(!isFirstPage)
  }

  const isSelected = () => selected === title

  return (
    <Stack
      minWidth={108}
      h={125}
      border={isSelected() ? "0.5px solid #404040" : "0.5px solid #a0a0a0"}
      onClick={onClick}
      cursor="pointer"
      /*boxShadow={isSelected() ? "5px 6px #001a72" : 'none'}>*/
      boxShadow={isSelected() ? '0px 0px 5px 3px rgba(32, 32, 32, 0.5)' : 'none' }
    >
      <Box
        w="100%"
        height="24px"
        background={isSelected() ? '#faad14' : '#fffbe6'}/*{style?.headerColor ? style.headerColor : "#1890ff"}*/
        textAlign="center"
        borderBottom={isSelected() ? "0.5px solid #404040" : "0.5px solid #a0a0a0"}
      >
        <Typography.Text strong style={{color: '#404040'/*'white'*/, fontSize: 14}}>
          {title}
        </Typography.Text>
      </Box>

      <Form.Item name="channelName" hidden>
      </Form.Item>

      <Stack align="center">
        <Row style={{width: '100%'}}>
          {/*isFirstPage && (
                        <Col span={4}>
                            <Box w="100%" h={30}>
                                <CaretLeftOutlined style={{fontSize: '1rem', color: '#606060'}} onClick={onCaretButtonClicked}/>
                            </Box>
                        </Col>
                    )*/}

          <Col offset={4/*!isFirstPage ? 4 : 0*/} span={15}>
            <Stack spacing={1}>
              <Box
                fontSize={12}
                textAlign="center"
              >
                {!isFirstPage ? "TV 접속률" : "매장 접속률"}
              </Box>
              <Box
                fontSize="0.625rem"
                textAlign="center">
                {!isFirstPage ? (connectCount + "/" + totalTvCount) : (connectRtiMcsCount + "/" + totalRtiMcsCount)}
              </Box>
            </Stack>
          </Col>
          {/*!isFirstPage &&*/ (
            <Col offset={1} span={4}>
              <Box w="100%" h={30}>
                <CaretRightOutlined style={{fontSize: '0.8rem', color: '#808080'}} onClick={onCaretButtonClicked}/>
              </Box>
            </Col>
          )}
        </Row>

        <Box>
          <Box position="relative" alignSelf="center">
            <Progress type="circle" width={44} percent={!isFirstPage ? connectPercent : connectRtiMcsPercent}
              strokeWidth={10}
              status='normal'
              format={percent => `${percent}%`}
              style={{margin: 0, padding: 0}}
            />
            {/*
                        <Doughnut
                            data={data}
                            options={option}
                            width={45}
                            height={45}/>

                        <Box
                            position="absolute"
                            top="50%"
                            left="50%"
                            transform="translate(-50%, -50%)"
                            textAlign="center">
                            <Box>{!isFirstPage ? connectPercent : connectRtiMcsPercent}%</Box>
                        </Box>
                        */}
          </Box>
        </Box>
      </Stack>

    </Stack>
  )
}

interface Props {
    rtiMcs: RtiMcDto[];
    form: FormInstance;
}

const ChannelsComponent: FC<Props> = ({rtiMcs, form}) => {
  const {allChannels} = useChannels()
  const channelNames = allChannels.map(c => c.name)

  const [selected, setSelected] = useState<ChannelName>("전체 경로")
  const rtiMcsGroupedByChannelName = _.groupBy(rtiMcs, (r) => r.mediacenter.channel.name)

  return (
    <Box display="flex">
      <Box p="8px" boxShadow='0px 0px 8px 3px rgba(192, 192, 192, 0.5)'>
        <ChannelCard
          form={form}
          title="전체 경로"
          selected={selected}
          setSelected={setSelected}
          {...TvConnection.getGroupStatistics(rtiMcs)}
          {...TvConnection.getRtiMcStatistics(rtiMcs)}
        />
      </Box>
      <Box w="100%" p="8px" ml={16} /*border="1px solid #a0a0a0"*/ justify="space-between" overflowX="scroll"
        boxShadow='0px 0px 8px 3px rgba(192, 192, 192, 0.5)'
      >
        <Space>
          {channelNames.map(channelName => (
            <ChannelCard
              form={form}
              key={channelName}
              selected={selected}
              setSelected={setSelected}
              style={{headerColor: '#fffbe6'/*'#42b9f5'*/}}
              title={channelName}
              {...TvConnection.getGroupStatistics(rtiMcsGroupedByChannelName[channelName])}
              {...TvConnection.getRtiMcStatistics(rtiMcsGroupedByChannelName[channelName])} />
          ))}
        </Space>
      </Box>
    </Box>
  )
}

export default ChannelsComponent
