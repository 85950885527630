import React, { FC, useState, useEffect, useRef } from 'react'
import { ColumnsType } from 'antd/es/table'
import { Divider, Table, Tag } from 'antd'
import { ConnRate } from 'components/mediacenter/conn-rate'
import { TvGroupStatusList } from 'components/mediacenter/tv-group-status-list'
import { McState, RtiMcDto } from 'api/graphql/rti-mc'
import { RtiShopDetail } from './detail'
import Button from 'antd/lib/button'
import { Box, HStack } from '@chakra-ui/layout'
import TvConnection from '../../models/tv-connection'
import usePageSize from '../../hooks/usePageSize'
import { sortByNumber, sortByText } from '../../utils'
import { format } from 'date-fns'
import { MclogModal } from './mclog-modal'
import { columnText, columnShopName, columnChannelName } from '../../components/table'
import useChannels from '../../hooks/useChannels'
import useGroups from '../../hooks/useGroups'
import useCells from '../../hooks/useCells'

interface Props {
  rtiMcs: RtiMcDto[]
}

const ElapsedTime: React.FC<{ pastDate: Date }> = ({ pastDate }) => {
  let text = ' - '
  let color = '#c0c0c0'

  if (pastDate.getTime() !== 0) {
    const elapsedSec = (new Date().getTime() - pastDate.getTime()) / 1000

    if (elapsedSec > 0) {
      if (elapsedSec < 60*60) {
        text = `${Math.floor(elapsedSec/60)}m`
      } else if (elapsedSec < 60*60*24) {
        text = `${Math.floor(elapsedSec/(60*60))}h`
      } else if (elapsedSec < 60*60*24*30) {
        text = `${Math.floor(elapsedSec/(60*60*24))}d`
      } else {
        text = `${Math.floor(elapsedSec/(60*60*24*30))}M`
      }
    }
      
    if (elapsedSec < 60*60) { // 0h ~ 1h
      color = '#000000'
    } else if (elapsedSec < 60*60*4) { // 1h ~ 4h
      color = '#404040'
    } else if (elapsedSec < 60*60*12) { // 4h ~ 12h
      color = '#808080'
    } else if (elapsedSec < 60*60*24) { // 12h ~ 24h
      color = '#a0a0a0'
    } else { // 24h ~
      color = '#c0c0c0'
    }

    return (
      <Tag style={{ color: color }}>
        {text}
      </Tag>
    )
  } else {
    return null
  }
}

const RtiShopList: FC<Props> = ({ rtiMcs }) => {
  const [, setLastUpdate] = useState<Date>(new Date())

  const { channels } = useChannels()
  const { cells } = useCells()
  const { groups: allGroups } = useGroups()

  const columns: ColumnsType<RtiMcDto> = [
    columnShopName(
      ['mediacenter', 'shop', 'name'],
      (e1, e2) => sortByText(e1.mediacenter.shop.name, e2.mediacenter.shop.name),
      (value, e1) => e1.mediacenter.shop.name.includes(value),
      useRef(null),
      'center',
      (record) => handleItemClick(record.id),
    ),
    columnChannelName(
      ['mediacenter', 'channel', 'name'],
      (e1, e2) => sortByText(e1.mediacenter.channel.name, e2.mediacenter.channel.name),
      (value, e1) => e1.mediacenter.channel.id === value,
      channels.map((ch) => ({ text: ch.name, value: ch.id }))
    ),
    columnText("지역", ["mediacenter", "cell", "name"], "cell", 100, "left",
      { filters: cells.map((cell) => ({ text: cell.name, value: cell.name })) }
    ),
    {
      title: '상태',
      dataIndex: 'state',
      key: 'state',
      width: 120,
      align: 'center',
      render: (state, rtiMcDto) =>
        <HStack m={0} p={0}>
          <Button style={{
            width: 48,
            height: 22,
            margin: 0,
            padding: 0,
            color: "white",
            backgroundColor: (state === McState.ONLINE) ? "#50c020" : '#c0c0c0',
            borderColor: (state === McState.ONLINE) ? "#508020" : '#808080',
            fontSize: 12
          }}
          onClick={() => handleMclogClick(rtiMcDto.id)}
          >
            {state === McState.ONLINE ? "접속" : "미접속"}
          </Button>
          <Divider style={{ borderColor: "#a0a0a0" }} type="vertical"/>
          <ElapsedTime pastDate={new Date(rtiMcDto.updatedAt)} />
        </HStack>
      ,
      sorter: (rtiMc1, rtiMc2) => sortByNumber(rtiMc1.state, rtiMc2.state),
      showSorterTooltip: false,
    },
    {
      title: 'TV 대수',
      dataIndex: 'groups',
      key: 'tvCount',
      align: 'center',
      width: 100,
      render: (groups, rtiMcDto) => <span>{TvConnection.getTvCount(rtiMcDto)}</span>,
      sorter: (rtiMc1, rtiMc2) => sortByNumber(TvConnection.getTvCount(rtiMc1), TvConnection.getTvCount(rtiMc2)),
      showSorterTooltip: false,
    },
    {
      title: '접속률',
      dataIndex: 'groups',
      key: 'connRate',
      width: 80,
      align: 'center',
      render: (groups, record) => {
        const counts = TvConnection.getGroupStatistics([record])

        if (counts) {
          return (
            <ConnRate
              key={record.id}
              totalCount={counts.totalTvCount}
              onlineCount={counts.connectCount}
              offlineCount={counts.unconnectCount}
              errorCount={counts.errorCount}
              disabled={record.state !== McState.ONLINE}
            />
          )
        } else {
          return (
            <ConnRate
              key={record.id}
              totalCount={0}
              onlineCount={0}
              offlineCount={0}
              errorCount={0}
              disabled={record.state !== McState.ONLINE}
            />
          )
        }
      },
      sorter: (rtiMc1, rtiMc2) => {
        const {connectPercent: connPercent1} = TvConnection.getGroupStatistics([rtiMc1])
        const {connectPercent: connPercent2} = TvConnection.getGroupStatistics([rtiMc2])

        return sortByNumber(connPercent1, connPercent2)
      },
      showSorterTooltip: false,
    },
    {
      title: 'TV 그룹 별 접속 현황',
      dataIndex: 'groups',
      key: 'connStatus',
      align: 'center',
      render: (groups, record) => {
        return <TvGroupStatusList groups={groups} allGroups={allGroups} disabled={record.state !== McState.ONLINE} />
      },
    },
    {
      title: '연결 시각',
      dataIndex: ['mediacenter', 'connectedAt'],
      key: 'connectedAt',
      width: 100,
      render: connectedAt => {
        const { title, text } = new Date(connectedAt).getTime() !== 0 ?
          {
            title: format(new Date(connectedAt), "yyyy-MM-dd HH:mm:ss"),
            text: format(new Date(connectedAt), "yyyy-MM-dd")
          } : {
            title: '', text: ''
          }
        
        return <span title={title}>{text}</span>
      },
      sorter: (rtiMc1, rtiMc2) => sortByNumber(rtiMc1.mediacenter.connectedAt, rtiMc2.mediacenter.connectedAt),
      showSorterTooltip: false,
    },
    {
      title: '끊김 시각',
      dataIndex: ['mediacenter', 'disconnectedAt'],
      key: 'disconnectedAt',
      width: 100,
      render: disconnectedAt => {
        const { title, text } = new Date(disconnectedAt).getTime() !== 0 ?
          {
            title: format(new Date(disconnectedAt), "yyyy-MM-dd HH:mm:ss"),
            text: format(new Date(disconnectedAt), "yyyy-MM-dd")
          } : {
            title: '', text: ''
          }
        
        return <span title={title}>{text}</span>
      },
      sorter: (rtiMc1, rtiMc2) => sortByNumber(rtiMc1.mediacenter.disconnectedAt, rtiMc2.mediacenter.disconnectedAt),
      showSorterTooltip: false,
    },
  ]

  const [visibleMclogModal, setMclogModalVisible] = useState(false)

  useEffect(() => {
    const timer = setInterval(() => {
      setLastUpdate(new Date())
    }, 1000 * 60)
      
    return function cleanup() {
      clearInterval(timer)
    }
  }, [])

  function handleMclogClick(mediacenterId) {
    const rtiMc = rtiMcs.find(rtiMc => rtiMc.id === mediacenterId)
    setSelRtiMc(rtiMc)
    setMclogModalVisible(true)
  }

  const [selRtiMc, setSelRtiMc] = useState<RtiMcDto>()
  const [visibleDetailDrawer, setVisibleDetailDrawer] = useState(false)
  const { pageSize, setPageSize } = usePageSize('rtishop')

  function handleItemClick(mediacenterId) {
    const rtiMc = rtiMcs.find(rtiMc => rtiMc.id === mediacenterId)
    setSelRtiMc(rtiMc)
    setVisibleDetailDrawer(true)
  }

  function handleDrawerAndModalClosed() {
    setSelRtiMc(undefined) 

    setVisibleDetailDrawer(false)
    setMclogModalVisible(false)
  }

  return (
    <Box>
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={rtiMcs}
        scroll={{ x: 1000/*true*/ }}
        onChange={(pagination) => setPageSize(pagination.pageSize)}
        pagination={{ showSizeChanger: true, pageSize }}
      />

      {selRtiMc && visibleDetailDrawer && (
        <RtiShopDetail
          rtiMc={selRtiMc}
          visible={visibleDetailDrawer}
          onClose={() => handleDrawerAndModalClosed()}
        />
      )}

      {selRtiMc && visibleMclogModal && (
        <MclogModal
          rtiMc={selRtiMc}
          visible={visibleMclogModal}
          onClose={() => handleDrawerAndModalClosed()}
        />
      )}

    </Box>
  )
}

export { RtiShopList }
