import React, {ChangeEvent, FC, useMemo, useState,} from 'react'
import {ApolloError, useMutation} from '@apollo/client'
import {Button, Drawer, Form, Input, notification, Select, Space, Table} from 'antd'
import {ColumnsType} from 'antd/es/table'
import {UserAddOutlined} from '@ant-design/icons'
import {AccountDto, ADD_ACCOUNT, REMOVE_ACCOUNT, UPDATE_ACCOUNT,} from 'api/graphql/account'
import {ChannelDto} from "../../api/graphql/channel"
import {AuthorizationLevel} from "../../features/auth"
import { Account/*, Roles*/} from "../../models"
import {Box, Grid} from "@chakra-ui/layout"
import _ from 'lodash'
import {AccountFormValues} from "../page-account"
import DrawerFooter from "components/common/drawer-footer"
import DrawerErrorAlert from "components/common/drawer-error-alert"
import useModalState from "../../hooks/useModalState"
import usePageSize from "../../hooks/usePageSize"
import {sortByNumber, sortByText} from "utils"
import useCells from "../../hooks/useCells"
import useShops from 'hooks/useShops'
import TextArea from "antd/lib/input/TextArea"
import format from "date-fns/format"
import { getResponsiveDrawerWidth } from 'common/layouts'
import { createdAtColumn } from "../../common/created-at-column"

interface Props {
  accounts: AccountDto[]
  channels: ChannelDto[]
}

type FormValuesWithoutPassword = Omit<AccountFormValues, 'password'>

const AccountList: FC<Props> = ({ accounts /*, channels */}) => {
  const [form] = Form.useForm()

  const { pageSize, setPageSize } = usePageSize('account')

  const {allCells: cells} = useCells()
  const {allShops, /*setFilter*/} = useShops()

  const {modalMode, closeModal, openAddModal, openEditModal} = useModalState()
  //const [selectedRole, selectRole] = useState<Roles>()
  const [accountError, setMutationError] = useState<ApolloError>()
  const [duplicatedError, setDuplicatedError] = useState<string>('')

  const columns = useMemo<ColumnsType<AccountDto>>(() => [
    /*
        {
            title: 'no',
            key: 'index',
            width: 30,
            render: (text, record, index) => index + 1,
        },
        */
    {
      title: '아이디',
      dataIndex: 'userid',
      key: 'userid',
      width: 60,
      fixed: 'left',
      sorter: (accountDto1, accountDto2) => sortByText(accountDto1.userid, accountDto2.userid),
      showSorterTooltip: false,
      render: (userid) => {
        const account = accounts.find(a => a.userid === userid)
        const onClick = () => {
          if (account) {
            const shop = allShops.find(s => s.id === account.shop?.id)

            const formValues: FormValuesWithoutPassword = {
              ...account,
              channelId: account.channel?.id,
              cellId: shop?.cell.id,
              shopId: shop?.id,
              regionAndCellId: shop ? [shop.region.id, shop.cell.id] : undefined
            }

            form.setFieldsValue(formValues)
            //selectRole(account.role)
            openEditModal()
          }
        }

        return (
          <a onClick={onClick}>
            {userid}
          </a>
        )
      }
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      width: 100,
      sorter: (accountDto1, accountDto2) => sortByText(accountDto1.name, accountDto2.name),
      showSorterTooltip: false,
    },
    /*
        {
            title: '판매 경로',
            dataIndex: 'channel',
            key: 'channel',
            render: (channel: { id: string, name: string }) => <span>{channel?.name}</span>,
            sorter: (accountDto1, accountDto2) => sortByText(accountDto1.channel?.name, accountDto2.channel?.name),
            showSorterTooltip: false,
        },
*/
    {
      title: '레벨',
      dataIndex: 'level',
      key: 'level',
      width: 60,
      render: (level: AuthorizationLevel) => <span>{Account.levels().get(level)}</span>,
      sorter: (accountDto1, accountDto2) => sortByNumber(accountDto1.level, accountDto2.level),
      showSorterTooltip: false,
    },
    {
      title: '소속',
      dataIndex: 'role',
      key: 'role',
      width: 60,
      render: role => <span>{Account.roles().get(role)}</span>,
      sorter: (accountDto1, accountDto2) => sortByNumber(accountDto1.role, accountDto2.role),
      showSorterTooltip: false,
    },
    {
      title: '전화번호',
      dataIndex: 'phoneno',
      key: 'phoneno',
      width: 100,
      sorter: (accountDto1, accountDto2) => sortByText(accountDto1.phoneno, accountDto2.phoneno),
      showSorterTooltip: false,
    },
    {
      title: '이메일',
      dataIndex: 'email',
      key: 'email',
      width: 120,
      sorter: (accountDto1, accountDto2) => sortByText(accountDto1.email, accountDto2.email),
      showSorterTooltip: false,
    },
    createdAtColumn('createdAt', (e1, e2) => sortByNumber(e1.createdAt, e2.createdAt)),
  ], [accounts, allShops, cells])

  const [addAccount] = useMutation(ADD_ACCOUNT)
  const [updateAccountMutation] = useMutation(UPDATE_ACCOUNT)
  const [removeAccountMutation] = useMutation(REMOVE_ACCOUNT)

  /*const cascaderOptions = */_.uniqBy(cells.map(cell => {
    const region = cell.region

    return {
      value: region.id,
      label: region.name,
      children: cells.filter(cell =>
        cell.region.id === region.id
      ).map(cell => {
        return {
          value: cell.id,
          label: cell.name,
        }
      })
    }
  }), (o: { value: string }) => o.value)

  const onAccountCreate = (variables: AccountFormValues) => {
    addAccount({
      variables
    })
      .then(() => {
        notification.success({
          message: `성공적으로 사용자를 추가했습니다.`
        })
        cleanFormData()
      }).catch((e) => {
        notification.error({
          message: `사용자를 추가 중에 에러가 발생했습니다.`
        })

        setMutationError(e)
      })
  }

  const onAccountUpdate = () => {
    updateAccountMutation({
      variables: form.getFieldsValue()
    }).then(() => {
      notification.success({
        message: `성공적으로 사용자를 업데이트했습니다.`
      })

      cleanFormData()
    })
      .catch((e) => {
        notification.error({
          message: `사용자를 업데이트 중에 에러가 발생했습니다.`
        })

        setMutationError(e)
      })
  }

  const onAccountRemove = () => {
    removeAccountMutation({
      variables: {
        id: form.getFieldValue('id'),
      }
    }).then(() => {
      notification.success({
        message: `성공적으로 사용자를 제거했습니다.`
      })

      cleanFormData()
    })
      .catch((e) => {
        notification.error({
          message: `사용자를 제거 중에 에러가 발생했습니다.`
        })

        setMutationError(e)
      })
  }

  const cleanFormData = () => {
    setDuplicatedError('')
    closeModal()
    form.resetFields()
    setMutationError(undefined)
  }

  /*
  const onRoleChange = (role: Roles) => {
    selectRole(role)
  }
  */

  const checkDuplicateUserId = (e: ChangeEvent<HTMLInputElement>) => {
    const existingAccount = accounts.find(account => account.userid === e.currentTarget.value)
    if (existingAccount) {
      setDuplicatedError("이미 존재하는 아이디 입니다.")
    } else {
      setDuplicatedError('')
    }
  }

  //const checkChannelShowable = (role: Roles) => role === Roles.SALES_CHANNEL || role === Roles.SHOP

  /*
  const regionChanged = ([regionId, cellId]: [string, string]) => {
    const prevCellId = form.getFieldValue('cellId')

    if (prevCellId !== cellId) {
      form.resetFields(['shopId'])
      setFilter({filter: dtos => dtos.filter(dto => dto.cell.id === cellId)})
    }
  }
  */

  return (
    <>
      <Box w="100%" textAlign="right" mb="10px">
        <Space direction="horizontal">
          <Button type="primary" icon={<UserAddOutlined/>}
            onClick={openAddModal}
          >
            추가
          </Button>
        </Space>
      </Box>
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={accounts}
        scroll={{ x: 1000/*true*/ }}
        onChange={(pagination) => setPageSize(pagination.pageSize)}
        pagination={{ showSizeChanger: true, pageSize }}
      />
      <Drawer
        title={`사용자 ${modalMode}`}
        width={getResponsiveDrawerWidth(400)}
        placement="right"
        closable
        onClose={cleanFormData}
        visible={!_.isEmpty(modalMode)}
        footer={
          <DrawerFooter
            formId="formAddAccount"
            close={cleanFormData}
            isNew={!form.getFieldValue('id')}
            onUpdate={onAccountUpdate}
            onRemove={onAccountRemove}
          />
        }
      >
        <Form
          id="formAddAccount"
          form={form}
          layout="vertical"
          labelAlign="right"
          colon
          onFinish={onAccountCreate}
        >
          <Form.Item name="id" hidden>
          </Form.Item>

          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Form.Item
              name="userid"
              label="아이디"
              rules={[{required: true, message: '\'아이디\'를 입력해 주세요.'}, {
                pattern: new RegExp(/[a-zA-Z0-9]+/),
                message: "아이디는 영문자, 숫자만 허용됩니다."
              }]}
              {
                ...!_.isEmpty(duplicatedError) && {
                  help: duplicatedError,
                  validateStatus: "error"
                }
              }
            >
              <Input maxLength={32} disabled={modalMode === "수정"} onChange={checkDuplicateUserId}/>
            </Form.Item>
            <Form.Item name="password" label="패스워드"
              rules={[{required: modalMode === '추가', message: '\'패스워드\'를 입력해 주세요.'}]}
            >
              <Input.Password maxLength={32}/>
            </Form.Item>
          </Grid>
          <Form.Item name="name" label="이름"
            rules={[{required: true, message: '\'이름\'을 입력해 주세요.'}]}
          >
            <Input/>
          </Form.Item>

          <Grid templateColumns="repeat(2, 1fr)" gap={6}>
            <Form.Item
              name="level"
              label="레벨"
              rules={[{required: true, message: '\'레벨\'을 선택해 주세요.'}]}
            >
              <Select
                placeholder="레벨을 선택해 주세요"
                dropdownMatchSelectWidth={false}
              >
                {Array.from(Account.levels().entries()).map((entry) => (
                  <Select.Option key={entry[1]} value={entry[0]}>{entry[1]}</Select.Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item name="role" label="소속"
              rules={[{required: true, message: '\'소속\'을 선택해 주세요.'}]}
            >
              <Select
                //onChange={onRoleChange}
                placeholder="소속을 선택해 주세요"
              >
                {Array.from(Account.roles().entries()).map((entry) => (
                  <Select.Option key={entry[1]} value={entry[0]}>{entry[1]}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Grid>
          {/*
                    {!_.isNil(selectedRole) && checkChannelShowable(selectedRole) && (
                        <Stack>
                            <Form.Item name="channelId" label="판매 경로"
                                    rules={[{required: true, message: '\'판매 경로\'를 선택해 주세요.'}]}
                            >
                                <Select
                                    placeholder="판매 경로를 선택해 주세요"
                                    dropdownMatchSelectWidth={false}
                                >
                                    {channels.map(channel => (
                                        <Select.Option key={channel.id}
                                                    value={channel.id}>{channel.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="regionAndCellId"
                                label="지역"
                                rules={[{required: true, message: '지역을 선택해 주세요.'}]}
                            >
                                <Cascader
                                    onChange={(v) => regionChanged(v as [string, string])}
                                    options={cascaderOptions}
                                    placeholder="지역을 선택해 주세요."
                                />
                            </Form.Item>

                            {selectedRole === Roles.SHOP && (
                                <Form.Item
                                    name="shopId"
                                    label="매장"
                                    rules={[{required: true, message: '매장을 선택해 주세요.'}]}
                                >
                                    <Select
                                        placeholder="매장을 선택해 주세요"
                                        dropdownMatchSelectWidth={false}
                                    >
                                        {shops.map(shop => (
                                            <Select.Option
                                                key={shop.id}
                                                value={shop.id}
                                            >
                                                {shop.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            )}
                        </Stack>
                    )}
*/}
          <Form.Item name="phoneno" label="전화번호">
            <Input maxLength={32}/>
          </Form.Item>
          <Form.Item name="email" label="이메일">
            <Input maxLength={32}/>
          </Form.Item>
          <Form.Item name="memo" label="메모">
            <TextArea maxLength={255} autoSize={{ minRows: 3, maxRows: 3 }} />
          </Form.Item>
        </Form>
        <DrawerErrorAlert error={accountError}/>
      </Drawer>
    </>
  )
}

export {AccountList}
