import React, { useState } from 'react'
import { Col, Progress, Radio, Row, Space, Typography, Divider } from 'antd'
import useChannels from "../../hooks/useChannels"
import useRtiMcs from "../../hooks/useRtiMcs"
import TvConnection from "../../models/tv-connection"
import { Box, Stack, } from '@chakra-ui/layout'
import _ from "lodash"
import { HorizontalBar } from "react-chartjs-2"
import PairingUpdateStatus from "../../models/pairing-update-status"
import * as chartjs from "chart.js"
import { Options } from "chartjs-plugin-datalabels/types/options"
import ConnectionStatus, { StatusPeriod } from "./connection-status"

import { isMobile } from 'react-device-detect'

const { Title, Text } = Typography
//const {TabPane} = Tabs

interface SummaryChannelCardProps {
  title: string
  color: string
  totalTvCount: number
  connectCount: number
  connectPercent: number
}

function SummaryCard(props) {
  const {title, content} = props

  return (
    <Box
      w="45%"
      boxShadow='0px 0px 8px 3px rgba(192, 192, 192, 0.2)'
    >
      <Stack>
        <Box textAlign="center">
          <Text style={{color: '#606060'}} strong>{title}</Text>
        </Box>
        <Box textAlign="center">
          <Title level={4} style={{margin: 0}}>{content}</Title>
        </Box>
      </Stack>
    </Box>
  )
}

const SummaryChannelCard: React.FC<SummaryChannelCardProps> = (props) => {
  const { title, color, totalTvCount, connectCount, connectPercent } = props

  return (
    <Stack w="100px" border="1px solid #aaa">
      <div style={{height: 20, textAlign: 'center'}}>
        <Text style={{ color: color, margin: 0 }} strong>{title}</Text>
      </div>
      <div style={{height: 50, textAlign: 'center'}}>
        <Progress type="circle" width={40} strokeWidth={16} percent={connectPercent}/>
      </div>
      <div style={{textAlign: 'center'}}>
        <Text
          style={{
            color: '#606060',
            margin: 0
          }}
          strong
        >
          {connectCount} / {totalTvCount}
        </Text>
      </div>

    </Stack>
  )
}

function DashboardSummary() {
  // const [lastUpdatedAt, setLastUpdatedAt] = React.useState<Date>()
  const { rtiMcs } = useRtiMcs()
  const { channels } = useChannels()

  const [connStatusPeriod, setConnStatusPeriod] = useState<StatusPeriod>('day')
  /*
  React.useEffect(() => {
    if (!_.isEmpty(rtiMcs)) {
      setLastUpdatedAt(new Date())
    }
  }, [rtiMcs])
  */
 
  const { connectCount, connectPercent, totalTvCount } = TvConnection.getGroupStatistics(rtiMcs)
  const { connectRtiMcsCount, totalRtiMcsCount } = TvConnection.getRtiMcStatistics(rtiMcs)

  const rtiMcsGroupedByChannelName = _.groupBy(rtiMcs, (r) => r.mediacenter.channel.name)
  const channelNames = channels.map(c => c.name)
  const channelUpdateCompletePercents = channelNames
    .map(k => {
      if (rtiMcsGroupedByChannelName[k]) {
        return PairingUpdateStatus.getUpdateStatus(rtiMcsGroupedByChannelName[k] ?? []).updateCompletePercent
      } else {
        return 0
      }
    })

  // const colors = _.repeat(randomColor({format: 'rgba'}), channelNames.length);

  const updateStatusData = {
    labels: channelNames,
    datasets: [
      {
        data: channelUpdateCompletePercents,
        backgroundColor: '#1890ff', //channelColors, // colors,
        borderColor: '#1890ff', // channelColors, // colors,
        borderWidth: 1,
      },
    ],
  }

  const updateStatusOption: chartjs.ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            min: 0,
            max: 100,
          },
          scaleLabel: {
            display: true,
            labelString: '배포 완료율(%)',
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      enabled: true
    },
    plugins: {
      datalabels: {
        display: true,
        align: 'center',
        anchor: 'center',
        color: 'white',
        formatter: (value, context) => {
          const {confirmedAt} = PairingUpdateStatus.getUpdateStatus(rtiMcsGroupedByChannelName[channelNames[context.datasetIndex]])
          return confirmedAt
        }
      } as Options,
    },
  }

  return (
    <Stack spacing={30}>
      <Row gutter={[16, 16]}>
        <Col span={isMobile ? 24 : 4}>
          <Stack justify="space-between" height="100%">
            <Box h="45%" display="flex" justifyContent="space-between">
              <SummaryCard title="등록 매장" content={totalRtiMcsCount}/>
              <SummaryCard title="등록 TV" content={totalTvCount}/>
            </Box>

            <Box h="45%" display="flex" justifyContent="space-between">
              <SummaryCard title="접속 매장" content={connectRtiMcsCount}/>
              <SummaryCard title="접속 TV" content={connectCount}/>
            </Box>
          </Stack>
        </Col>

        <Col span={isMobile ? 24 : 20}>
          <Stack padding="0.5rem" boxShadow='0px 0px 8px 3px rgba(192, 192, 192, 0.2)'>
            <Stack justify="space-between" isInline>
              <Box display="flex">
                <h3 style={{marginRight: "3px"}}>
                  판매경로 별 접속률
                </h3>
                {/*
                  lastUpdatedAt && (
                      <Text style={{color: "#606060"}}>
                          (마지막 업데이트: {format(new Date(lastUpdatedAt), "yyyy/MM/dd hh:mm:ss")})
                      </Text>
                  )
                */}
              </Box>
              {/*
                <Stack isInline>
                    <Stack isInline justify="center" align="center">
                        <Box w={15} h={15} backgroundColor="#1b90ff"/>
                        <Box>
                            접속률
                        </Box>
                    </Stack>
                    <Stack isInline justify="center" align="center">
                        <Box w={15} h={15} backgroundColor="#f5f5f5"/>
                        <Box>
                            미접속률
                        </Box>
                    </Stack>
                </Stack>
              */}
            </Stack>
            <Stack
              w="100%"
              // overflowX="scroll"
              overflowX="auto"
              alignItems="center"
              isInline
            >
              <Space>
                <SummaryChannelCard
                  title="전체"
                  color="#000000"
                  totalTvCount={totalTvCount}
                  connectCount={connectCount}
                  connectPercent={connectPercent}
                />
                <Divider type="vertical" style={{height: 100, backgroundColor: '#e0e0e0'}}/>
                {
                  channels.map(channel =>
                    <SummaryChannelCard
                      key={channel.id}
                      title={channel.name}
                      color="#606060"
                      {...TvConnection.getGroupStatistics(rtiMcsGroupedByChannelName[channel.name])}
                    />
                  )
                }
              </Space>
            </Stack>
          </Stack>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={isMobile ? 24 : 12}>
          <Stack
            w="100%"
            boxShadow='0px 0px 8px 3px rgba(192, 192, 192, 0.2)'
            padding='5px'
          >
            <Stack isInline>
              <Box as="h3" px="1em" flex={1}>
                접속 현황
              </Box>

              <Box px="1em">
                <Radio.Group defaultValue="day" onChange={(e) => setConnStatusPeriod(e.target.value)}>
                  <Radio.Button value="month">1개월</Radio.Button>
                  <Radio.Button value="week">1주</Radio.Button>
                  <Radio.Button value="day">1일</Radio.Button>
                </Radio.Group>
              </Box>
            </Stack>

            <Box position="relative" minH="400px" px="1em">
              <ConnectionStatus period={connStatusPeriod} />
            </Box>
          </Stack>
        </Col>
        <Col span={isMobile ? 24 : 12}>
          <Stack
            w="100%"
            boxShadow='0px 0px 8px 3px rgba(192, 192, 192, 0.2)'
            padding='5px'
          >
            <Box as="h3" px="1em">
              배포 현황
            </Box>

            <Box position="relative" minH="400px">
              <HorizontalBar data={updateStatusData} options={updateStatusOption}/>
            </Box>
          </Stack>
        </Col>
      </Row>
    </Stack>
  )
}

export { DashboardSummary }
