import React, {FC} from 'react'
import {Box, Stack} from "@chakra-ui/layout"
import Typography from "antd/lib/typography"
import {Divider} from "antd"
import _ from 'lodash'
import {Col, Row} from "antd/lib/grid"
import Button from "antd/lib/button"
import {SearchOutlined} from '@ant-design/icons'
import Form, {FormInstance} from "antd/lib/form"
import { isMobile } from 'react-device-detect'

interface Props {
    searchForm: FormInstance;
    title?: string;
    searchItems: JSX.Element[];
    onSearch: () => void;
    hideSearchButton?: boolean;
}

export const ListHeader: FC<Props> = ({searchForm, title, searchItems, onSearch, hideSearchButton = false}) => {

  const itemsInRow = searchItems.map((searchItem, index) =>
    <Col span={isMobile ? 24 : 6} key={index}>
      {searchItem}
    </Col>
  )
    
  const rows = _.chunk(itemsInRow, 4)
  //const groupByX = _.chunk(searchItems, 2);
  //const groupByX = _.chunk(searchItems, 4);
  //const groupByX = _.chunk(searchItems, 1);

  return (
    <Stack spacing={16}>
      {title && (
        <Typography.Title level={3}>
          {title}
        </Typography.Title>
      )}

      <Box>
        <Form form={searchForm}>
          {
            rows.map((row, index) => (
              <Row key={index} gutter={[8, 8]} style={{ marginBottom: 8 }}>
                {row}
              </Row>
            ))
            /*
                        // groupByTwo.map((group, index) => (
                        groupByX.map((group, index) => (
                            <Row key={index} gutter={[60, 16]}>
                                {group.map(searchItem => (
                                    <Col key={searchItem.key as string} span={isMobile ? 24 : 6}>
                                        {searchItem}
                                    </Col>
                                ))}
                            </Row>
                        ))
*/
          }
        </Form>
      </Box>

      {!hideSearchButton && (
        <Button icon={<SearchOutlined/>} block type="primary" style={{width: 80, alignSelf: "flex-end"}}
          onClick={onSearch}>
                    조회
        </Button>
      )}

      <Divider/>
    </Stack>
  )
}
