import _ from "lodash"
import { MediaDto } from "../api/graphql/media"

export type Group = { id: string, name: string, playlist: MediaDto[], creatable: boolean };

export class GroupManager {
    private readonly groups: Group[]
    private currentPointer = ""

    constructor(groups: Group[], currentGroupId = "") {
      this.groups = groups
      this.currentPointer = currentGroupId
    }

    get selectedGroup(): Group | undefined {
      return this.groups.find(group => group.id === this.currentPointer)
    }

    get allGroups(): Group[] {
      return this.groups
    }

    get creatableGroups(): Group[] {
      return this.groups.filter(group => group.creatable)
    }

    changeTab(groupId: string): GroupManager {
      return new GroupManager(this.groups, groupId)
    }

    select(groupId: string): GroupManager {
      return new GroupManager(this.groups, groupId)
    }

    setMedias(medias: MediaDto[]): GroupManager {
      const newGroupManager = _.cloneDeep(this)

      if (newGroupManager.selectedGroup) {
        newGroupManager.selectedGroup.playlist = [...medias]
        return newGroupManager
      }

      return this
    }

    addMedia(media: MediaDto[]): GroupManager {
      const newGroupManager = _.cloneDeep(this)

      if (newGroupManager.selectedGroup) {
        newGroupManager.selectedGroup.playlist = _.uniqBy(newGroupManager.selectedGroup.playlist.concat(media), media => media.id)
        return newGroupManager
      }

      return this
    }

    removeMedia(mediaId: string): GroupManager {
      const newGroupManager = _.cloneDeep(this)

      if (newGroupManager.selectedGroup) {
        _.remove(newGroupManager.selectedGroup.playlist, media => media.id === mediaId)
        return newGroupManager
      }

      return this
    }

    getMediaIds(): string[] {
      return this.selectedGroup?.playlist?.map(m => m.id) ?? []
    }

    getMedias(): MediaDto[] {
      return this.selectedGroup?.playlist ?? []
    }

    reorder(source: number, destination: number): GroupManager {
      const newGroupManager = _.cloneDeep(this)

      if (newGroupManager.selectedGroup) {
        // this.swap(newGroupManager.selectedGroup.playlist, source, destination)
        this.move(newGroupManager.selectedGroup.playlist, source, destination)

        return newGroupManager
      }

      return this
    }

    setCreatable(groupId: string, creatable: boolean): GroupManager {
      const newGroupManager = _.cloneDeep(this)
      const groupIndex = newGroupManager.groups.findIndex(group => group.id === groupId)

      if (groupIndex !== -1) {
        if (creatable) {
          newGroupManager.currentPointer = groupId
        } else if (newGroupManager.currentPointer === groupId) {
          const firstCreatable = newGroupManager.groups.find((g => 
            g.creatable && g.id !== groupId
          ))
          if (firstCreatable) {
            newGroupManager.currentPointer = firstCreatable.id
          } else {
            newGroupManager.currentPointer = ''
          }
        }

        newGroupManager.groups[groupIndex].creatable = creatable
        return newGroupManager
      }

      return this
    }

    private swap(arr: any[], source: number, destination: number): void {
      const tmp = arr[destination]
      arr[destination] = arr[source]
      arr[source] = tmp
    }

    private move(arr: any[], source: number, destination: number): void {
      const tmp = arr[source]
      arr.splice(source, 1)
      arr.splice(destination, 0, tmp)
    }
}
