import React, { useState } from 'react'
import {Route, Router, Switch} from 'react-router-dom'
import {RouteLogin} from '../routes'
import AuthRoute from './auth-route'
import {history} from './history'
import _ from "lodash"
import {MainLayout} from "../layout"
import {useDispatch, useSelector} from "react-redux"
import {RootState} from "./reducer"
import {AuthLoginStatus, logout} from "../features/auth"
import {ApolloClient, ApolloProvider, createHttpLink, InMemoryCache, split} from "@apollo/client"
import {setContext} from "@apollo/client/link/context"
import {SubscriptionClient} from "subscriptions-transport-ws"
import {WebSocketLink} from "@apollo/client/link/ws"
import {onError} from "@apollo/client/link/error"
import {notification, Result, Button } from "antd"
import {RetryLink} from "@apollo/client/link/retry"
import {getMainDefinition} from "@apollo/client/utilities"
import {urlJoin} from '../utils/url'

import "styles/common"

function App() {
  const authState = useSelector((store: RootState) => store.authReducer)
  const authenticated = !_.isEmpty(authState) && authState.status === AuthLoginStatus.LOGGED_IN
  const dispatch = useDispatch()
  
  const [wsState, setWsState] = useState<'init'|'connecting'|'connected'|'disconnected'>('init')

  const httpLink = createHttpLink({
    uri: urlJoin(process.env.REACT_APP_API_URL, process.env.REACT_APP_GRAPHQL_PATH)
  })

  const authLink = setContext((_, {headers}) => {
    const token = authState.token
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
      }
    }
  })

  const wsClient = new SubscriptionClient(urlJoin(process.env.REACT_APP_WS_URL, process.env.REACT_APP_GRAPHQL_PATH), {
    reconnect: true,
    reconnectionAttempts: 1,
  })

  wsClient.on('connected', () => {
    // console.log('ws-client connected...')

    setWsState('connected')
  })
  wsClient.on('reconnected', () => {
    // console.log('ws-client re-connected...')
  })
  wsClient.on('connecting', () => {
    // console.log('ws-client connecting...')

    //setWsState('connecting')
  })
  wsClient.on('reconnecting', () => {
    // console.log('ws-client re-connecting...')
  })
  wsClient.on('disconnected', () => {
    //console.log('ws-client disconnected...')
    //console.log(wsClient.status)

    setWsState('disconnected')
  })

  const wsLink = new WebSocketLink(wsClient)

  const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(
        ({extensions, message, locations, path}) => {
          if (process.env.NODE_ENV !== 'production') {
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${extensions?.code}`
            )
          }

          if (message.match(/Access denied/)) {
            dispatch(logout())
            history.replace('/login')
          }
        }
      )
    }

    if (networkError) {
      if (networkError.message === 'Failed to fetch') {
        let initialSeconds = 10
        const interval = setInterval(() => {
          notification.error({
            message: '서버와의 연결이 끊어졌습니다.',
            description: `${initialSeconds}초 후에 재연결 시도합니다.`,
            key: networkError.name,
            duration: 1000 * initialSeconds,
          })

          initialSeconds--
        }, 1000)

        setTimeout(() => {
          clearInterval(interval)
          notification.close(networkError.name)
        }, 1000 * initialSeconds)
      }
    }
  })

  const retryLink = new RetryLink({
    delay: {
      initial: 10000,
      max: 10000,
      jitter: false
    },
    attempts: (count, operation, error) => {
      if (error?.message !== 'Failed to fetch') {
        // If error is not related to connection, do not retry
        return false
      }
      operation.setContext(context => ({...context, retryCount: count}))
      return (count <= 5)
    }
  })

  const links = split(
    ({query}) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
      )
    },
    wsLink,
    retryLink.concat(errorLink).concat(authLink).concat(httpLink),
  )

  const client = new ApolloClient({
    link: links,
    cache: new InMemoryCache({typePolicies: {RtiMcGroup: {keyFields: false}}})
  })

  return (
    <ApolloProvider client={client}>
      <Router history={history}>
        <Switch>
          <Route path="/login" component={RouteLogin}/>
          <MainLayout>
            {
              wsState === 'disconnected' ?
                <Result
                  status="error"
                  title="미디어허브 서버에 연결할 수 없음."
                  subTitle="미디어허브 서버와의 연결이 끊어졌습니다. 화면을 [새로고침] 해주세요. "
                  extra={[
                    <Button type="primary" key="console" onClick={() => location.reload()}>
                      새로고침
                    </Button>,
                  ]}
                />
                :
                <AuthRoute authenticated={authenticated}/>
            }
          </MainLayout>
        </Switch>
      </Router>
    </ApolloProvider>
  )
}

export default App
