import React, { FC, useState, useRef } from 'react'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { Drawer, Form, Input, Select, notification } from 'antd'
import { ModalMode } from "../../types"
import DrawerFooter from "../../components/common/drawer-footer"
import _ from 'lodash'
import DrawerErrorAlert from "../../components/common/drawer-error-alert"
import { FormInstance } from "antd/lib/form"
import { ChannelFormValues } from "./list"
import { ADD_CHANNEL, REMOVE_CHANNEL, UPDATE_CHANNEL } from "api/graphql/channel/mutations"
import { GET_CHANNELS, ChannelDto } from "../../api/graphql/channel"
import { GET_DIVISIONS, Divisions } from "../../api/graphql/division"
import { afterRemoved } from "../../utils"
import { getResponsiveDrawerWidth } from 'common/layouts'
import TextArea from "antd/lib/input/TextArea"
import useRtiMcs from "../../hooks/useRtiMcs"

interface Props {
  channels: ChannelDto[]
  form: FormInstance
  visible: boolean
  modalMode: ModalMode
  onClose: () => void
}

const ChannelEditDrawer: FC<Props> = ({ form, channels, visible, onClose, modalMode }) => {
  const [channelError, setMutationError] = useState<ApolloError>()
  // const { data: bundle } = useQuery(GET_CHANNELS_CELLS_AMCODES)

  const [addChannel] = useMutation(ADD_CHANNEL)
  const [updateChannel] = useMutation(UPDATE_CHANNEL)
  const [removeChannel] = useMutation(REMOVE_CHANNEL, {
    update: afterRemoved({query: GET_CHANNELS, queryResultKey: 'channels', mutationResultKey: 'removeChannel'})
  })

  const { rtiMcs } = useRtiMcs()
  const { data: dataDivisions } = useQuery<Divisions>(GET_DIVISIONS)

  const divisions = _.defaultTo(dataDivisions?.divisions, [])

  const selectedDivision = useRef<string>()

  const channelId = form.getFieldValue('id')

  function onChannelCreate(values: ChannelFormValues) {
    const variables = { ...values }

    const channelByName = channels.map(s => s.name).find(name => name === variables.name)
    if (channelByName) {
      setMutationError(new ApolloError({errorMessage: "이미 존재하는 판매 경로입니다."}))
      return
    }

    const channelInput = {
      variables: {
        ...variables
      }
    }

    addChannel(channelInput)
      .then(() => {
        notification.success({
          message: `판매 경로를 성공적으로 생성하였습니다.`
        })

        cleanFormData()
        onClose()
      })
      .catch((e) => {
        notification.error({
          message: `판매 경로를 추가하는 중에 에러가 발생하였습니다.`
        })

        setMutationError(e)
      })
  }

  const onChannelUpdate = () => {
    const variables = form.getFieldsValue()

    updateChannel({
      variables: {
        ...variables
      }
    })
      .then(() => {
        notification.success({
          message: `판매 경로를 성공적으로 수정하였습니다.`
        })

        cleanFormData()
        onClose()
      })
      .catch((e) => {
        notification.error({
          message: `판매 경로를 수정하는 중에 에러가 발생하였습니다.`
        })

        setMutationError(e)
      })
  }

  const onChannelRemove = () => {
    if (rtiMcs.find(rtiMcs => rtiMcs.mediacenter.channel.id === channelId)) {
      notification.error({
        message: `매장이 있는 판매 경로는 삭제할 수 없습니다.`
      })
      return
    }

    removeChannel({
      variables: {
        id: form.getFieldValue('id'),
      }
    })
      .then(() => {
        notification.success({
          message: `판매 경로를 성공적으로 삭제하였습니다.`
        })

        cleanFormData()
        onClose()
      })
      .catch((e) => {
        notification.error({
          message: `판매 경로를 삭제하는 중에 에러가 발생하였습니다.`
        })

        setMutationError(e)
      })
  }

  const cleanFormData = () => {
    form.resetFields()
    setMutationError(undefined)
  }

  const onDivisionChanged = (divisionId: string) => {
    if (selectedDivision.current !== divisionId) {

      selectedDivision.current = divisionId
    }
  }

  return (
    <Drawer
      title={`판매 경로 ${modalMode}`}
      width={getResponsiveDrawerWidth(400)}
      placement="right"
      closable
      onClose={onClose}
      visible={visible}
      footer={
        <DrawerFooter
          formId="formAddChannel"
          close={() => {
            onClose()
            setMutationError(undefined)
          }
          }
          isNew={modalMode === '추가'}
          onUpdate={onChannelUpdate}
          onRemove={onChannelRemove}
        />
      }
    >
      <Form
        id="formAddChannel"
        form={form}
        layout="vertical"
        labelAlign="right"
        onFinish={onChannelCreate}
        colon
      >
        <Form.Item name="id" hidden>
        </Form.Item>

        <Form.Item
          name="name"
          label="판매 경로 이름"
          rules={[{required: true, message: '판매 경로 이름을 입력해 주세요.'}]}
        >
          <Input placeholder="판매 경로 이름을 입력해 주세요."/>
        </Form.Item>

        <Form.Item
          name="divisionId"
          label="매장 분류"
          rules={[{required: true, message: '매장 분류를 선택해 주세요.'}]}
          validateTrigger="onSubmit"
        >
          <Select
            placeholder="매장 분류를 선택해 주세요."
            dropdownMatchSelectWidth={false}
            onChange={onDivisionChanged}
          >
            {
              divisions.map(
                division =>
                  <Select.Option
                    key={division.id}
                    value={division.id}
                  >
                    {division.name}
                  </Select.Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          name="desc"
          label="판매 경로 설명"
        >
          <TextArea maxLength={255} autoSize={{ minRows: 3, maxRows: 3 }} 
            placeholder="판매 경로 설명을 입력해 주세요."
          />
        </Form.Item>
      </Form>

      <DrawerErrorAlert error={channelError}/>
    </Drawer>
  )
}


export { ChannelEditDrawer }
