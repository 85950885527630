import fp from "lodash/fp"
import {isAfter, isBefore} from "date-fns"

interface Args {
    fieldValue: any;
    type: 'text' | 'text_strict' | 'date' | 'dropbox';
    path: string;
}

export function filterForTable<FilterElement extends Record<string, any>>({fieldValue, path, type}: Args) {
  function pred(d: FilterElement): boolean {
    if (!fp.isNumber(fieldValue) && fp.isEmpty(fieldValue)) {
      return true
    }

    const obj = fp.flow(fp.at(path), fp.head)(d)

    switch (type) {
    case "text":
      return obj.includes(fieldValue)
    case "dropbox":
    case "text_strict":
      return obj === fieldValue
    case "date": {
      const createdAt = new Date(obj)
      const searchFieldCreatedAt = fieldValue

      return fp.isEmpty(searchFieldCreatedAt) ? true :
        isAfter(createdAt, new Date(searchFieldCreatedAt[0]))
                    && isBefore(createdAt, new Date(searchFieldCreatedAt[1]))
    }
    default:
      return true
    }
  }

  return fp.filter(pred)
}
