import React, {FC} from 'react'
import {
  Divider,
} from 'antd'
import Popover from "antd/lib/popover"
import {Box, Stack} from "@chakra-ui/layout"
import Typography from "antd/lib/typography"

interface Props {
    totalCount: number;
    onlineCount: number;
    offlineCount: number;
    errorCount: number;
    disabled: boolean
}

const ConnRate: FC<Props> = ({totalCount, onlineCount, errorCount, offlineCount, disabled}) => {
  const rate = totalCount ? (onlineCount * 100) / totalCount : 0
  const rateStr = totalCount ? Math.floor(rate) + '%' : '0%'

  let backgroundColor, textColor

  if (disabled) {
    backgroundColor = '#d0d0d0'
    textColor = '#a0a0a0'
  } else {
    if (rate >= 80) {
      backgroundColor = '#00b050'
      textColor = '#ffffff'
    } else if (rate >= 60) {
      backgroundColor = '#b5e61d'
      textColor = '#000000'
    } else if (rate >= 40) {
      backgroundColor = '#fff200'
      textColor = '#000000'
    } else if (rate >= 20) {
      backgroundColor = '#ffc90e'
      textColor = '#000000'
    } else if (rate > 0) {
      backgroundColor = '#ff0000'
      textColor = '#ffffff'
    } else {
      backgroundColor = '#d0d0d0'
      textColor = '#000000'
    }
  }

  return (
    <Popover
      content={
        (
          <Stack>
            <Box display="flex" alignItems="center">
              <Box mr={8} width={8} height={8} background='#0000ff'/>
              <Typography.Text>접속 {onlineCount}</Typography.Text>
            </Box>
            <Box display="flex" alignItems="center">
              <Box mr={8} width={8} height={8} background='#404040'/>
              <Typography.Text>미접속 {offlineCount}</Typography.Text>
            </Box>
            <Box display="flex" alignItems="center">
              <Box mr={8} width={8} height={8} background='#ff0000'/>
              <Typography.Text>검토 {errorCount}</Typography.Text>
            </Box>
          </Stack>
        )
      }
    >
      <div style={{width: '100%', height: '100%', backgroundColor: '#A0A0A0'}}>
        <div style={{
          position: 'relative',
          width: '100%',
          height: 16,
          backgroundColor: '#ffffff',
          border: '1px solid #a0a0a0',
          /* textAlign: "center",
            margin: 0,
            padding: 0,*/
        }}>
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: 14,
            width: rateStr,
            backgroundColor: backgroundColor
          }}>
          </div>
          <div style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            backgroundColor: 'rgba(255, 255, 255, 0)',
            fontSize: '8px',
            color: textColor,
          }}>
            {rateStr}
          </div>
        </div>
        <div style={{
          position: 'relative',
          width: '100%',
          height: 16,
          backgroundColor: '#ffffff',
          fontSize: '8px',
        }}>
          <span style={{color: disabled ? '#a0a0a0' : '#0000ff'}}>{onlineCount}</span>
          <Divider type="vertical" style={{margin: '0px 6px 0px 6px', padding: '0px'}}/>
          <span style={{color: disabled ? '#a0a0a0' : '#404040'}}>{offlineCount}</span>
          <Divider type="vertical" style={{margin: '6px', padding: '0px'}}/>
          <span style={{color: disabled ? '#a0a0a0' : '#ff0000'}}>{errorCount}</span>
        </div>
      </div>
    </Popover>
  )
}

export {ConnRate}
