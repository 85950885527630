import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {GroupDto} from "../../api/graphql/group"

const initialState: GroupDto[] = []

const slice = createSlice({
  name: 'group',
  initialState,
  reducers: {
    storeGroups(state, action: PayloadAction<GroupDto[]>) {
      state = action.payload
      return state
    },
  }
})

export const { storeGroups } = slice.actions
export default slice.reducer
