import { gql } from '@apollo/client'

export const groupFragment = gql`
  fragment groupParts on Group {
    id
    name
    color
    regex
    desc
    createdAt
    updatedAt
  }
`

export const GET_GROUPS = gql`
  query GetGroups {
    groups {
        ...groupParts
    }
  }
  ${groupFragment}
`

export const SUBSCRIBE_GROUP = gql`
  subscription {
    subscribeGroup {
      op
      group {
        ...groupParts
      }
    }
  }
  ${groupFragment}
`
