import {gql} from "@apollo/client"
import {groupFragment} from "./queries"

export const ADD_GROUP = gql`
    mutation AddGroup(
        $name: String!
        $regex: String!
    ) {
        addGroup(name: $name, regex: $regex) {
            ...groupParts
        }
    }
    ${groupFragment}
`

export const UPDATE_GROUP = gql`
    mutation UpdateGroup(
        $id: ID!
        $name: String!
        $regex: String!
    ) {
        updateGroup(id: $id, name: $name, regex: $regex) {
            ...groupParts
        }
    }
    ${groupFragment}
`

export const REMOVE_GROUP = gql`
    mutation RemoveGroup(
        $id: ID!
    ) {
        removeGroup(id: $id)
    }
`

