import {gql} from "@apollo/client"
import {accountFragment} from "./queries"

export const ADD_ACCOUNT = gql`
    mutation AddAccount(
        $userid: String!
        $password: String!
        $level: Int!
        $role: Int!
        $name: String!
        $phoneno: String
        $email: String
        $desc: String
        $channelId: String
        $shopId: String
    ) {
        addAccount(
            userid: $userid
            password: $password
            level: $level
            role: $role
            name: $name
            phoneno: $phoneno
            email: $email
            desc: $desc
            channelId: $channelId
            shopId: $shopId
        ) {
            ...accountParts
        }
    }
    ${accountFragment}
`

export const UPDATE_ACCOUNT = gql`
    mutation UpdateAccount(
        $id: String!
        $userid: String!
        $level: Int!
        $role: Int!
        $name: String!
        $password: String
        $phoneno: String
        $email: String
        $desc: String
        $channelId: String
        $shopId: String
    ) {
        updateAccount(
            id: $id
            userid: $userid
            password: $password
            level: $level
            role: $role
            name: $name
            phoneno: $phoneno
            email: $email
            desc: $desc
            channelId: $channelId
            shopId: $shopId
        ) {
            ...accountParts
        }
    }
    ${accountFragment}
`
export const REMOVE_ACCOUNT = gql`
    mutation RemoveAccount(
        $id: String!
    ) {
        removeAccount(
            id: $id
        )
    }
`
