import React, { FC, useState } from 'react'
import { VStack, Center, Box } from '@chakra-ui/layout'
import { List, Image, Button, Space, Typography, Row, Col } from 'antd'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'
import { GroupManager } from "../../models"
import { urlJoin } from '../../utils/url'
import { fallback } from '../../common/images/fallback'
import { toMegabytes, toMmSs } from '../../utils'
import { PictureOutlined, DeleteOutlined } from '@ant-design/icons'
import { PairingEditGroupFileModal } from "./edit-group-filemodal"

const { Text } = Typography

interface Props {
  editable: boolean
  groupManager: GroupManager
  onGroupManagerUpdated?: (newGroupManager: GroupManager) => void
}

const PairingEditGroupMediaList: FC<Props> = ({
  editable,
  groupManager,
  onGroupManagerUpdated
}) => {
  const [modalVisible, setModalVisible] = useState(false)

  const selectedGroup = groupManager.selectedGroup
  const playlist = groupManager.selectedGroup?.playlist ?? []

  const onMediaDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    if (result.source.index === result.destination.index) {
      return
    }

    const reordered = groupManager.reorder(result.source.index, result.destination.index)
    onGroupManagerUpdated && onGroupManagerUpdated(reordered)
  }

  function onDeleteMedia(mediaId) {
    const newGroupManager = groupManager.removeMedia(mediaId)
    onGroupManagerUpdated && onGroupManagerUpdated(newGroupManager)
  }

  return (
    <VStack w="100%" h="100%" p={8} bg="#f0f0f0">
      <Row style={{
        width: "100%", height: 32
      }}>
        <Col span={5}></Col>
        <Col span={14}>
          <Center h={32} bg="#fffbe6" borderRadius={4}
            boxShadow="0 2px 4px rgba(0, 0, 0, 0.15)"
          >
            {
              selectedGroup && 
                <>
                  <Text strong style={{ fontSize: 24 }}>
                    {selectedGroup.name}
                  </Text>
                  &nbsp;&nbsp;
                  <Text style={{ fontSize: 12, fontWeight: 400 }}>
                    ({selectedGroup?.playlist?.length})
                  </Text>
                </>
            }
          </Center>
        </Col>
        <Col span={3}>
        </Col>
        <Col span={2}>
          <Center w="100%" h="100%">
            <Button 
              type="primary"
              size="small"
              icon={<PictureOutlined />}
              onClick={() => setModalVisible(true)}
              disabled={!editable || groupManager.creatableGroups.length === 0}
              title="미디어 선택"
            />
          </Center>
        </Col>
      </Row>
      <Box w="100%" h="100%" bg="white" p={8}>
        {
          !selectedGroup ?
            <div></div>
            :
            <DragDropContext onDragEnd={onMediaDragEnd}>
              <Droppable droppableId={selectedGroup.id}>
                { provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}
                    style={{ width: "100%", height: "100%"}}>
                    {
                      playlist.map((item, i) =>
                        <Draggable key={item.id} draggableId={item.id} index={i}
                          isDragDisabled={!editable}
                        >
                          {
                            providedItem => (
                              <div
                                ref={providedItem.innerRef}
                                {...providedItem.draggableProps}
                                {...providedItem.dragHandleProps}
                              >
                                <List.Item style={{ margin: 0, padding: 0, paddingTop: 4, borderTop: "1px dotted #e0e0e0" }}>
                                  <List.Item.Meta
                                    avatar={
                                      <Space style={{ verticalAlign: "middle" }}>
                                        <Image
                                          src={urlJoin(process.env.REACT_APP_THUMBNAIL_URL, item.thumbnail) +
                                            `?${new Date(item.updatedAt).getTime()}`}
                                          width={64}
                                          height={36}
                                          preview={false}
                                          fallback={fallback}
                                          style={{ margin: 0, padding: 0, border: "1px solid #c0c0c0" }}
                                        />
                                        <div style={{
                                          width: 22, marginBottom: 4, textAlign: "center",
                                          border: "1px solid #e0e0e0", backgroundColor: "#f0f0f0",
                                          fontSize: 12, fontWeight: 600, borderRadius: 4
                                        }}>
                                          {i+1}
                                        </div>
                                      </Space>
                                    }
                                    title={
                                      <span style={{
                                        fontSize: 12, display: "block", margin: 0, fontWeight: 500
                                      }}>{item.filename}</span>
                                    }
                                    description={
                                      <div style={{
                                        height: 16, margin: 0, padding: 0
                                      }}>
                                        <Row style={{ fontSize: 11 }}>
                                          <Col span={2}></Col>
                                          <Col span={4}>{toMegabytes(item.size)} MB</Col>
                                          <Col span={4}>{item.videoCodec}</Col>
                                          <Col span={4}>{toMmSs(item.duration)}</Col>
                                          <Col span={6}>{toMegabytes(item.bitrate)}mb/s</Col>
                                          <Col span={4}></Col>
                                        </Row>
                                      </div>
                                    }
                                    style={{ margin: 0, padding: 0 }}
                                  />

                                  {
                                    editable && (
                                      <Button
                                        type="link"
                                        size="small"
                                        onClick={() => onDeleteMedia(item.id)}
                                        icon={<DeleteOutlined />}
                                      />
                                    )
                                  }
                                </List.Item>
                              </div>
                            )
                          }
                        </Draggable>
                      )
                    }
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
        }
      </Box>
      { modalVisible && (
        <PairingEditGroupFileModal groupManager={groupManager} onGroupManagerUpdated={onGroupManagerUpdated}
          onClose={() => setModalVisible(false)}/>
      )}
    </VStack>
  )
}


export { PairingEditGroupMediaList }