import {gql} from "@apollo/client"
import {regionFragment} from "./queries"

export const ADD_REGION = gql`
    mutation AddRegion(
        $name: String!
    ) {
        addRegion (
            name: $name
        ) {
           ...regionParts 
        }
    }
    ${regionFragment}
`

export const UPDATE_REGION = gql`
    mutation UpdateRegion(
        $id: ID!
        $name: String!
    ) {
        updateRegion (
            id: $id
            name: $name
        ) {
           ...regionParts 
        }
    }
    ${regionFragment}
`

export const REMOVE_REGION = gql`
    mutation RemoveRegion(
        $id: ID!
    ) {
        removeRegion (
            id: $id
        )
    }
`
