import React, { FC } from 'react'
import { Divider, Image } from 'antd'
import { Stack } from "@chakra-ui/layout"
import Typography from "antd/lib/typography"
import Table from "antd/lib/table"
import { MediaFile } from "./upload-executor"
import { toMegabytes } from "../../utils"
import { fallback } from '../../common/images/fallback'

interface Props {
  fileList: MediaFile[]
  setFileList: React.Dispatch<React.SetStateAction<MediaFile[]>>
}

const MediaUploadList: FC<Props> = ({ fileList }) => {
  const uploadedFiles = fileList.filter(f => f.status === 'success')
  const columns = [
    {
      title: '썸네일',
      dataIndex: 'thumnailUrl',
      key: 'thumnailUrl',
      width: 140,
      height: 90,
      render: (url) =>
        <Image
          width={128}
          height={72}
          preview={false}
          src={url + `?${new Date().getTime()}`} /* use "URL?xxxxx" to prevent loading cache image */
          fallback={fallback}
          style={{ border: "1px solid #a0a0a0" }}
        />
    },
    {
      title: '파일 이름',
      dataIndex: 'name',
      key: 'name',
      width: 300,
      ellipsis: true,
    },
    {
      title: '파일 크기',
      dataIndex: 'size',
      key: 'size',
      render: (size) => `${toMegabytes(size)}MB`
    },
  ]

  return (
    <Stack w="100%" mb={6}>
      <Divider type="horizontal" style={{width: '100%', marginTop: 4}}/>

      <Typography.Title level={5}>
        업로드된 파일 리스트
      </Typography.Title>

      <Table columns={columns} dataSource={uploadedFiles} pagination={false}/>
    </Stack>
  )
}

export { MediaUploadList }
