import { gql } from '@apollo/client'

export const mediaFragment = gql`
    fragment mediaParts on Media {
      id
      name
      filename
      path
      thumbnail
      licenseEnd
      size
      duration
      width
      height
      bitrate
      videoCodec
      audioCodec
      hash
      desc
      createdAt
      updatedAt
    }
`

export const GET_MEDIAS = gql`
  query GetMedias {
    medias {
        ...mediaParts
    }
  }
  ${mediaFragment}
`

export const SUBSCRIBE_MEDIA = gql`
  subscription {
    subscribeMedia {
      op
      media {
        id
        name
        filename
        path
        thumbnail
        licenseEnd
        size
        duration
        width
        height
        bitrate
        videoCodec
        audioCodec
        hash
        desc
        createdAt
        updatedAt
      }
    }
  }
`
