import {gql} from '@apollo/client'

export const accountFragment = gql`
    fragment accountParts on Account {
        id
        userid
        name
        level
        role
        phoneno
        email
        desc
        channel {
            id
            name
        }
        shop {
            id
            name
        }
        createdAt
        updatedAt
    }
`

export const GET_ACCOUNTS = gql`
    query GetAccounts {
        accounts {
            ...accountParts
            shop {
                id
                name
            }
        }
    }
    ${accountFragment}
`

export const SUBSCRIBE_ACCOUNT = gql`
    subscription {
        subscribeAccount {
            op
            account {
                ...accountParts
            }
        }
    }
    ${accountFragment}
`
