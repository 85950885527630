import React, {FC, useMemo, useState,} from 'react'
import {ApolloError, useMutation} from '@apollo/client'
import { Drawer, Form, Input, notification, Table } from 'antd'
import {ColumnsType} from 'antd/es/table'
import TextArea from "antd/lib/input/TextArea"
import _ from 'lodash'
import DrawerFooter from "components/common/drawer-footer"
import DrawerErrorAlert from "components/common/drawer-error-alert"
import useModalState from "../../hooks/useModalState"
import { FirmwareDto, ADD_FIRMWARE, UPDATE_FIRMWARE, REMOVE_FIRMWARE } from "../../api/graphql/firmware"
import usePageSize from "../../hooks/usePageSize"
import { sortByText } from "../../utils"
import { getResponsiveDrawerWidth } from 'common/layouts'
import { createdAtColumn } from "../../common/created-at-column"
import { sortByNumber } from '../../utils'

interface Props {
  firmwares: FirmwareDto[]
}

interface FormValues {
  filename: string
  version: string
  hash: string
}

const FirmwareList: FC<Props> = ({firmwares}) => {
  const [form] = Form.useForm<FormValues>()

  const {modalMode, closeModal, /*openAddModal,*/ openEditModal} = useModalState()
  const [firmwareError, setMutationError] = useState<ApolloError>()

  const { pageSize, setPageSize } = usePageSize('firmware')

  const columns = useMemo<ColumnsType<FirmwareDto>>(() => [
    {
      title: '파일 이름',
      dataIndex: 'filename',
      key: 'filename',
      width: 200,
      fixed: 'left',
      render: (text, record) => {
        const firmware = firmwares.find(firmware => firmware.id === record.id)
        const onClick = () => {
          if (firmware) {
            form.setFieldsValue(firmware)
            // form.setFieldsValue({ shopNames: smd.shops.map(s => s.name) })
            openEditModal()
          }
        }

        return (
          <a onClick={onClick}>
            {firmware?.filename}
          </a>
        )
      },
      sorter: (firmware1, firmware2) => sortByText(firmware1.filename, firmware2.filename),
      showSorterTooltip: false,

    },
    {
      title: '버전',
      dataIndex: 'version',
      key: 'version',
      width: 100,
      sorter: (firmware1, firmware2) => sortByText(firmware1.version, firmware2.version),
      showSorterTooltip: false,
    },
    {
      title: '크기',
      dataIndex: 'size',
      key: 'size',
      width: 120,
      showSorterTooltip: false,
    },
    createdAtColumn('createdAt', (e1, e2) => sortByNumber(e1.createdAt, e2.createdAt)),
  ], [firmwares])

  const [addFirmware] = useMutation(ADD_FIRMWARE)
  const [updateFirmware] = useMutation(UPDATE_FIRMWARE)
  const [removeFirmware] = useMutation(REMOVE_FIRMWARE)

  const onFirmwareCreate = (variables: FormValues) => {
    const found = firmwares.map(s => s.filename).find(filename => filename === variables.filename)
    if (found) {
      setMutationError(new ApolloError({errorMessage: "이미 존재하는 펌웨어 파일입니다."}))
      return
    }

    addFirmware({
      variables: {
        firmwareInput: {
          ...variables
        }
      }
    })
      .then(() => {
        notification.success({
          message: `성공적으로 펌웨어를 추가했습니다.`
        })

        cleanFormData()
      }).catch((e) => {
        notification.error({
          message: `펌웨어 추가 중에 에러가 발생했습니다.`
        })

        setMutationError(e)
      })
  }

  const onFirmwareUpdate = () => {
    updateFirmware({
      variables: {
        firmwareInput: {
          ...form.getFieldsValue()
        }
      }
    }).then(() => {
      notification.success({
        message: `펌웨어 정보를 성공적으로 업데이트했습니다.`
      })

      cleanFormData()
    })
      .catch((e) => {
        notification.error({
          message: `펌웨어 정보를 업데이트하는 중에 에러가 발생했습니다.`
        })

        setMutationError(e)
      })
  }

  const onFirmwareRemove = () => {
    removeFirmware({
      variables: {
        id: form.getFieldValue('id'),
      }
    }).then(() => {
      notification.success({
        message: `펌웨어를 성공적으로 제거했습니다.`
      })

      cleanFormData()
    })
      .catch((e) => {
        notification.error({
          message: `펌웨어를 제거하는 중에 에러가 발생했습니다.`
        })

        setMutationError(e)
      })
  }

  const cleanFormData = () => {
    closeModal()
    form.resetFields()
    setMutationError(undefined)
  }

  return (
    <>
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={firmwares}
        scroll={{ x: 1000/*true*/ }}
        onChange={(pagination) => setPageSize(pagination.pageSize)}
        pagination={{ showSizeChanger: true, pageSize }}
      />
      <Drawer
        title={`펌웨어 ${modalMode}`}
        width={getResponsiveDrawerWidth(400)}
        placement="right"
        closable
        onClose={cleanFormData}
        visible={!_.isEmpty(modalMode)}
        footer={
          <DrawerFooter
            formId="formAddFirmware"
            close={cleanFormData}
            isNew={!form.getFieldValue('id')}
            onUpdate={onFirmwareUpdate}
            onRemove={onFirmwareRemove}
          />
        }
      >
        <Form
          id="formAddFirmware"
          form={form}
          layout="vertical"
          labelAlign="right"
          colon
          onFinish={onFirmwareCreate}
        >
          <Form.Item name="id" hidden>
          </Form.Item>

          <Form.Item name="filename" label="파일 이름"
            rules={[{required: true, message: '\'이름\'을 입력해 주세요.'}]}
          >
            <Input readOnly />
          </Form.Item>
          <Form.Item name="version" label="버전">
            <Input readOnly />
          </Form.Item>
          <Form.Item name="size" label="파일 크기">
            <Input readOnly />
          </Form.Item>
          <Form.Item name="hash" label="파일 해시">
            <Input readOnly />
          </Form.Item>
          <Form.Item name="desc" label="펌웨어 설명" initialValue="">
            <TextArea maxLength={255} autoSize={{ minRows: 5, maxRows: 5 }} />
          </Form.Item>

        </Form>

        <DrawerErrorAlert error={firmwareError}/>
      </Drawer>
    </>
  )
}

export {FirmwareList}
