import {gql} from "@apollo/client"
import {shopFragment} from "./queries"

export const ADD_SHOP = gql`
    mutation AddShop(
        $shopInput: ShopInput!
    ) {
        addShop(
            shopInput: $shopInput
        ) {
            id
        }
    }
`

export const UPDATE_SHOP = gql`
    mutation UpdateShop(
        $id: String!,
        $name: String!,
        $channelId: String!,
        $regionId: String!,
        $cellId: String!,
        $amcodeId: String,
        $smdId: String,
        $code: String,
        $address: String,
        $latitude: Float,
        $longitude: Float,
        $desc: String,
        $mac: String,
    ) {
        updateShop(
            id: $id
            name: $name
            channelId: $channelId
            amcodeId: $amcodeId
            regionId: $regionId
            cellId: $cellId
            smdId: $smdId
            code: $code
            address: $address
            latitude: $latitude
            longitude: $longitude
            desc: $desc
            mac: $mac
        ) {
            ...shopParts
        }
    }
    ${shopFragment}
`

export const REMOVE_SHOP = gql`
    mutation RemoveShop(
        $id: String!
    ) {
        removeShop(
            id: $id
        )
    }

`
