import React, { FC, useState, } from 'react'
import Axios from 'axios'
import { Button, notification, Upload, } from 'antd'
import { SmileOutlined, SyncOutlined, UploadOutlined, YoutubeOutlined } from '@ant-design/icons'
import { MediaUploadExecutorFilelist } from './upload-executor-filelist'
import { UploadChangeParam, UploadFile } from "antd/lib/upload/interface"
import _ from 'lodash'
import _pullAt from 'lodash/fp/pullAt'
import { urlJoin } from '../../utils/url'
import useMedia from "../../hooks/useMedia"

export interface MediaFile extends UploadFile {
  licenseEnd?: Date
  thumnailUrl?: string
  uploadErrorMessage?: string
  isNew?: boolean
}

export type UploadStatus = 'done' | 'ready' | 'uploading'

const { Dragger } = Upload

interface Props {
  fileList: MediaFile[]
  setFileList: React.Dispatch<React.SetStateAction<MediaFile[]>>
}

const MediaUploadExecutor: FC<Props> = ({ fileList, setFileList }) => {
  const { mediaDtos: medias } = useMedia()

  const [uploadStatus, setUploadStatus] = useState<UploadStatus>('ready')

  const totalCount = fileList.length
  const totalMegabytes = _.floor(fileList.reduce((acc, cur) => acc + cur.size, 0) / 1024 / 1024, 1)

  const handleUpload = async () => {
    if (fileList.length === 0) {
      return
    }

    setUploadStatus('uploading')

    const requests = fileList
      .map(file => {
        const formData = new FormData()
        formData.append('media', file.originFileObj!, file.fileName)

        return Axios
          .post(urlJoin(process.env.REACT_APP_API_URL, 'upload'), formData, {
            onUploadProgress: function (event) {
              const percent = Math.floor((event.loaded / event.total) * 100)
              notification.open({
                key: file.name,
                placement: "bottomLeft",
                duration: 0,
                message: `'${file.name.slice(0, 20)}...' 업로드 중`,
                description: `${percent}%`,
                icon: <SmileOutlined style={{color: '#108ee9'}}/>
              })
            }
          })
      })

    Promise.all(requests)
      .then(responses => {
        // const newFileList = _.cloneDeep(fileList);
        const newFileList = fileList

        responses.forEach(res => {
          const { resp, media } = res.data

          const file = newFileList.find(f => f.name === media.filename)!
          let fileName = ''
          if (resp === 'created' || resp === 'updated') {
            notification.success({
              message: resp === 'created' ? `'${media.filename}' 파일이 추가되었습니다.`
                : `'${media.filename}' 파일이 수정되었습니다.`
            })

            file.status = 'success'
            file.thumnailUrl = urlJoin(process.env.REACT_APP_THUMBNAIL_URL, media.thumbnail) +
              `?${new Date(media.updatedAt).getTime()}`
            file.licenseEnd = media.licenseEnd
            file.isNew = (resp === 'created') ? true : false
            fileName = file.name
          } else if (resp === 'failed') {
            notification.error({
              message: `'${media.filename}' 파일 업로드를 실패하였습니다.`,
            })
            
            file.status = 'error'
            fileName = file.name
          }

          notification.close(fileName)
        })

        setFileList(newFileList)
      })
      .catch((err) => {
        console.log(err)
        notification.error({
          message: `업로드 중 알 수 없는 문제가 발생했습니다.`
        })
      })
      .finally(() => {
        setUploadStatus('done')
      })
  }

  const handleItemDelete = (uid: string) => {
    if (uploadStatus === 'uploading') {
      notification.error({
        message: '업로드 중에는 취소할 수 없습니다.'
      })
      return
    }

    const i = fileList.findIndex(file => file.uid === uid)
    if (i !== -1) {
      const newFileList = _pullAt(i)(fileList)
      setFileList(newFileList)
    }
  }

  const onChange = ({ fileList }: UploadChangeParam) => {
    const uniq: MediaFile[] = _.uniqBy(fileList, 'name')

    uniq.forEach(f => {
      f.isNew = medias.findIndex(m => m.filename === f.name) === -1 ? true : false
    })
    setFileList(uniq)
  }

  return (
    <div style={{marginBottom: 10}}>
      <div style={
        {
          border: '1px solid #C0C0C0',
          marginBottom: 6,
          padding: 8
        }
      }>
        <div style={{ height: 140 }}>
          <Dragger
            name="media"
            fileList={fileList}
            multiple
            disabled={uploadStatus === 'uploading'}
            showUploadList={false}
            onChange={onChange}
            beforeUpload={() => {
              return false  // false means "do not upload as soon as files is added".
            }}
          >
            {
              uploadStatus === 'uploading' ?
                <div>
                  <p className="ant-upload-drag-icon"><SyncOutlined spin/></p>
                  <p className="ant-upload-text">파일을 업로드 하는 중입니다.</p>
                </div>
                :
                <div>
                  <p className="ant-upload-drag-icon"><YoutubeOutlined/></p>
                  <p className="ant-upload-text">여기를 클릭하거나 업로드할 파일을 끌어 놓으세요.</p>
                  <p className="ant-upload-hint">{totalCount}개 파일 선택 ({totalMegabytes}MB)</p>
                </div>
            }
          </Dragger>
        </div>
        <MediaUploadExecutorFilelist
          files={fileList}
          uploadStatus={uploadStatus}
          onItemDelete={handleItemDelete}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          type="primary"
          disabled={fileList.length === 0 || uploadStatus === 'uploading'}
          loading={uploadStatus === 'uploading'}
          icon={<UploadOutlined/>}
          onClick={handleUpload}
        >
          업로드
        </Button>
      </div>
    </div>
  )
}


export { MediaUploadExecutor }
