import {Stack} from "@chakra-ui/layout"
import React, {FC} from "react"
import Typography from "antd/lib/typography"
import {Divider} from "antd"
import {SmdList} from "./smd"
import useSmds from "../hooks/useSmds"

const PageSmd: FC = () => {
  const {smds} = useSmds()

  return (
    <Stack>
      <Typography.Title level={3}>
                SMD 관리
      </Typography.Title>

      <Divider/>

      <SmdList smds={smds}/>
    </Stack>
  )
}


export {PageSmd}
