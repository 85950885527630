import React, {FC, useMemo, useState,} from 'react'
import {ApolloError, useMutation} from '@apollo/client'
import { Button, Drawer, Form, Input, notification, Space, Table } from 'antd'
import {ColumnsType} from 'antd/es/table'
import {FileAddOutlined} from '@ant-design/icons'
import {Box} from "@chakra-ui/layout"
import TextArea from "antd/lib/input/TextArea"
import _ from 'lodash'
import DrawerFooter from "components/common/drawer-footer"
import DrawerErrorAlert from "components/common/drawer-error-alert"
import useModalState from "../../hooks/useModalState"
import {ADD_SMD, SmdDto, REMOVE_SMD, UPDATE_SMD} from "../../api/graphql/smd"
import usePageSize from "../../hooks/usePageSize"
import {sortByText} from "../../utils"
import { getResponsiveDrawerWidth } from 'common/layouts'

interface Props {
    smds: SmdDto[];
}

interface FormValues {
    name: string;
    phoneno: string;
    desc: string;
    shopNames: string[];
}

const SmdList: FC<Props> = ({smds}) => {
  const [form] = Form.useForm<FormValues>()

  const {modalMode, closeModal, openAddModal, openEditModal} = useModalState()
  const [smdError, setMutationError] = useState<ApolloError>()

  const { pageSize, setPageSize } = usePageSize('smd')

  const columns = useMemo<ColumnsType<SmdDto>>(() => [
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      width: 160,
      fixed: 'left',
      render: (name) => {
        const smd = smds.find(smd => smd.name === name)
        const onClick = () => {
          if (smd) {
            form.setFieldsValue(smd)
            form.setFieldsValue({ shopNames: smd.shops.map(s => s.name) })

            openEditModal()
          }
        }

        return (
          <a onClick={onClick}>
            {name}
          </a>
        )
      },
      sorter: (smd1, smd2) => sortByText(smd1.name, smd2.name),
      showSorterTooltip: false,

    },
    {
      title: '전화 번호',
      dataIndex: 'phoneno',
      key: 'phoneno',
    },
  ], [smds])

  const columnShops: ColumnsType<{ name: string }> = [
    {
      title: '관리 매장 리스트',
      dataIndex: 'name',
      key: 'name',
      /*width: 160,*/
      fixed: 'left',
      /*
            render: (name) => {
                return (
                    <a onClick={onClick}>
                        {name}
                    </a>
                )
            },
            sorter: (smd1, smd2) => sortByText(smd1.name, smd2.name),
            showSorterTooltip: false,
*/
    }
  ]

  const [addSmd] = useMutation(ADD_SMD)
  const [updateSmd] = useMutation(UPDATE_SMD)
  const [removeSmd] = useMutation(REMOVE_SMD)

  const onSmdCreate = (variables: FormValues) => {
    const found = smds.map(s => s.name).find(name => name === variables.name)
    if (found) {
      setMutationError(new ApolloError({errorMessage: "이미 존재하는 SMD 이름입니다."}))
      return
    }

    addSmd({
      variables: {
        smdInput: {
          ...variables
        }
      }
    })
      .then(() => {
        notification.success({
          message: `성공적으로 SMD를 추가했습니다.`
        })

        cleanFormData()
      }).catch((e) => {
        notification.error({
          message: `SMD 추가 중에 에러가 발생했습니다.`
        })

        setMutationError(e)
      })
  }

  const onSmdUpdate = () => {
    updateSmd({
      variables: {
        smdInput: {
          ...form.getFieldsValue()
        }
      }
    }).then(() => {
      notification.success({
        message: `SMD를 성공적으로 업데이트했습니다.`
      })

      cleanFormData()
    })
      .catch((e) => {
        notification.error({
          message: `SMD를 업데이트하는 중에 에러가 발생했습니다.`
        })

        setMutationError(e)
      })
  }

  const onSmdRemove = () => {
    removeSmd({
      variables: {
        id: form.getFieldValue('id'),
      }
    }).then(() => {
      notification.success({
        message: `SMD를 성공적으로 제거했습니다.`
      })

      cleanFormData()
    })
      .catch((e) => {
        notification.error({
          message: `SMD를 제거하는 중에 에러가 발생했습니다.`
        })

        setMutationError(e)
      })
  }

  const cleanFormData = () => {
    closeModal()
    form.resetFields()
    setMutationError(undefined)
  }

  return (
    <>
      <Box w="100%" textAlign="right" mb="10px">
        <Space direction="horizontal">
          <Button type="primary" icon={<FileAddOutlined/>}
            onClick={openAddModal}
          >
            추가
          </Button>
        </Space>
      </Box>
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={smds}
        scroll={{ x: 1000/*true*/ }}
        onChange={(pagination) => setPageSize(pagination.pageSize)}
        pagination={{ showSizeChanger: true, pageSize }}
      />
      <Drawer
        title={`SMD ${modalMode}`}
        width={getResponsiveDrawerWidth(400)}
        placement="right"
        closable
        onClose={cleanFormData}
        visible={!_.isEmpty(modalMode)}
        footer={
          <DrawerFooter
            formId="formAddSmd"
            close={cleanFormData}
            isNew={!form.getFieldValue('id')}
            onUpdate={onSmdUpdate}
            onRemove={onSmdRemove}
          />
        }
      >
        <Form
          id="formAddSmd"
          form={form}
          layout="vertical"
          labelAlign="right"
          colon
          onFinish={onSmdCreate}
        >
          <Form.Item name="id" hidden>
          </Form.Item>

          <Form.Item name="name" label="이름"
            rules={[{required: true, message: '\'이름\'을 입력해 주세요.'}]}
          >
            <Input/>
          </Form.Item>
          <Form.Item name="phoneno" label="전화번호">
            <Input maxLength={32}/>
          </Form.Item>
          <Form.Item name="desc" label="SMD 설명" initialValue="">
            <TextArea maxLength={255} autoSize={{ minRows: 5, maxRows: 5 }} />
          </Form.Item>

        </Form>

        <Table
          size="small"
          //rowKey="id"
          columns={columnShops}
          dataSource={form.getFieldValue('shopNames')?.map(v => ({ name: v}))}
          pagination={false}
          style={{ width: "90%" }}
        />

        <DrawerErrorAlert error={smdError}/>
      </Drawer>
    </>
  )
}

export {SmdList}
