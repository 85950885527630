import React, { FC, useEffect, useState, useRef } from 'react'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { Cascader, Drawer, Form, Input, notification, Select } from 'antd'
import { Channels, GET_CHANNELS, } from 'api/graphql/channel'
import { GET_SHOPS, ShopDto, Shops, } from 'api/graphql/shop'
import _ from 'lodash'
import { ModalMode } from "../../types"
import DrawerErrorAlert from "../../components/common/drawer-error-alert"
import DrawerFooter from "../../components/common/drawer-footer"
import { ADD_MEDIACENTER, REMOVE_MEDIACENTER, UPDATE_MEDIACENTER } from "api/graphql/mediacenter/mutations"
import { MediacenterDto } from "../../api/graphql/mediacenter"
import { FormInstance } from "antd/lib/form"
import { Cells, GET_CELLS } from "../../api/graphql/cell"
import { RtiMcDto } from "../../api/graphql/rti-mc"
import TextArea from "antd/lib/input/TextArea"
import { getResponsiveDrawerWidth } from 'common/layouts'

interface Props {
  rtiMcs: RtiMcDto[]
  form: FormInstance
  selected?: MediacenterDto
  visible: boolean
  modalMode: ModalMode
  onClose: () => void
}

export interface MediacenterFormValues {
  mac: string
  channelId: string
  regionAndCellId: string
  shopId: string
  id?: string
  mode?: string
  serial?: string
  desc?: string
}

const MediacenterDrawer: FC<Props> = ({ rtiMcs, form, selected, visible, onClose, modalMode }) => {
  const [shopList, setShops] = useState<ShopDto[]>([])

  const [mediacenterError, setMutationError] = useState<ApolloError>()

  const { data: dataChannels } = useQuery<Channels>(GET_CHANNELS)
  const { data: dataCells } = useQuery<Cells>(GET_CELLS)
  const { data: dataShops } = useQuery<Shops>(GET_SHOPS)

  const selectedChannel = useRef<string>()
  const selectedRegion = useRef<string>()
  const selectedCell = useRef<string>()

  const channels = _.defaultTo(dataChannels?.channels, [])
  const cells = _.defaultTo(dataCells?.cells, [])
  const shops = _.defaultTo(dataShops?.shops, [])

  useEffect(() => {
    if (selected) {
      if (dataShops) {
        resetShops()
      }

      form.setFieldsValue({
        ...selected,
        regionAndCellId: [selected.region.id, selected.cell.id],
        channelId: selected.channel.id,
        shopId: selected.shop.id,
        regionId: selected.region.id
      })
    }
  }, [selected])

  const cascaderOptions = _.uniqBy(cells.map(cell => {
    const region = cell.region

    return {
      value: region.id,
      label: region.name,
      children: cells.filter(cell =>
        cell.region.id === region.id
      ).map(cell => {
        return {
          value: cell.id,
          label: cell.name,
        }
      })
    }
  }), (o: { value: string }) => o.value)

  const [addMediacenter] = useMutation(ADD_MEDIACENTER, {
    onCompleted() {
      notification.success({
        message: `성공적으로 미디어센터가 등록되었습니다.`,
      })
      onClose()
    },
  })

  const [updateMediacenter] = useMutation(UPDATE_MEDIACENTER, {
    onCompleted() {
      notification.success({
        message: `성공적으로 미디어센터가 업데이트되었습니다.`,
      })
      onClose()
    },
  })

  const [removeMediacenter] = useMutation(REMOVE_MEDIACENTER, {
    onCompleted({removeMediacenter}) {
      if (removeMediacenter) {
        onClose()
      }
    },
  })

  const onMediacenterAdd = (values: MediacenterFormValues) => {
    const variables = getUpsertVariables(values)

    const found = rtiMcs.map(rtiMc => [rtiMc.mediacenter.mac, rtiMc.mediacenter.serial])
      .find(macAndSerial => {
        if (macAndSerial[0] === variables.mac) {
          setMutationError(new ApolloError({errorMessage: "이미 MAC 주소가 존재합니다."}))
          return true
        }

        if (!_.isEmpty(macAndSerial[1]) && macAndSerial[1] === variables.serial) {
          setMutationError(new ApolloError({errorMessage: "이미 시리얼 번호가 존재합니다."}))
          return true
        }

        return false
      })

    if (found) {
      return
    }

    const mediacenterInput = {
      variables: {
        mediacenterInput: variables
      }
    }

    addMediacenter(mediacenterInput)
      .catch((e) => {
        notification.error({
          message: `미디어센터를 추가 중에 에러가 발생했습니다.`
        })

        setMutationError(e)
      })
  }

  const onMediacenterUpdate = () => {
    const values = form.getFieldsValue()
    const variables = getUpsertVariables(values)

    updateMediacenter({
      variables
    }).catch((e) => {
      notification.error({
        message: `미디어센터를 업데이트 중에 에러가 발생했습니다.`
      })

      setMutationError(e)
    })
  }

  const getUpsertVariables = (values: MediacenterFormValues) => {
    const {
      mac,
      shopId,
      regionAndCellId,
    } = values

    const MAC = mac.toUpperCase()
    const cellId = shops.find(s => s.id === shopId)!.cell.id

    return {
      ..._.omit(values, 'regionAndCellId'),
      name: MAC,
      mac: MAC,
      cellId,
      regionId: regionAndCellId[0]
    }
  }

  const onMediacenterRemove = () => {
    removeMediacenter({
      variables: {
        id: form.getFieldValue('id'),
      }
    })
      .then(() => {
        notification.success({
          message: `성공적으로 미디어센터가 제거되었습니다.`,
        })
        cleanFormData()
      })
      .catch((e) => {
        notification.error({
          message: `미디어센터를 제거 중에 에러가 발생했습니다.`
        })

        setMutationError(e)
      })
  }

  const cleanFormData = () => {
    onClose()
    form.resetFields()
    setMutationError(undefined)
  }

  const resetShops = () => {
    form.resetFields(['shopId'])

    const channelId = form.getFieldValue('channelId')
    const regionAndCellId = form.getFieldValue('regionAndCellId')
    const regionId = (regionAndCellId?.length > 0) && regionAndCellId[0]
    const cellId = (regionAndCellId?.length > 0) && regionAndCellId[1]

    const shops = dataShops?.shops.filter(s =>
      (channelId ? channelId === s.channel.id : true) &&
            ((regionId ? regionId === s.region.id : true) &&
            (cellId ? cellId === s.cell.id : true))
    ) ?? []

    setShops(shops)
  }

  const onChannelChanged = (channelId: string) => {
    if (selectedChannel.current !== channelId) {
      resetShops()

      selectedChannel.current = channelId
    }
  }

  const onRegionCellChanged = ([regionId, cellId]: [string, string]) => {
    if (selectedRegion.current !== regionId || selectedCell.current !== cellId) {
      resetShops()

      selectedRegion.current = regionId
      selectedCell.current = cellId
    }
  }

  const validateMac = (_, value: string) => {
    // empty value is validated already at mac's rules (required)
    if (!value || value.length === 0) {
      return Promise.resolve()  // MAC 주소를 입력해 주세요.
    }

    const mac = value.toUpperCase()
    if (rtiMcs.find(rtiMc => rtiMc.mediacenter.mac === mac)) {
      return Promise.reject('MAC 주소가 중복되었습니다.')
    } else {
      return Promise.resolve()
    }
  }

  return (
    <Drawer
      title={`미디어센터 ${modalMode}`}
      width={getResponsiveDrawerWidth(400)}
      placement="right"
      closable
      onClose={() => {
        onClose()
        setMutationError(undefined)
      }}
      visible={visible}
      footer={
        <DrawerFooter
          formId="formAddMediacenter"
          close={() => {
            onClose()
            setMutationError(undefined)
          }
          }
          isNew={modalMode === '추가'}
          onUpdate={onMediacenterUpdate}
          onRemove={onMediacenterRemove}
        />
      }
      forceRender
    >

      <Form
        id="formAddMediacenter"
        form={form}
        layout="vertical"
        labelAlign="right"
        colon
        onFinish={onMediacenterAdd}
      >
        <Form.Item name="id" hidden>
        </Form.Item>

        <Form.Item name="mode" hidden>
        </Form.Item>

        <Form.Item
          name="mac"
          label="MAC 주소"
          rules={[
            { required: true, message: 'MAC 주소를 입력해 주세요. (ex. \'0014CDAABBCC\')' },
            { pattern: /[0-9A-Fa-f]{12}/, message: 'MAC 주소 형식에 맞지 않는 문자입니다.' },
            { len: 12, message: '12자리 MAC 주소를 입력해 주세요.' },
            { validator: validateMac }
          ]}
          validateTrigger="onChange"
        >
          <Input
            placeholder="MAC 주소 입력해 주세요."
            maxLength={12}
            style={{ textTransform: 'uppercase' }}
          />
        </Form.Item>

        <Form.Item
          name="channelId"
          label="판매 경로"
          rules={[{required: true, message: '판매 경로를 선택해 주세요.'}]}
          validateTrigger="onSubmit"
        >
          <Select
            placeholder="판매 경로를 선택해 주세요."
            dropdownMatchSelectWidth={false}
            onChange={onChannelChanged}
          >
            {
              channels.map(
                channel =>
                  <Select.Option
                    key={channel.id}
                    value={channel.id}
                  >
                    {channel.name}
                  </Select.Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          name="regionAndCellId"
          label="지역"
          rules={[{required: true, message: '지역을 선택해 주세요.'}]}
          validateTrigger="onSubmit"
        >
          <Cascader
            onChange={(v) => onRegionCellChanged(v as [string, string])}
            options={cascaderOptions}
            placeholder="지역을 선택해 주세요."
          />
        </Form.Item>

        <Form.Item
          name="shopId"
          label="매장"
          rules={[{required: true, message: '매장을 선택해 주세요.'}]}
          validateTrigger="onSubmit"
        >
          <Select
            placeholder="매장을 선택해 주세요."
            dropdownMatchSelectWidth={false}
          >
            {
              shopList.map(
                shop =>
                  <Select.Option
                    key={shop.id}
                    value={shop.id}
                    disabled={shop.mediacenters && shop.mediacenters.length > 0}
                  >
                    {shop.name}
                  </Select.Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          name="serial"
          label="시리얼 번호"
        >
          <Input
            placeholder="시리얼 번호를 입력해 주세요."
          />
        </Form.Item>

        <Form.Item
          name="desc"
          label="설명"
        >
          <TextArea maxLength={255} autoSize={{ minRows: 3, maxRows: 3 }} 
            placeholder="미디어센터 설명을 입력해 주세요."
          />
        </Form.Item>
      </Form>

      <DrawerErrorAlert error={mediacenterError}/>
    </Drawer>
  )
}

export {MediacenterDrawer}
