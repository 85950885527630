import React from 'react'

function PageStatistics() {
  return (
    <div>
            STATISTICS
    </div>
  )
}


export { PageStatistics }
