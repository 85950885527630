import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {AuthLoginStatus, AuthLoginSuccess, AuthorizationLevel, AuthState} from './types'

const initialState: AuthState = {
  password: '',
  status: AuthLoginStatus.LOGGED_OUT,
  token: '',
  level: AuthorizationLevel.ADMIN,
  message: '',
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginSuccess(state, action: PayloadAction<AuthLoginSuccess>) {
      state.token = action.payload.token
      state.level = action.payload.accountLevel
      state.status = AuthLoginStatus.LOGGED_IN

      localStorage.setItem('token', state.token)
    },
    loginFailed(state, action: PayloadAction<string>) {
      state.status = AuthLoginStatus.LOGIN_FAILED
      state.message = action.payload
    },
    logout() {
      localStorage.clear()
      return initialState
    }
  }
})

export const { loginSuccess, loginFailed, logout } = authSlice.actions

export default authSlice.reducer
