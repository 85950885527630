import {gql} from '@apollo/client'

export const reportFragment = gql`
    fragment reportParts on Excel {
        id
        name
        filename
        path
        date
        createdAt
        updatedAt
    }
`

export const GET_REPORTS = gql`
    query GetReports {
        excels {
            ...reportParts
        }
    }
    ${reportFragment}
`

export const SUBSCRIBE_REPORT = gql`
    subscription {
        subscribeExcel {
            op
            excel {
                ...reportParts
            }
        }
    }
    ${reportFragment}
`
