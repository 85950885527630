import React from "react"
import {RootState} from "../app/reducer"
import {useSelector} from "react-redux"
import {AuthorizationLevel} from "../features/auth"
import {useHistory} from "react-router-dom"

interface Props {
    roles: AuthorizationLevel[];
}

const AuthRouteWrapper: React.FC<Props> = ({roles, children}) => {
  const history = useHistory()
  const {level} = useSelector((store: RootState) => store.authReducer)

  if (!roles.includes(level)) {
    console.log(level)
    history.push("/")
  }

  return (
    <>
      {children}
    </>
  )
}

export default AuthRouteWrapper
