import React, { FC, useState, useRef } from 'react'
import { Button, Space, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { CreditCardOutlined } from '@ant-design/icons'
import { RtiMcDto } from 'api/graphql/rti-mc'
import useModalState from '../../hooks/useModalState'
import { MediacenterDrawer } from './mediacenter-drawer'
import { Box } from '@chakra-ui/layout'
import { MediacenterDto } from '../../api/graphql/mediacenter'
import { useForm } from 'antd/lib/form/Form'
import usePageSize from '../../hooks/usePageSize'
import { sortByNumber, sortByText } from '../../utils'
import { createdAtColumn } from '../../common/created-at-column'
import { columnMacAddress, columnShopName, columnText } from '../../components/table'
import useChannels from '../../hooks/useChannels'
import useCells from '../../hooks/useCells'

interface Props {
  rtiMcs: RtiMcDto[]
}

const MediacenterList: FC<Props> = ({ rtiMcs }) => {
  const [drawerForm] = useForm()

  const {closeModal, modalMode, openAddModal, openEditModal, modalShowable} = useModalState()

  const [selected, selectMediacenter] = useState<MediacenterDto>()
  const { pageSize, setPageSize } = usePageSize('mediacenter')

  const { channels } = useChannels()
  const { cells } = useCells()

  const onMacClick = (mac: string) => {
    const mediacenter = rtiMcs.find(rtiMc => rtiMc.mediacenter.mac === mac)?.mediacenter
    if (mediacenter) {
      selectMediacenter(mediacenter)
      openEditModal()
    }
  }

  const onDrawerOpen = () => {
    drawerForm.resetFields()
    openAddModal()
  }

  const onDrawerClose = () => {
    drawerForm.resetFields()
    selectMediacenter(undefined)

    closeModal()
  }

  const columns: ColumnsType<RtiMcDto> = [
    columnMacAddress(
      ['mediacenter', 'mac'],
      (e1, e2) => sortByText(e1.mediacenter.mac, e2.mediacenter.mac),
      (value, e1) => e1.mediacenter.mac.includes(value),
      useRef(null),
      undefined,
      (record) => onMacClick(record.mediacenter.mac)
    ),
    columnShopName(
      ['mediacenter', 'shop', 'name'],
      (e1, e2) => sortByText(e1.mediacenter.shop.name, e2.mediacenter.shop.name),
      (value, e1) => e1.mediacenter.shop.name.includes(value),
      useRef(null),
      undefined
    ),
    columnText("판매 경로", ["mediacenter", "channel", "name"], "channel", 120, "left",
      { filters: channels.map((channel) => ({ text: channel.name, value: channel.name })) }
    ),
    columnText("지역", ["mediacenter", "cell", "name"], "cell", 100, "left",
      { filters: cells.map((cell) => ({ text: cell.name, value: cell.name })) }
    ),
    createdAtColumn(['mediacenter', 'createdAt'], 
      (e1, e2) => sortByNumber(e1.mediacenter.createdAt, e2.mediacenter.createdAt, )),
  ]

  return (
    <>
      <Box w="100%" textAlign="right" mb="10px">
        <Space direction="horizontal">
          <Button type="primary" icon={<CreditCardOutlined />}
            onClick={onDrawerOpen}
          >
            미디어센터 추가
          </Button>
        </Space>
      </Box>
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        scroll={{ x: 1000/*true*/ }}
        dataSource={rtiMcs}
        onChange={(pagination) => setPageSize(pagination.pageSize)}
        pagination={{ showSizeChanger: true, pageSize }}
      />
      <MediacenterDrawer
        rtiMcs={rtiMcs}
        form={drawerForm}
        selected={selected}
        visible={modalShowable}
        modalMode={modalMode}
        onClose={onDrawerClose}
      />
    </>
  )
}

export {MediacenterList}
