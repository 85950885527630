import {configureStore, Action, getDefaultMiddleware} from '@reduxjs/toolkit'
import { FLUSH } from 'redux-persist'
import persistReducer from 'redux-persist/lib/persistReducer'
import persistStore from 'redux-persist/lib/persistStore'
import storage from 'redux-persist/lib/storage'
import { ThunkAction } from 'redux-thunk'

import rootReducer, { RootState } from './reducer'
import {PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE} from "redux-persist/lib/constants"

const store = configureStore({
  reducer: persistReducer({key: 'root', storage}, rootReducer),
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})
const persistor = persistStore(store)

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./reducer', () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    store.replaceReducer(rootReducer)
  })
}

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default () => {
  return {store, persistor}
}
