import React from "react"
import { Button, Input } from 'antd'
import { SearchOutlined, ThunderboltFilled } from '@ant-design/icons'
import { AlignType } from 'rc-table/lib/interface'
import { sortByText } from '../../utils'

type ColumnTextFilterOptions = {
  searchInputRef?: any
  filters?: { text: string, value: string }[]
}

const genRender = (onClick) => {
  if (onClick) {
    return (text, record) =>
      <a href="#" onClick={() => onClick(record)}>
        {text}
      </a>
  } else {
    return (text) =>
      <span>{text}</span>
  }
}

const genFilterDropDown = (title, filterOptions) => ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters
}) => (
  <div style={{
    padding: 8, borderRadius: 4, background: "#ffffff",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)"
  }}>
    <Input
      ref={node => filterOptions.searchInputRef = node }
      placeholder={title}
      value={selectedKeys[0]}
      onChange={e =>
        setSelectedKeys(e.target.value ? [e.target.value] : [])
      }
      onPressEnter={() => confirm()}
      style={{ width: 140, marginBottom: 8, display: "block" }}
    />
    <Button
      type="link"
      onClick={() => clearFilters()}
      size="small"
      style={{ width: 65 }}
      disabled={selectedKeys[0]?.length > 0 ? false : true}
    >
      Reset
    </Button>
    <Button
      type="primary"
      onClick={() => confirm()}
      //icon={<SearchOutlined />}
      size="small"
      style={{ width: 65, marginRight: 8 }}
    >
      OK
    </Button>
  </div>
)

const getValueFromRecord = (record, dataIndex): string | undefined => {
  let v = undefined
  if (Array.isArray(dataIndex)) {
    let o = record
    for (const key of dataIndex) {
      o = o[key]
      if (o) {
        v = o
      } else {
        v = undefined
        break
      }
    }
  } else {
    v = record[dataIndex]
  }

  return v
}

export const columnText = (
  title: string,
  dataIndex: string | string[],
  key: string,
  width: number,
  align: AlignType,
  filterOptions: ColumnTextFilterOptions,
  onClick?: (record) => void
) => ({
  title: title,
  dataIndex: dataIndex,
  key: key,
  width: width === 0 ? undefined : width,
  align: align,

  // render
  render: genRender(onClick),

  // sort
  sorter: (e1, e2) => {
    const v1 = getValueFromRecord(e1, dataIndex)
    const v2 = getValueFromRecord(e2, dataIndex)
    
    return sortByText(v1, v2)
  },
  showSorterTooltip: false,

  // filter
  filterSearch: true,
  onFilter: (value, record) => {
    const v = getValueFromRecord(record, dataIndex)
    
    if (v) {
      return filterOptions.searchInputRef ? v.toLowerCase().includes(value.toLowerCase()) : v === value
    } else {
      return false
    }
  },
  filters: filterOptions.filters,
  filterDropdown: filterOptions.searchInputRef ? genFilterDropDown(title, filterOptions) : undefined,
  filterIcon: filtered => (
    filtered ? <ThunderboltFilled style={{ color: "#ffa940", fontSize: 16 }}/> : 
      <SearchOutlined />
  ),
  onFilterDropdownVisibleChange: filterOptions.searchInputRef ? visible => {
    if (visible) {
      setTimeout(() => filterOptions.searchInputRef.select())
    }
  } : undefined ,
})
