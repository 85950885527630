import {McState} from "../api/graphql/rti-mc"

class McStateViewModel {
  constructor(private mcState: McState) {
  }

  toString() {
    switch (this.mcState) {
    case McState.ONLINE:
      return "접속"
    case McState.OFFLINE:
      return "미접속"
    case McState.ERROR:
      return "검토"
    case McState.UNKNOWN:
      return "알 수 없음"
    default:
      return ""
    }
  }
}

export default McStateViewModel
