import {GET_GROUPS, GroupDto, Groups, SUBSCRIBE_GROUP} from "../api/graphql/group"
import useDtos, {LoadingAndError} from "./useDtos"
import {afterProvided} from "../utils"

interface Result extends LoadingAndError {
    groups: GroupDto[];
}

const useGroups = (): Result => {
  const {allDtos: groups, loading, error} = useDtos<GroupDto, Groups>({
    query: GET_GROUPS,
    queryResultKey: 'groups',
    subscription: SUBSCRIBE_GROUP,
    updateQueryFunction: afterProvided({
      queryResultKey: 'groups',
      subscriptionResultKey: 'subscribeGroup',
      dataKey: 'group'
    })
  })

  return {groups, loading, error}
}

export default useGroups
