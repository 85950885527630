import React from 'react'
import { Box } from '@chakra-ui/layout'
import { Line, ChartData } from "react-chartjs-2"
import * as chartjs from "chart.js"
import LogMcViewModel from "models/log-mc"

interface Props {
  logMcs: LogMcViewModel
}

const RtiShopDetailMclogChart: React.FC<Props> = ({ logMcs }) => {

  const today0000 = new Date()
  today0000.setHours(0)
  today0000.setMinutes(0)
  today0000.setSeconds(0)

  const today2400 = new Date(today0000.getTime())
  today2400.setDate(today2400.getDate() + 1)

  // const [logMcs, setLogMcs] = useState<LogMcViewModel>(new LogMcViewModel([]));

  // const {labels: lineLabels, data: lineData} = logMcs.getLineChartLabelsAndData();
  const data = logMcs.getLineChartData()
  const lineChartData: ChartData<chartjs.ChartData> = {
    // labels: lineLabels,
    datasets: [
      {
        fill: false,
        data: data,
        lineTension: 0.1,
        steppedLine: 'before',
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 1,
      }
    ],
  }
  const lineChartOption: chartjs.ChartOptions = {
    legend: {
      display: false,
    },
    responsive: false,
    plugins: {
      datalabels: {display: false,}
    },
    scales: {
      xAxes: [{
        type: "time",
        time: {
          displayFormats: { minute: 'HH:mm' },
          unit: 'minute',
          minUnit: 'minute',
          stepSize: 60 * 6,
        },
        distribution: 'linear',
        // gridLines: {display: false}
        ticks: {
          display: true,
          source: 'auto',
          min: today0000.toISOString(),
          max: today2400.toISOString()
        },
      }],
      yAxes: [{
        ticks: {
          max: 100,
          min: 0,
          stepSize: 20,
          callback: (value) => value.toString() + "%"
        }
      }],
      /*
          ticks: {
              maxTicksLimit: 4,
          },
          */
    }
  }

  return (
    <Box w="96%" h="120px">
      <Line data={lineChartData} options={lineChartOption} width={300} height={120}/>
    </Box>
  )

}

export { RtiShopDetailMclogChart }