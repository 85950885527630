import { gql } from '@apollo/client'
import { mediaFragment } from "../media"

export const pairingFragment = gql`
  fragment pairingParts on Pairing {
    id
    name
    desc
    playlists {
      id
      group {
        id
        name
      }
      playlistMedias {
        id
        name
        order
        media {
          ...mediaParts
        }
      }
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
  ${mediaFragment}
`

export const GET_PAIRINGS = gql`
  query GetPairings {
    pairings {
      ...pairingParts
    }
  }
  ${pairingFragment}
`

export const GET_PAIRING = gql`
  query GetPairing($id: String!) {
    getPairing(id: $id) {
      ...pairingParts
    }
  }
  ${pairingFragment}
`

export const SUBSCRIBE_PAIRING = gql`
  subscription {
    subscribePairing {
      op
      pairing {
        ...pairingParts
      }
    }
  }
  ${pairingFragment}
`
