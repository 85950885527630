import useDtos, {DtoFilter, LoadingAndError} from "./useDtos"
import {afterProvided} from "../utils"
import React from "react"
import {GET_SHOPS, ShopDto, Shops, SUBSCRIBE_SHOP} from "../api/graphql/shop"

interface Result extends LoadingAndError {
    shops: ShopDto[];
    allShops: ShopDto[];
    setFilter: React.Dispatch<React.SetStateAction<DtoFilter<ShopDto>>>;
}

const useShops = (): Result => {
  const {allDtos: allShops, setFilter, dtos: shops, loading, error} = useDtos<ShopDto, Shops>({
    query: GET_SHOPS,
    queryResultKey: 'shops',
    subscription: SUBSCRIBE_SHOP,
    updateQueryFunction: afterProvided({
      queryResultKey: 'shops',
      subscriptionResultKey: 'subscribeShop',
      dataKey: 'shop'
    })
  })

  return {shops, allShops, setFilter, loading, error}
}

export default useShops
