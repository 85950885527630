import React, {FC} from "react"
import {Box, Stack} from "@chakra-ui/layout"
import {DatePicker, Typography} from "antd"
import Input from "antd/lib/input"
import Select from "antd/lib/select"
import Form, {FormInstance} from "antd/lib/form"
import _ from "lodash"

const {RangePicker} = DatePicker

export interface SearchItemProps {
    id: string;
    title: string;
    placeholder?: string;
    type?: "dropbox" | "text" | "date" | "datetime";
    dropboxOptions?: [any, any][];
    width?: number | string;
    controllableValue?: FormInstance;
}

export const SearchItem: FC<SearchItemProps> = ({id, width, placeholder, title, dropboxOptions, controllableValue, type = "text"}) => {
  const w = width ?? 300

  const onOk = (value) => {
    if (controllableValue) {
      if (type === "date" && _.every(value, !_.isEmpty)) {
        controllableValue.setFieldsValue({
          ...controllableValue.getFieldsValue(),
          [id]: value.map(m => m.toDate())
        })
      }
    }
  }

  const Field = () => {
    if (type === "text") {
      return <Input style={{width: w}} placeholder={placeholder} allowClear/>

    }

    if (type === "dropbox") {
      return (
        <Select style={{width: w}} placeholder={placeholder} allowClear>
          {dropboxOptions?.map(option => (
            <Select.Option key={option[0]} value={option[0]}>{option[1]}</Select.Option>
          ))}
        </Select>
      )
    }

    if (type === 'date' || type === 'datetime') {
      return <RangePicker placeholder={["시작 날짜", "마지막 날짜"]} style={{width: w}} onOk={onOk} showTime={type === 'datetime'}/>
    }

    return <></>
  }

  return (
    <Stack spacing={8} isInline align="center">
      <Box ml={16} mr={8} minW={60}>
        <Typography.Text strong>
          {title}
        </Typography.Text>
      </Box>

      <Box flex={1}>
        <Form.Item name={id} noStyle>
          {Field()}
        </Form.Item>
      </Box>
    </Stack>
  )
}
