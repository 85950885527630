import React, { useState, useEffect } from 'react'
import { Col, Divider, Drawer, Empty, Row, Typography, notification } from 'antd'
import { McState, RtiMcDto } from 'api/graphql/rti-mc'
import { RtiShopDetailShopState } from './detail-shop-state'
import Button from 'antd/lib/button'
import { Box, Stack, Wrap } from '@chakra-ui/layout'
import { Bar, Doughnut } from 'react-chartjs-2'
import { chartColors } from 'common/chart-colors'
import TvConnection from 'models/tv-connection'
import 'chartjs-plugin-datalabels'
import * as chartjs from 'chart.js'
import { useQuery } from '@apollo/client'
import { GET_LOG_MCS } from 'api/graphql/log/queries'
import { LogMcs } from '../../api/graphql/log/types'
import { endOfDay, startOfDay } from 'date-fns'
import _ from 'lodash'
import LogMcViewModel from 'models/log-mc'
//import RtiMcViewModel from 'models/rti-mc'
import { useSelector } from 'react-redux'
import { IStore } from '../../app/reducer'
import PairingContentList from '../../components/pairing-content-list'
import DrawerSection from 'components/common/drawer-section'
import { TvGroupStatusList } from 'components/mediacenter/tv-group-status-list'
import { RtiShopDetailMclogChart } from './detail-mclog-chart'
import { getResponsiveDrawerWidth } from 'common/layouts'
import { useMutation } from '@apollo/client'
import { REBOOT_MEDIACENTER, CLEAR_MEDIACENTER_TV_INFOS } from 'api/graphql/mediacenter/mutations'
import { RtiShopDetailTvinfos } from './detail-tvinfos'
import format from "date-fns/format"

interface Props {
  rtiMc: RtiMcDto
  visible: boolean
  onClose: () => void
}

const RtiShopDetail: React.FC<Props> = ({ rtiMc, visible, onClose }) => {
  const [logMcs, setLogMcs] = useState<LogMcViewModel>(new LogMcViewModel([]))
  //const [rtiMcViewModel, setRtiMcViewModel] = useState<RtiMcViewModel>(new RtiMcViewModel(rtiMc))

  const [visibleModal, setVisibleModal] = useState(false)

  const [pollVariable, setPollVariable] = useState({
    id: rtiMc.id,
    start: startOfDay(new Date()),
    until: endOfDay(new Date()),
  })

  const groups = useSelector((state: IStore) => state.groups)

  const {data: dataLogMcs} = useQuery<LogMcs>(GET_LOG_MCS, {
    variables: {
      logMcInput: pollVariable,
    }
  })

  const {
    connectCount,
    unconnectCount,
    errorCount,
    connectPercent,
    totalTvCount,
    errorPercent,
    unconnectPercent
  } = TvConnection.getGroupStatistics([rtiMc])

  /*
  React.useEffect(() => {
    setRtiMcViewModel(new RtiMcViewModel(rtiMc))
  }, [rtiMc])
  */

  React.useEffect(() => {
    if (dataLogMcs) {
      setTimeout(() => {
        setPollVariable({
          ...pollVariable,
          start: new Date(),
        })
      }, 1000 * 60 * 3)

      setLogMcs(new LogMcViewModel(_.concat(logMcs.logs, dataLogMcs.logMcs)))
    }
  }, [rtiMc, dataLogMcs])

  const labels = ['접속', '미접속', '검토']
  const doughnutChartData = {
    labels,
    datasets: [{
      data: [connectPercent, unconnectPercent, errorPercent],
      backgroundColor: chartColors,
    }],
    text: `${connectPercent}%`,
  }
  const doughnutChartOption = {
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false
    },
    elements: {
      arc: {
        borderWidth: 1
        // weight: 4
      }
    },
    cutoutPercentage: 70,
    plugins: {
      datalabels: {display: false}
    },
  }
  const barChartData = {
    labels,
    datasets: [{
      data: [connectCount, unconnectCount, errorCount],
      backgroundColor: chartColors,
    }],
    // barPercentage: 0.5,
    //categoryPercentage: 0.5
  }
  const barChartOption: chartjs.ChartOptions = {
    legend: {
      display: false,
    },
    responsive: false,
    tooltips: {
      enabled: false
    },
    maintainAspectRatio: false,
    scales: {
      xAxes: [{
        gridLines: {
        },
      }],
      yAxes: [{
        ticks: {
          min: 0,
          max: 30,
          stepSize: 10,
        }
                
      }]
    },
    plugins: {
      datalabels: {
        display: true,
        color: '#404040',
        anchor: 'end',
        align: 'top',
        offset: -4,
      }
    }
  }

  /*
  const {labels: lineLabels, data: lineData} = logMcs.getLineChartLabelsAndData()
  const lineChartData: ChartData<chartjs.ChartData> = {
    labels: lineLabels,
    datasets: [
      {
        fill: false,
        data: lineData,
        lineTension: 0.1,
        backgroundColor: 'rgba(75,192,192,0.4)',
        borderColor: 'rgba(75,192,192,1)',
        borderCapStyle: 'butt',
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: 'miter',
        pointBorderColor: 'rgba(75,192,192,1)',
        pointBackgroundColor: '#fff',
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: 'rgba(75,192,192,1)',
        pointHoverBorderColor: 'rgba(220,220,220,1)',
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
      }
    ],
  }
  const lineChartOption: chartjs.ChartOptions = {
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {display: false,}
    },
    scales: {
      xAxes: [{gridLines: {display: false}}],
      yAxes: [{
        ticks: {
          max: 100,
          stepSize: 10,
          callback: (value) => value.toString() + "%"
                    
        }
      }],
      ticks: {
        maxTicksLimit: 4,
      },
    }
  }
  */
  const [rebootMediacenter] = useMutation(REBOOT_MEDIACENTER, {
    onCompleted() {
      notification.success({
        message: `미디어센터 리셋을 실행하였습니다.`,
      })
    },
  })
  const onMediacenterRebootClicked = () => {
    rebootMediacenter({
      variables: {
        id: rtiMc.id
      }
    }).catch((/*e*/) => {
      notification.error({
        message: `미디어센터를 리셋하는 중에 에러가 발생했습니다.`
      })
    })
  }

  const [clearMediacenterTvInfos] = useMutation(CLEAR_MEDIACENTER_TV_INFOS, {
    onCompleted() {
      notification.success({
        message: `미디어센터 TV 정보 초기화를 실행하였습니다.`,
      })
    },
  })
  const onClearMediacenterTvInfosClicked = () => {
    clearMediacenterTvInfos({
      variables: {
        id: rtiMc.id
      }
    }).catch((/*e*/) => {
      notification.error({
        message: `미디어센터 TV 정보를 초기화하는 중에 에러가 발생했습니다.`
      })
    })
  }

  const onCollectMediacenterTvInfosClicked = () => {
    setVisibleModal(true)
  }

  return (
    <>
      <Drawer
        title="매장 상세 정보"
        width={getResponsiveDrawerWidth(720)}
        placement="right"
        onClose={onClose}
        visible={visible}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={onClose} style={{marginRight: 8}}>
              닫기
            </Button>
          </div>
        }
      >
        <Row align="middle" style={{ border: "solid 1px #808080", background: "#fff7e6", padding: "4px", borderRadius: "4px" }}>
          <Col offset={1} span={12}>
            <Typography.Text
              style={{fontSize: '1rem', marginRight: '6px'}}
              strong
              ellipsis
            >
              {rtiMc.mediacenter.shop.name}
            </Typography.Text>
            <Typography.Text style={{color: '#606060'}} ellipsis>
              ({ rtiMc.mediacenter.channel.name }/{ rtiMc.mediacenter.cell.name })
            </Typography.Text>
          </Col>
          <Col span={5}>

          </Col>
          <Col span={3}>
            { 
              rtiMc.mediacenter.recognizedAt &&
                <Typography.Text style={{color: '#808080'}} ellipsis>
                  { format(new Date(rtiMc.mediacenter.recognizedAt), "yyyy-MM-dd") }
                </Typography.Text>
            }
          </Col>
          <Col span={3}>
            <div style={{textAlign: 'right'}}>
              <RtiShopDetailShopState state={rtiMc.state}/>
            </div>
          </Col>
        </Row>

        <Row>
          <Col span={12} style={{ margin: 0, paddingRight: 8 }}>
            <DrawerSection title="접속 현황">
              <Stack h={120} pl={12} pr={12} spacing={32} isInline>
                <Box mr={12} position="relative" w={80} height={80} alignSelf="center">
                  <Doughnut
                    data={doughnutChartData}
                    options={doughnutChartOption}
                    width={80}
                    height={80}/>

                  <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)"
                    textAlign="center">
                    <Box fontWeight="bold" fontSize="12px" color="#404040">{connectPercent}%</Box>
                    <Box fontSize="8px" color="#404040">
                      {connectCount} / {totalTvCount}
                    </Box>
                  </Box>
                </Box>

                <Divider type="vertical" style={{ height: 100, backgroundColor: '#e0e0e0' }}/>

                <Box pt={0} h={120}>
                  <Bar data={barChartData} options={barChartOption} width={200} height={120} />
                </Box>
              </Stack>
            </DrawerSection>
          </Col>

          <Col span={12} style={{ paddingRight: 8 }}>
            <DrawerSection title="접속률 변화 그래프">
              <RtiShopDetailMclogChart logMcs={logMcs} />
            </DrawerSection>
          </Col>
        </Row>

        <DrawerSection title="TV 그룹 별 접속 현황">
          <Wrap>
            <TvGroupStatusList groups={rtiMc.groups} allGroups={groups} disabled={rtiMc.state !== McState.ONLINE}/>
          </Wrap>
        </DrawerSection>

        <DrawerSection title="TV 그룹 별 컨텐츠 리스트">
          {
            rtiMc.pairingId ? 
              <PairingContentList groups={groups} pairingId={rtiMc.pairingId} />
              : 
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          }
        </DrawerSection>
        <DrawerSection title="미디어센터 제어">
          <Button onClick={onMediacenterRebootClicked} style={{marginRight: 8}}>
            MC 리셋
          </Button>
          <Button onClick={onClearMediacenterTvInfosClicked} style={{marginRight: 8}}>
            TV정보 초기화
          </Button>
          <Button onClick={onCollectMediacenterTvInfosClicked} style={{marginRight: 8}}>
            TV 상세 정보 조회
          </Button>
        </DrawerSection>

        <RtiShopDetailTvinfos
          rtiMc={rtiMc}
          visible={visibleModal}
          onClose={() => setVisibleModal(false)}
        />

      </Drawer>
    </>
  )
}

export { RtiShopDetail }
