import {CellDto, Cells, GET_CELLS, SUBSCRIBE_CELL} from "../api/graphql/cell"
import useDtos, {LoadingAndError} from "./useDtos"
import {afterProvided} from "../utils"

interface Result extends LoadingAndError {
  cells: CellDto[]
  allCells: CellDto[]
}

const useCells = (): Result => {
  const {allDtos: allCells, dtos: cells, loading, error} = useDtos<CellDto, Cells>({
    query: GET_CELLS,
    queryResultKey: 'cells',
    subscription: SUBSCRIBE_CELL,
    updateQueryFunction: afterProvided({
      queryResultKey: 'cells',
      subscriptionResultKey: 'subscribeCell',
      dataKey: 'cell'
    })
  })

  return {allCells, cells, loading, error}
}

export default useCells
