import { gql } from '@apollo/client'
import { cellFragment } from "../cell"
import { amcodeFragment } from "../amcode"

export const channelFragment = gql`
  fragment channelParts on Channel {
    id
    name
    desc
    division {
      id
      name
    }
    createdAt
    updatedAt
  }
`

export const GET_CHANNELS = gql`
  query GetChannels {
    channels {
      ...channelParts
    }
  }
  ${channelFragment}
`

export const GET_CHANNEL_LOGS = gql`
  query GetChannelLogs($logChInput: LogChInput!) {
    logChs(logChInput: $logChInput) {
      onlineRate
      createdAt
      channel {
        name
      }
    }
  }
`

export const GET_CHANNEL_DAY_LOGS = gql`
  query GetChannelDayLogs($logChInput: LogChInput!) {
    logChDays(logChInput: $logChInput) {
      onlineRate
      createdAt
      channel {
        name
      }
    }
  }
`

export const GET_CHANNELS_CELLS_AMCODES = gql`
  query {
    channels {
      ...channelParts
    }
    cells {
      ...cellParts
    }
    amcodes {
      ...amcodeParts
    }
  }
  ${channelFragment}
  ${cellFragment}
  ${amcodeFragment}
`

export const SUBSCRIBE_CHANNEL = gql`
  subscription {
    subscribeChannel {
      op
      channel {
        id
        name
        desc
        division {
          id
          name
        }
        createdAt
        updatedAt
      }
    }
  }
`
