import {LogMcDto} from "../api/graphql/log/types"
import {sortByCreatedAt} from "../utils"
import * as chartjs from "chart.js"
import fp from "lodash/fp"

class LogMcViewModel {
  constructor(private logMcs: LogMcDto[]) {
  }

  get logs(): LogMcDto[] {
    return sortByCreatedAt(this.logMcs, "ASC")
  }

  getLineChartLabelsAndData(chunkSize?: number): { labels: string[], data: number[] } {
    const labelsAndData: [string[], number[]] = this.logs.reduce(
      (acc, log) => {
        const totalCount = log.totalCount === 0 ? 1 : log.totalCount

        // acc[0].push(format(new Date(log.createdAt), 'HH:mm'));
        acc[1].push(log.onlineCount / totalCount * 100)

        return acc
      }
      , [[] as string[], [] as number[]])

    if (fp.isEmpty(labelsAndData[0])) {
      return {labels: ["00:00", "06:00", "12:00", "18:00", "24:00"], data: []}
    }

    if (chunkSize) {
      const chunking = fp.flow(fp.chunk(chunkSize), fp.head, fp.flatten)
      return {labels: chunking(labelsAndData[0]), data: chunking(labelsAndData[1])}
    }

    return {labels: labelsAndData[0], data: labelsAndData[1]}
  }

  getLineChartData(): chartjs.ChartPoint[] {
    const data: chartjs.ChartPoint[] = this.logs.reduce((acc, log) => {
      const totalCount = log.totalCount === 0 ? 1 : log.totalCount

      acc.push({
        x: new Date(log.createdAt),
        y: log.onlineCount / totalCount * 100
      })
      return acc
    }, [] as chartjs.ChartPoint[]) 

    return data 
  }
}

export default LogMcViewModel
