import React, { FC } from 'react'
import { Redirect, Route } from 'react-router-dom'
import {
  PageAccount,
  PageBasedata,
  PageDashboard,
  PageMedia,
  PageMediacenter,
  PageShop,
  PageStatistics,
  PageStatusMediacenter,
  PageStatusShop,
  PageUpdateDist,
  PageUpdatePairing,
  PageTvGroup,
  PageSmd,
  PageReport,
  PageFirmware,
  PageBasedataDivision,
  PageBasedataChannel,
  PageBasedataRegion,
  PageBasedataCell,
  PageTest
} from "../pages"
import { TableContextProvider } from "../contexts/TableContextProvider"
import { PageStatusUpdate } from "../pages/page-status-update"
import { AuthorizationLevel } from "../features/auth"
import AuthRouteWrapper from "../components/auth-route-wrapper"
import useRtiMcs from "../hooks/useRtiMcs"

interface Props {
  authenticated: boolean
}

const AuthRoute: FC<Props> = props => {
  const { authenticated } = props
  if (!authenticated) {
    return <Redirect to={{ pathname: '/login' }}/>
  }

  // IMPORTANT : for UPDATING RTI-MC's cache DEFINITELY.
  useRtiMcs()

  return (
    <>
      <AuthRouteWrapper
        roles={[AuthorizationLevel.ADMIN, AuthorizationLevel.OPERATOR, AuthorizationLevel.USER, AuthorizationLevel.VISITOR]}>
        <Route exact path="/" component={PageDashboard} />
        <Route exact path="/dashboard" component={PageDashboard}/>
      </AuthRouteWrapper>

      <AuthRouteWrapper
        roles={[AuthorizationLevel.ADMIN, AuthorizationLevel.OPERATOR, AuthorizationLevel.USER]}>
        <Route exact path="/status/shop" component={PageStatusShop}/>
      </AuthRouteWrapper>

      <AuthRouteWrapper
        roles={[AuthorizationLevel.ADMIN, AuthorizationLevel.OPERATOR]}>
        <TableContextProvider>
          <Route exact path="/status/update" component={PageStatusUpdate}/>
        </TableContextProvider>

        <Route exact path="/status/mediacenter" component={PageStatusMediacenter}/>
        <Route exact path="/statistics" component={PageStatistics}/>
        <Route exact path="/update/pairing" component={PageUpdatePairing}/>

        <TableContextProvider>
          <Route exact path="/update/dist" component={PageUpdateDist}/>
        </TableContextProvider>

        <Route exact path="/manage/shop" component={PageShop}/>
        <TableContextProvider>
          <Route exact path="/manage/media" component={PageMedia}/>
        </TableContextProvider>
      </AuthRouteWrapper>

      <AuthRouteWrapper
        roles={[AuthorizationLevel.ADMIN]}>
        <Route exact path="/manage/mediacenter" component={PageMediacenter}/>
        <Route exact path="/manage/basedata" component={PageBasedata}/>
        <Route exact path="/manage/group" component={PageTvGroup}/>
        <Route exact path="/manage/account" component={PageAccount}/>
        <Route exact path="/manage/smd" component={PageSmd}/>
        <Route exact path="/system/report" component={PageReport}/>
        <Route exact path="/system/firmware" component={PageFirmware}/>
        <Route exact path="/basedata/division" component={PageBasedataDivision}/>
        <Route exact path="/basedata/channel" component={PageBasedataChannel}/>
        <Route exact path="/basedata/region" component={PageBasedataRegion}/>
        <Route exact path="/basedata/cell" component={PageBasedataCell}/>
        <Route exact path="/test" component={PageTest}/>
      </AuthRouteWrapper>

      {/*
      <AuthRouteWrapper
        roles={[AuthorizationLevel.ADMIN, AuthorizationLevel.OPERATOR, AuthorizationLevel.USER, AuthorizationLevel.VISITOR]}>
        <Route path="/*" render={() => {console.log('#####'); return <Redirect to="/dashboard"/>}}/>
      </AuthRouteWrapper>
      */}

    </>
  )
}

export default AuthRoute
