import React, { FC } from 'react'
import { Box, Center, Stack, WrapItem } from "@chakra-ui/layout"
import { Typography } from "antd"

interface Props {
  groupName: string
  totalCount: number
  onlineCount: number
  disabled: boolean
}
  
const TvGroupStatus: FC<Props> = ({groupName, totalCount, onlineCount, disabled}) => {
  return (
    <WrapItem fontSize="8px">
      <Stack border={disabled ? "1px solid #a0a0a0" : "1px solid black"} minW="40px">
        <Box pl={2} pr={2} background="#f0f0f0" borderBottom={disabled ? "1px solid #a0a0a0" : '1px solid black'}
          textAlign="center"
        >
          <Typography.Text style={{fontWeight: 'bold', color: disabled ? "#808080" : "black"}}>
            {groupName}
          </Typography.Text>
        </Box>

        <Center w="100%">
          <Box mr={2}>
            {onlineCount}
          </Box>

          <Box mr={2}>
            /
          </Box>

          <Box>
            {totalCount}
          </Box>
        </Center>
      </Stack>
    </WrapItem>
  )
}

export { TvGroupStatus }