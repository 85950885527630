import { Required } from 'utility-types'
import { DataNode, EventDataNode } from "rc-tree/lib/interface"
import { CellDto } from "../api/graphql/cell"
import { ShopDto } from "../api/graphql/shop"
import { RtiMcDto } from "../api/graphql/rti-mc"
import TreeModel from "tree-model"
import { ChannelDto } from "api/graphql/channel/types"
import _ from "lodash"
import { FlagTwoTone } from "@ant-design/icons"

type TreeNodeType = "channel" | "cell" | "shop"

interface _TreeNode {
  id: string
  name: string
  type: TreeNodeType
  checked: boolean
  disabled: boolean
  parent: _TreeNode | undefined
  children: _TreeNode[]
}

interface DistTargetInput {
  channelId: string
  cellIds?: string[]
  shopIds?: string[]
}

type TreeNode = TreeModel.Node<TreeNode>

export interface CheckInfo {
  node: Required<Partial<EventDataNode>, 'key'>
  checked: boolean
  checkedNodes: DataNode[]
}

export class DistManager {
  constructor(private readonly channelId: string, private readonly tree?: TreeNode) {
  }

  selectAll({ checked }: { checked: boolean }): DistManager {
    const root: TreeNode | undefined = this.getRoot()
    if (root) {
      root.model.checked = checked

      root.walk(n => {
        n.model.checked = checked
        return true
      })
    }

    return new DistManager(this.channelId, this.tree)
  }

  isSelectAll(): boolean {
    const root = this.getRoot()
    if (root) {
      if (root.model.checked) {
        return true
      } else {
        if (root.hasChildren()) {
          const node = root.first(n => 
            (n.model.type === "cell" || n.model.type === "shop") && 
              n.model.checked === false
          )
          if (node) {
            return false
          } else {
            return true
          }
        } else {
          return false
        }
      }
    } else {
      return false
    }
  }

  addCheckedInfo(info: CheckInfo): DistManager {
    const root = this.getRoot()!

    if (this.isCellSelected(info.node)) {
      const cellId = info.node.key as string

      const cell = root.first(n => n.model.type === "cell" && n.hasChildren() && n.model.id === cellId)!
      if (cell) {
        cell.model.checked = info.checked
        cell.walk(c => {
          if (c.model.type === "shop") {
            c.model.checked = info.checked
          }

          return true
        })
      }
    } else {
      const shopId = info.node.key as string

      const shop = root.first(n => n.model.type === "shop" && n.model.id === shopId)!
      if (shop) {
        shop.model.checked = info.checked

        if (info.checked) {
          if (info.checkedNodes.find(n => n.key === shop.model.parent.id)) {
            shop.model.parent.checked = true
          }
        } else {
          shop.model.parent.checked = false
        }
      }
    }

    if (info.checked === false) {
      root.model.checked = false
    }

    return new DistManager(this.channelId, this.tree)
  }

  getTreeData(): DataNode[] {
    if (this.tree) {
      const dataNodeList: DataNode[] = []

      this.tree.walk((node: TreeNode) => {
        if (node.model.type === 'cell') {
          const cellChildren = node.children.map((child: TreeNode) => ({
            title: child.model.name,
            key: child.model.id,
            disabled: child.model.disabled,
            //disableCheckbox: node.model.checked,
            isLeaf: true,
            icon: child.model.disabled ? 
              <FlagTwoTone twoToneColor="red" style={{ fontSize: "14px" }} />
              : null
          }))

          dataNodeList.push({
            title: node.model.name,
            key: node.model.id,
            children: cellChildren
          })
        }

        return true
      })

      return dataNodeList
    } else {
      return []
    }
  }

  getCheckedKeys(): string[] {
    const ids: string[] = []
    this.tree?.walk((node: TreeNode) => {
      //if (node.model.type === "shop" && node.model.checked) {
      if (node.model.checked) {
        ids.push(node.model.id)
      }

      return true
    })

    return ids
  }

  hasAnyChecked(): boolean {
    const node = this.tree?.first(n => n.model.checked)
    if (node) {
      return node.model.checked
    }

    return false
  }

  getShopCount(): number {
    return this.tree?.all(n => n.model.type === 'shop').length ?? 0
  }

  getCheckedShopCount(): number {
    return this.tree?.all(
      n => n.model.type === 'shop' && n.model.checked && !n.model.disabled
    ).length ?? 0
  }

  private isCellSelected(node: DataNode): boolean {
    return !node.isLeaf
  }

  private getRoot(): TreeNode | undefined {
    return this.tree?.first(n => n.isRoot())
  }

  static constructTree(channel: ChannelDto, cells: CellDto[],
    shops: ShopDto[], rtiMcs: RtiMcDto[]): TreeNode
  {
    const tree = new TreeModel()
    const root = tree.parse<_TreeNode>({
      id: channel.id,
      name: channel.name,
      type: "channel",
      checked: false,
      disabled: false,
      parent: undefined,
      children: []
    })

    for (const cell of cells) {
      const nodeCell: _TreeNode = {
        id: cell.id,
        name: cell.name,
        checked: false,
        disabled: false,
        type: "cell",
        parent: undefined,
        children: [],
      }
      
      nodeCell.children = shops.filter(shop => shop.cell.id === cell.id).map(shop => ({
        id: shop.id,
        name: shop.name,
        checked: false,
        disabled: rtiMcs.findIndex(rtiMc => 
          rtiMc.mediacenter.shop.id === shop.id && rtiMc.isManual) !== -1,
        type: "shop",
        parent: nodeCell,
        children: []
      }))

      if (_.isEmpty(nodeCell.children)) {
        continue
      }

      root.addChild(tree.parse(nodeCell))
    }

    return root
  }

  static mergeAll(entries: IterableIterator<[string, DistManager]>): DistTargetInput[] {
    return Array
      .from(entries)
      .filter(([channelId, distManager]) => distManager.hasAnyChecked())
      .map(([channelId, distManager]) => {
        if (distManager.isSelectAll()) {
          return {
            channelId
          }
        } else {
          const distTarget: DistTargetInput = {channelId: distManager.channelId, cellIds: [], shopIds: []}
          distManager.tree?.walk(n => {
            if (n.model.checked && !n.model.disabled) {
              if (n.model.type === "cell") {
                if (n.children.every(c => c.model.checked || c.model.disabled)) {
                  distTarget.cellIds!.push(n.model.id)
                }
              } else {
                if (!distTarget.cellIds!.includes(n.model.parent.id)) {
                  distTarget.shopIds!.push(n.model.id)
                }
              }
            }

            return true
          })

          return distTarget
        }
      })
  }
}
