import {gql} from "@apollo/client"

export const ADD_MEDIACENTER = gql`
    mutation AddMediacenter(
        $mediacenterInput: MediacenterInput!
    ) {
        addMediacenter(
            mediacenterInput: $mediacenterInput
        ) {
            id
            mac
        }
    }
`

export const UPDATE_MEDIACENTER = gql`
    mutation UpdateMediacenter(
        $id: String!,
        $name: String!,
        $mac: String!,
        $mode: Int,
        $shopId: String!,
        $serial: String,
        $desc: String,
    ) {
        updateMediacenter(
            id: $id
            name: $name
            mac: $mac
            mode: $mode
            shopId: $shopId
            serial: $serial
            desc: $desc
        ) {
            mac
        }
    }
`

export const REMOVE_MEDIACENTER = gql`
    mutation RemoveMediacenter(
        $id: ID!
    ) {
        removeMediacenter(
            id: $id
        )
    }
`

export const REBOOT_MEDIACENTER = gql`
    mutation RebootMediacenter(
        $id: ID!
    ) {
        rebootMediacenter(
            id: $id
        )
    }
`

export const CLEAR_MEDIACENTER_TV_INFOS = gql`
    mutation ClearMediacenterTvInfos(
        $id: ID!
    ) {
        clearMediacenterTvInfos(
            id: $id
        )
    }
`

export const SEND_TEXT_TO_MEDIACENTER = gql`
    mutation SendTextToMediacenter(
        $id: ID!
        $text: String!
    ) {
        sendTextToMediacenter(
            id: $id
            text: $text
        )
    }
`
