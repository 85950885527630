import React from 'react'
import { Drawer, Image, notification, Typography, Row, Col, Card, Button } from 'antd'
import { useTableContext } from "../../contexts/TableContextProvider"
import { MediaDto } from "../../api/graphql/media"
import { REMOVE_MEDIA, GENERATE_MEDIA_THUMBNAIL } from "../../api/graphql/media"
import { Box, Stack, Center } from "@chakra-ui/layout"
import Descriptions from "antd/lib/descriptions"
import format from "date-fns/format"
import DatePicker from "antd/lib/date-picker"
import moment from 'antd/node_modules/moment'
import { toMegabytes } from "../../utils"
import DrawerFooter from "../../components/common/drawer-footer"
import { useMutation } from "@apollo/client"
import { getResponsiveDrawerWidth } from 'common/layouts'
import { urlJoin } from '../../utils/url'
import { fallback } from '../../common/images/fallback'
import { PictureOutlined } from '@ant-design/icons'


//const {Title} = Typography

export const DetailDrawerId = 'detail'

const MediaDetail: React.FC = () => {
  const { drawerState, setDrawerState } = useTableContext()
  const media: MediaDto = drawerState!.tableRow?.data
  const { open } = drawerState!

  const [removeMedia] = useMutation(REMOVE_MEDIA)
  const [generateMediaThumbnail] = useMutation(GENERATE_MEDIA_THUMBNAIL)

  function handleClose() {
    setDrawerState!({ open: { status: false }})
  }

  function getDuration() {
    const duration = media?.duration ?? 0

    const hours = Math.floor(duration / 3600)
    const minutes = Math.floor(duration % 3600 / 60)
    const seconds = Math.floor(duration % 3600 % 60)

    const addZero = (n: number) => n < 10 ? `0${n}` : n

    return [addZero(hours), addZero(minutes), addZero(seconds)].join(':')
  }

  const onMediaUpdate = () => {
    console.log('do something')
  }

  const onMediaRemove = () => {
    removeMedia({ variables: { id: media.id }})
      .then(() => {
        notification.success({
          message: `성공적으로 컨텐츠를 제거했습니다.`
        })

        setDrawerState!({ open: { status: false }})
      })
      .catch((/*e*/) => {
        notification.error({
          message: `컨텐츠를 제거 중에 에러가 발생했습니다.`
        })
      })
  }

  const onGenerateMediaThumbnail = () => {
    generateMediaThumbnail({ variables: { id: media.id }})
      .then(() => {
        notification.success({
          message: `미디어의 썸네일을 성공적으로 생성하였습니다..`
        })
      })
      .catch((/*e*/) => {
        notification.error({
          message: `미디어 썸네일 생성 중 에러가 발생하였습니다.`
        })
      })
  }

  return (
    <Drawer
      title="컨텐츠 상세 정보"
      width={getResponsiveDrawerWidth(400)}
      placement="right"
      closable
      onClose={handleClose}
      visible={open.status && open.id === DetailDrawerId}
      footer={
        <DrawerFooter
          formId="media"
          close={handleClose}
          isNew={false}
          onUpdate={onMediaUpdate}
          onRemove={onMediaRemove}
        />
      }
    >
      <Row align="middle" style={{ border: "solid 1px #808080", background: "#e6f7ff", padding: "4px", borderRadius: "4px" }}>
        <Col offset={1} span={23}>
          <Typography.Text
            style={{fontSize: '1rem', marginRight: '6px', width: "100%", wordBreak: "break-all" }}
            strong
            //ellipsis
          >
            {media?.filename}
          </Typography.Text>
        </Col>
      </Row>
      <Stack w="100%">
        <Card
          style={{ 
            width: "100%",
            border: "0.5px solid #e0e0e0"
          }}
          bordered={false}
          hoverable
          cover={
            <Center w="100%">
              <Image
                width="100%"//{ isMobile ? 64 : 128 }
                // height="100%"//{ isMobile ? 36 : 72 }
                preview={false}
                src={urlJoin(process.env.REACT_APP_THUMBNAIL_URL, media?.thumbnail) +
                  `?${new Date(media?.updatedAt).getTime()}`}
                fallback={fallback}
                style={{ border: "1px solid #a0a0a0", aspectRatio: "16/9" }}
              />
            </Center>
          }
          actions={[
            <Button key="generate-thumbnail"
              type="primary"
              icon={<PictureOutlined />}
              size='small'
              //style={{width: '90%'}}
              onClick={onGenerateMediaThumbnail}
            >
            </Button>
          ]}
          bodyStyle={{ margin:0, padding: 0, paddingLeft: 4, paddingRight: 4 }}
        >
        </Card>

        <Box mt={8}>
          <Descriptions bordered size="small" contentStyle={{ wordBreak: "break-word" }}>
            <Descriptions.Item label="사용 기한" span={3}>
              {<DatePicker {...(media?.licenseEnd && {defaultValue: moment(media?.licenseEnd)})} />}
            </Descriptions.Item>

            <Descriptions.Item label="해상도" span={3}>
              {media?.width && media?.height ? media?.width + ' X ' + media?.height : ''}
            </Descriptions.Item>
            <Descriptions.Item label="비디오" span={3}>
              {media?.videoCodec}
            </Descriptions.Item>
            <Descriptions.Item label="오디오" span={3}>
              {media?.audioCodec}
            </Descriptions.Item>
            <Descriptions.Item label="비트레이트" span={3}>
              {media?.bitrate && toMegabytes(media.bitrate)}mb/s
            </Descriptions.Item>
            <Descriptions.Item label="파일 크기" span={3}>
              {media?.size && toMegabytes(media.size)}MB
            </Descriptions.Item>
            <Descriptions.Item label="재생 시간" span={3}>
              {getDuration()}
            </Descriptions.Item>
            <Descriptions.Item label="등록 시각" span={3}>
              {media?.createdAt && format(new Date(media.createdAt), "yyyy/MM/dd HH:mm:ss")}
            </Descriptions.Item>
            <Descriptions.Item label="해시값" span={3} 
              contentStyle={{ fontSize: 12 }}
            >
              {media?.hash}
            </Descriptions.Item>
          </Descriptions>
        </Box>
      </Stack>
    </Drawer>
  )
}

export { MediaDetail }
