import {gql} from '@apollo/client'
import {pairingFragment} from "../pairing"

export const GET_DISTS = gql`
    query GetDists {
        dists {
            id
            name
            reserveAt
            appliedAt
            pairing {
                ...pairingParts
            }
            desc
            targets {
                channel {
                    id
                    name
                }
                cell {
                    id
                    name
                }
                shop {
                    id
                    name
                }
            }
            createdAt
            updatedAt
        }
    }
    ${pairingFragment}
`

export const SUBSCRIBE_DIST = gql`
    subscription {
        subscribeDist {
            op
            dist {
                id
                name
                pairing {
                    id
                    name
                }
                desc
                targets {
                    channel {
                        id
                        name
                    }
                    cell {
                        id
                        name
                    }
                    shop {
                        id
                        name
                    }
                }
                createdAt
                reserveAt
                appliedAt
                updatedAt
            }
        }
    }
`
