import React, { useEffect, useState } from 'react'
import {
  Drawer, Form, Input, notification, Row, Col,
} from 'antd'
import { VStack, Spacer } from '@chakra-ui/layout'
import { useTableContext } from '../../contexts/TableContextProvider'
import { useMutation } from '@apollo/client'
import { ADD_PAIRING, REMOVE_PAIRING, UPDATE_PAIRING } from '../../api/graphql/pairing/mutations'
import { Group, GroupManager } from '../../models'
import useGroups from '../../hooks/useGroups'
import { getResponsiveDrawerWidth } from 'common/layouts'
import DrawerFooter from '../../components/common/drawer-footer'
import { PairingEditGroupList } from './edit-group-list'
import { PairingEditGroupMediaList } from './edit-group-medialist'

export const PairingDrawerId = 'pairing'

interface Props {
  editable: boolean
}

const PairingEdit: React.FC<Props> = ({ editable }) => {
  const { drawerState, closeDrawer } = useTableContext()
  const visible = drawerState.open.id === PairingDrawerId && drawerState.open.status

  const onClose = () => closeDrawer()

  const { groups } = useGroups()

  const [addPairing] = useMutation(ADD_PAIRING)
  const [updatePairing] = useMutation(UPDATE_PAIRING)
  const [removePairing] = useMutation(REMOVE_PAIRING)

  const [groupManager, setGroupManager] = useState<GroupManager>(new GroupManager([]))
  const [form] = Form.useForm()

  useEffect(() => {
    const isPairingDrawerOpen = drawerState.open.id === PairingDrawerId && drawerState.open.status

    if (groups.length > 0 && isPairingDrawerOpen) {
      const selectedGroups: Group[] = drawerState?.tableRow?.data.playlists?.map(playlist => ({
        id: playlist.group.id,
        creatable: true,// playlist.playlistMedias.length > 0,
        playlist: [...playlist.playlistMedias].sort((p1, p2) => (p1.order > p2.order) ? 1 : -1).map(p => p.media)
      })) ?? []

      let selectedGroupId = ''
      const _groups: Group[] = groups.map(group => {
        const selected = selectedGroups.find(selectedGroup => selectedGroup.id === group.id)

        if (selected && selectedGroupId === '') {
          selectedGroupId = selected.id
        }

        return {
          id: group.id,
          name: group.name,
          playlist: selected?.playlist ?? [],
          creatable: selected?.creatable ?? false,
        }
      })

      const groupManager = new GroupManager(_groups, selectedGroupId/*_groups[0].id*/)
      setGroupManager(groupManager)

      if (drawerState.tableRow) {
        form.setFieldsValue({id: drawerState.tableRow.data.id, name: drawerState.tableRow.data.name})
      } else {
        form.resetFields()
      }
    } else {
      form.resetFields()
      setGroupManager(new GroupManager([]))
    }
  }, [groups, drawerState])

  function handlePairingAdd() {
    const name = form.getFieldValue('name')

    const pairing = {
      variables: {
        pairingInput: {
          name,
          playlists: groupManager.creatableGroups.map(group => {
            return {
              groupId: group.id,
              mediaIds: group.playlist.map(media => media.id)
            }
          })
        }
      }
    }

    addPairing(pairing)
      .then(() => {
        setGroupManager(new GroupManager([]))
        form.resetFields()

        notification.success({
          message: `성공적으로 편성표를 생성했습니다.`
        })

        onClose()
      })
      .catch(e => {
        notification.error({
          message: `편성표 생성 중에 에러가 발생했습니다.`
        })

        console.error(e)
      })
  }

  function handlePairingUpdate() {
    const name = form.getFieldValue('name')
    const id = form.getFieldValue('id')

    const pairing = {
      variables: {
        pairingInput: {
          id,
          name,
          playlists: groupManager.creatableGroups.map(group => {
            return {
              groupId: group.id,
              mediaIds: group.playlist.map(media => media.id)
            }
          })
        }
      }
    }

    console.log(pairing)
    
    updatePairing(pairing)
      .then(() => {
        setGroupManager(new GroupManager([]))
        form.resetFields()
        notification.success({
          message: `성공적으로 편성표를 업데이트했습니다.`
        })

        onClose()
      })
      .catch(e => {
        notification.error({
          message: `편성표 업데이트 중에 에러가 발생했습니다.`
        })

        console.error(e)
      })
  }

  function handlePairingRemove() {
    const id = form.getFieldValue('id')

    removePairing({variables: {id}})
      .then(() => {
        setGroupManager(new GroupManager([]))
        form.resetFields()
        notification.success({
          message: `성공적으로 편성표를 제거했습니다.`
        })

        onClose()
      })
      .catch(e => {
        notification.error({
          message: `편성표 제거 중에 에러가 발생했습니다.`
        })

        console.log(e)
      })
  }

  const onGroupAdded = (groupId: string) => {
    setGroupManager(groupManager.setCreatable(groupId, true))
  }

  const onGroupSelected = (groupId: string) => {
    setGroupManager(groupManager.select(groupId))
  }

  const onGroupRemoved = (groupId: string) => {
    setGroupManager(groupManager.setCreatable(groupId, false))
  }

  const onGroupManagerUpdated = (newGroupManager: GroupManager) => {
    setGroupManager(newGroupManager)
  }

  return (
    <Drawer
      title={ editable ? "편성표 작성" : "편성표 정보" }
      width={getResponsiveDrawerWidth(800)}
      placement="right"
      closable
      onClose={onClose}
      visible={visible}
      footer={
        editable && (
          <DrawerFooter
            formId="formAddPairing"
            close={onClose}
            isNew={!form.getFieldValue('id')}
            onUpdate={handlePairingUpdate}
            onRemove={handlePairingRemove}
          />
        )
      }
    >
      <VStack w="100%" h="100%" align="left">
        <Form
          id="formAddPairing"
          layout="vertical"
          labelAlign="left"
          form={form}
          onFinish={handlePairingAdd}
        >
          <Form.Item name="id" hidden>
          </Form.Item>

          <Form.Item
            name="name"
            label="편성표 이름"
            rules={[{required: true, message: '편성표 이름을 입력해 주세요.'}]}
          >
            <Input disabled={!editable} placeholder="편성표 이름을 입력해 주세요."/>
          </Form.Item>
        </Form>

        <Spacer>
          <div>
            <h4>
              그룹 별 플레이리스트
            </h4>
          </div>
          <div style={{ width: "100%", height: "96%" }}>
            <Row style={{ 
              width: "100%",
              height: "100%",
              border: "1px solid #d0d0d0",
            }}>
              <Col span={8} style={{ padding: 8 }}>
                <PairingEditGroupList
                  groups={groups}
                  groupManager={groupManager}
                  onGroupAdded={onGroupAdded}
                  onGroupSelected={onGroupSelected}
                  onGroupRemoved={onGroupRemoved}
                />
              </Col>
              <Col span={16} style={{ padding: 8 }}>
                <PairingEditGroupMediaList
                  editable={editable}
                  groupManager={groupManager}
                  onGroupManagerUpdated={onGroupManagerUpdated}
                />
              </Col>
            </Row>
          </div>
        </Spacer>
        
      </VStack>
    </Drawer>
  )    
}

export { PairingEdit }
