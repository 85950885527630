import React from 'react'
import { RegionList } from './region'
import { Stack } from "@chakra-ui/layout"
import useRegions from "../hooks/useRegions"

function PageBasedataRegion() {
  const { allRegions, regions } = useRegions()

  return (
    <Stack>
      <RegionList regions={regions}/>
    </Stack>
  )
}

export { PageBasedataRegion }
