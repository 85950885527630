import React from "react"
import { SearchOutlined, ThunderboltFilled } from '@ant-design/icons'
import { AlignType } from 'rc-table/lib/interface'

export const columnChannelName = (
  dataIndex: string | string[],
  sorter: (e1, e2) => number,
  onFilter: (value, e1) => boolean,
  filters: { text: string, value: string}[]
) => ({
  title: '판매 경로',
  dataIndex: dataIndex,
  key: 'channelName',
  width: 150,
  align: 'center' as AlignType,
  render: (text, record) =>
    <span>{text}</span>,
  sorter: sorter,
  showSorterTooltip: false,

  filterSearch: true,

  onFilter: onFilter,

  filters: filters,

  filterIcon: filtered => (
    filtered ? <ThunderboltFilled style={{ color: "#ffa940", fontSize: 16 }}/> : 
      <SearchOutlined />
  ),

})