import useDtos, {LoadingAndError} from "./useDtos"
import {afterProvided} from "../utils"
import {DistDto, Dists, GET_DISTS, SUBSCRIBE_DIST} from "../api/graphql/dist"

interface Result extends LoadingAndError {
    allDists: DistDto[];
}

const useDists = (): Result => {
  const {allDtos: allDists, loading, error} = useDtos<DistDto, Dists>({
    query: GET_DISTS,
    queryResultKey: 'dists',
    subscription: SUBSCRIBE_DIST,
    updateQueryFunction: afterProvided({
      queryResultKey: 'dists',
      subscriptionResultKey: 'subscribeDist',
      dataKey: 'dist'
    })
  })

  return {allDists, loading, error}
}

export default useDists
