import React, { useState, useMemo } from 'react'
import { Box, } from '@chakra-ui/layout'
import { Modal, Table, Tag } from 'antd'
import { useQuery } from "@apollo/client"
import {RtiMcDto, McState} from 'api/graphql/rti-mc'
import {LogMcs, LogMcDto } from "../../api/graphql/log/types"
import {ColumnsType} from 'antd/es/table'
import {format} from "date-fns"
import { getResponsiveModalWidth } from 'common/layouts'
import {GET_LOG_MCS} from 'api/graphql/log/queries'
import {GET_GROUPS, Groups} from 'api/graphql/group'

interface Props {
  rtiMc: RtiMcDto
  visible: boolean
  onClose: () => void
}

const MclogModal: React.FC<Props> = ({ rtiMc, visible, onClose }) => {
  const {data: dataGroups} = useQuery<Groups>(GET_GROUPS)

  const prev7Days = new Date()
  prev7Days.setDate(prev7Days.getDate()-7)

  const [logMcInput, setlogMcInput] = useState({
    id: rtiMc.id,
    start: prev7Days,
  })

  const { data: dataLogMcs } = useQuery<LogMcs>(GET_LOG_MCS, {
    fetchPolicy: 'no-cache',
    variables: {
      logMcInput
    }
  })

  React.useEffect(() => {
    const timer = setInterval(() => {
      const prev7DaysFromNow = new Date() 
      prev7DaysFromNow.setDate(prev7DaysFromNow.getDate()-7)
  
      setlogMcInput({
        id: rtiMc.id,
        start: prev7DaysFromNow
      })
    }, 1000 * 60)
    
    return function cleanup() {
      clearInterval(timer)
    }
  }, [rtiMc])

  const columns = useMemo<ColumnsType<LogMcDto>>(() => [
    {
      title: '시각',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      align: 'left',
      showSorterTooltip: false,
      fixed: 'left',
      render: (createdAt) => <span>{format(new Date(createdAt), "MM/dd HH:mm:ss")}</span>,
    },
    {
      title: '상태',
      dataIndex: 'state',
      key: 'state',
      width: 80,
      align: 'center',
      fixed: 'left',
      render: state => {
        if (state === McState.ONLINE) {
          return <Tag color='#52c41a'>접속</Tag>
        } else {
          return <Tag color='#bfbfbf'>미접속</Tag>
        }
      },
      //sorter: (rtiMc1, rtiMc2) => sortByNumber(rtiMc1.state, rtiMc2.state),
      showSorterTooltip: false,
    },
    {
      title: '접속률',
      // dataIndex: 'state',
      key: 'rate',
      width: 60,
      align: 'center',
      fixed: 'left',
      render: (text, record) =>
        record.totalCount === 0 ? '0 %' :
          `${Math.round(record.onlineCount * 100 / record.totalCount)} %`,
      //sorter: (rtiMc1, rtiMc2) => sortByNumber(rtiMc1.state, rtiMc2.state),
      showSorterTooltip: false,
    },
    {
      title: 'TV대수',
      // dataIndex: 'state',
      key: 'count',
      width: 80,
      align: 'center',
      fixed: 'left',
      render: (text, record) => 
        <Box>
          <span style={{ display: "inline-block", width: 20, textAlign: "right" }}>{record.onlineCount}</span>
          <span style={{ display: "inline-block", width: 20, textAlign: "center" }}> / </span>
          <span style={{ display: "inline-block", width: 20, textAlign: "left" }}>{record.totalCount}</span>
        </Box>,
      /*
        <span> / </span>
        <span style={{ width: 20 }}>{record.totalCount}</span>,
        */
      //sorter: (rtiMc1, rtiMc2) => sortByNumber(rtiMc1.state, rtiMc2.state),
      showSorterTooltip: false,
    },

  ], [dataLogMcs])

  if (dataGroups && dataLogMcs) {
    if (dataLogMcs.logMcs.length > 0) {
      const log = dataLogMcs.logMcs[0]

      log.groupTvData?.forEach((tvData/*, index*/) => {
        const group = dataGroups.groups.find(g => g.id === tvData.groupId)
        if (group) {
          columns.push({
            title: <div style={{ whiteSpace: "nowrap" }}>{group?.name}</div>,// <span></span>,
            // dataIndex: 'createdAt',
            key: group?.id,
            // width: 120,
            width: group?.name.length <= 3 ? 80 : undefined,
            align: "center",
            showSorterTooltip: false,
            render: (text, record) => {
              const groupTvData = record.groupTvData?.find(data => data.groupId === group?.id)
              const totalCount = groupTvData ? groupTvData.onlineCount + 
                groupTvData.offlineCount + groupTvData.errorCount : 0
              const onlineCount = groupTvData ? groupTvData.onlineCount : 0
              return (
                <Box w="100%" textAlign="center">
                  <span style={{ display: "inline-block", width: 16, textAlign: "right" }}>{onlineCount}</span>
                  <span style={{ display: "inline-block", width: 16, textAlign: "center" }}> / </span>
                  <span style={{ display: "inline-block", width: 16, textAlign: "left" }}>{totalCount}</span>
                </Box>
              )
            }
          })
        }
      })

      columns.push({
        title: <div></div>,// <span></span>,
        key: 'spaceForLast',
        width: "100%",
        align: "center",
        showSorterTooltip: false,
        render: (/*text, record*/) => <div></div>
      })
    }
  }
 
  return (
    <Modal
      title="미디어센터 접속 로그"
      width={ getResponsiveModalWidth(800) }
      okText="닫기"
      onOk={onClose}
      cancelButtonProps={{ style: { display: 'none' }}}
      onCancel={onClose}
      visible={visible}
      bodyStyle={{ overflow: "auto" }}
      style={{ height: 500 }}
    >
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={dataLogMcs?.logMcs}
        style={{ width: "100%", height: 500 }}
        scroll={{ x: true/*1000*//*, y: 10000*/  }}
      />
    </Modal>
  )

}

export { MclogModal }