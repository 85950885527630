
import { MediacenterDto } from 'api/graphql/mediacenter'

export enum TvState {
  UNKNOWN     = 0,
  ONLINE      = 1,
  OFFLINE     = 2,
  ERROR       = 3,
}

export enum McState {
  UNKNOWN     = 0,
  ONLINE      = 1,
  OFFLINE     = 2,
  ERROR       = 3,
}

export type RtiMcTv = {
  id: string
  serial: string
  state: number
}

export type RtiMcGroup = {
  id: string
  tvs: RtiMcTv[]
  totalCount: number
  unknownCount: number
  onlineCount: number
  offlineCount: number
  errorCount: number
}

export interface RtiMcMedia {
  assignedCount: number
  confirmedCount: number
  assignedAt: number
  confirmedAt: number
  assignedMediaIds: string[]
  confirmedMediaIds: string[]
}

export type RtiMcDto = {
  id: string
  state: McState
  mediacenter: MediacenterDto
  isManual: boolean
  pairingId: string
  distId: string
  groups: RtiMcGroup[]
  media?: RtiMcMedia
  //createdAt: number
  isDebug: boolean
  updatedAt: number
}

export type RtiMcs = {
  rtiMcs: RtiMcDto[]
}
