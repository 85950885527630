import useDtos, {DtoFilter, LoadingAndError} from "./useDtos"
import {afterProvided} from "../utils"
import {GET_RTI_MCS, SUBSCRIBE_RTI_MC} from "api/graphql/rti-mc/queries"
import {RtiMcDto, RtiMcs} from "../api/graphql/rti-mc"
import React from "react"

interface Result extends LoadingAndError {
    rtiMcs: RtiMcDto[];
    allRtiMcs: RtiMcDto[];
    setFilter: React.Dispatch<React.SetStateAction<DtoFilter<RtiMcDto>>>;
}

const useRtiMcs = (): Result => {
  const {allDtos: allRtiMcs, setFilter, dtos: rtiMcs, loading, error} = useDtos<RtiMcDto, RtiMcs>({
    query: GET_RTI_MCS,
    queryResultKey: 'rtiMcs',
    subscription: SUBSCRIBE_RTI_MC,
    updateQueryFunction: afterProvided({
      queryResultKey: 'rtiMcs',
      subscriptionResultKey: 'subscribeRtiMc',
      dataKey: 'rtiMc'
    })
  })

  return {rtiMcs, allRtiMcs, setFilter, loading, error}
}

export default useRtiMcs
