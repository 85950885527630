export enum AuthLoginStatus {
    LOGGING_IN = 'LOGGING_IN',
    LOGGED_IN = 'LOGGED_IN',
    LOGGING_OUT = 'LOGGING_OUT',
    LOGGED_OUT = 'LOGGED_OUT',
    LOGIN_FAILED = 'LOGIN_FAILED'
}

export type AuthState = {
    password: string;
    status: AuthLoginStatus;
    token: string;
    level: number;
    message: string;
};

export type AuthLoginRequest = {
    userid: string;
    password: string;
};

export type AuthLoginSuccess = {
    userid: string;
    token: string;
    accountLevel: AuthorizationLevel;
};

export enum AuthorizationLevel {
    ADMIN = 0,
    OPERATOR = 1,
    USER = 2,
    VISITOR = 3,
}
