import { Stack } from '@chakra-ui/layout'
import React from 'react'
import { UpdatePairingList } from './pairing'
import Typography from 'antd/lib/typography'
import { Divider } from 'antd'
import { TableContextProvider} from '../contexts/TableContextProvider'

function PageUpdatePairing() {
  return (
    <TableContextProvider>
      <Stack>
        <Stack spacing={16}>
          <Typography.Title level={3}>
            편성표 관리
          </Typography.Title>
          <Divider />
        </Stack>

        <UpdatePairingList />

      </Stack>
    </TableContextProvider>
  )
}


export { PageUpdatePairing }
