import React from 'react'
import BaseDataCard from "./basedata/basedata-card"
import {ADD_CELL, REMOVE_CELL, UPDATE_CELL} from "../api/graphql/cell/mutations"
import Select from 'antd/lib/select'
import {GET_REGIONS, RegionDto, Regions, SUBSCRIBE_REGION} from "../api/graphql/region"
import {Division, Divisions, GET_DIVISIONS, SUBSCRIBE_DIVISION} from "../api/graphql/division"
import {ADD_DIVISION, REMOVE_DIVISION, UPDATE_DIVISION} from "../api/graphql/division/mutations"
import {Box, Stack} from '@chakra-ui/layout'
import {ADD_CHANNEL, REMOVE_CHANNEL, UPDATE_CHANNEL} from "../api/graphql/channel/mutations"
import {ADD_REGION, REMOVE_REGION, UPDATE_REGION} from "../api/graphql/region/mutations"
import {Amcode, Amcodes, GET_AMCODES, SUBSCRIBE_AMCODE} from "../api/graphql/amcode"
import {ADD_AMCODE, REMOVE_AMCODE, UPDATE_AMCODE} from "../api/graphql/amcode/mutations"
import Typography from "antd/lib/typography"
import {Divider} from "antd"
import './page-basedata.css'
import useCells from "../hooks/useCells"
import useChannels from "../hooks/useChannels"
import useDtos from "../hooks/useDtos"
import {afterProvided} from "../utils"

function PageBasedata() {
  const {cells} = useCells()
  const {channels} = useChannels()

  const {allDtos: regions} = useDtos<RegionDto, Regions>({
    query: GET_REGIONS,
    queryResultKey: 'regions',
    subscription: SUBSCRIBE_REGION,
    updateQueryFunction: afterProvided({
      queryResultKey: 'regions',
      subscriptionResultKey: 'subscribeRegion',
      dataKey: 'region'
    })
  })
  const {allDtos: divisions} = useDtos<Division, Divisions>({
    query: GET_DIVISIONS,
    queryResultKey: 'divisions',
    subscription: SUBSCRIBE_DIVISION,
    updateQueryFunction: afterProvided({
      queryResultKey: 'divisions',
      subscriptionResultKey: 'subscribeDivision',
      dataKey: 'division'
    })
  })
  const {allDtos: amcodes} = useDtos<Amcode, Amcodes>({
    query: GET_AMCODES,
    queryResultKey: 'amcodes',
    subscription: SUBSCRIBE_AMCODE,
    updateQueryFunction: afterProvided({
      queryResultKey: 'amcodes',
      subscriptionResultKey: 'subscribeAmcode',
      dataKey: 'amcode'
    })
  })

  const regionDropboxElements = regions.map(region => (
    <Select.Option key={region.id} value={region.id}>{region.name}</Select.Option>))
  const divisionDropboxElements = divisions.map(division => (
    <Select.Option key={division.id} value={division.id}>{division.name}</Select.Option>))

  return (
    <Stack>
      <Typography.Title level={3}>
                기초 데이터 관리
      </Typography.Title>

      <Divider/>

      <Box className="container">
        <Box gridArea="division">
          <BaseDataCard
            namePlaceholder="예) 전략, 전시"
            data={divisions}
            type="division"
            title="매장 분류"
            mutations={{
              addMutation: ADD_DIVISION,
              updateMutation: UPDATE_DIVISION,
              removeMutation: REMOVE_DIVISION,
            }}
          />
        </Box>
        <Box gridArea="channel">
          <BaseDataCard
            namePlaceholder="예) 이마트, 백화점"
            data={channels}
            type="channel"
            title="판매 경로"
            mutations={{
              addMutation: ADD_CHANNEL,
              updateMutation: UPDATE_CHANNEL,
              removeMutation: REMOVE_CHANNEL,
            }}
            dropbox={{
              elements: divisionDropboxElements,
              elementKey: 'division',
            }}
          />
        </Box>
        <Box gridArea="region">
          <BaseDataCard
            namePlaceholder="예) 서울, 경기"
            data={regions}
            type="region"
            title="지역"
            mutations={{
              addMutation: ADD_REGION,
              updateMutation: UPDATE_REGION,
              removeMutation: REMOVE_REGION,
            }}
          />
        </Box>
        <Box gridArea="cell">
          <BaseDataCard
            namePlaceholder="예) 서울동부"
            data={cells}
            type="cell"
            title="지역 셀"
            mutations={{
              addMutation: ADD_CELL,
              updateMutation: UPDATE_CELL,
              removeMutation: REMOVE_CELL,
            }}
            dropbox={{
              elementKey: 'region',
              elements: regionDropboxElements
            }}
          />
        </Box>
        <Box gridArea="amcode">
          <BaseDataCard
            namePlaceholder=""
            data={amcodes}
            type="amcode"
            title="AM 코드"
            mutations={{
              addMutation: ADD_AMCODE,
              updateMutation: UPDATE_AMCODE,
              removeMutation: REMOVE_AMCODE,
            }}
          />
        </Box>
      </Box>
    </Stack>
  )
}

export {PageBasedata}
