import {gql} from '@apollo/client'

export const firmwareFragment = gql`
    fragment firmwareParts on Firmware {
        id
        name
        type
        version
        filename
        path
        size
        hash
        desc
        createdAt
        updatedAt
    }
`

export const GET_FIRMWARES = gql`
    query GetFirmwares {
        firmwares {
            ...firmwareParts
        }
    }
    ${firmwareFragment}
`

export const SUBSCRIBE_FIRMWARE = gql`
    subscription {
        subscribeFirmware {
            op
            firmware {
                ...firmwareParts
            }
        }
    }
    ${firmwareFragment}
`
