import React from 'react'
import { Modal, Table } from 'antd'
import { getResponsiveModalWidth } from 'common/layouts'
import {RtiMcDto} from 'api/graphql/rti-mc'
import { TvInfoDto } from 'api/graphql/tvinfo'
import { ColumnsType } from "antd/es/table"

interface Props {
  rtiMc: RtiMcDto
  visible: boolean
  onClose: () => void
}

const RtiShopDetailTvinfos: React.FC<Props> = ({/* rtiMc, */ visible, onClose}) => {

  const tvInfos: TvInfoDto[] = []

  const columns: ColumnsType<TvInfoDto> = [
    {
      title: 'Serial No',
      dataIndex: 'serial',
      key: 'serial',
      width: 120,
      align: 'center',
      showSorterTooltip: false,
    },
  ]

  return (
    <Modal
      title="매장 TV 상세 정보"
      width={ getResponsiveModalWidth(600) }
      cancelText="닫기"
      //onOk={handleMediaAdd}
      onCancel={onClose}
      visible={visible}
    >
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={tvInfos}
        scroll={{ x: 1000/*true*/ }}
      />
            
      {/*selRtiMc && (
                <RtiShopDetail
                    rtiMc={selRtiMc}
                    visible={visible}
                    onClose={() => setVisible(false)}
                />
            )*/}

    </Modal>
  )
}

export { RtiShopDetailTvinfos }
