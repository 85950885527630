import {Box, Stack} from "@chakra-ui/layout"
import React, {FC, useState} from "react"
import {Space, Typography, Progress} from "antd"
import {RtiMcDto} from "../../api/graphql/rti-mc"
import TvConnection from "../../models/tv-connection"
import _ from "lodash"
import Form, {FormInstance} from "antd/lib/form"
import useDtos from "../../hooks/useDtos"
import {CellDto, Cells, GET_CELLS, SUBSCRIBE_CELL} from "../../api/graphql/cell"
import {afterProvided} from "../../utils"

type CellName = "전체 지역" | string;

interface CellCardProps {
  title: CellName
  form: FormInstance
  selected: string
  setSelected: React.Dispatch<React.SetStateAction<string>>
  connectRtiMcsCount: number
  totalRtiMcsCount: number
  connectRtiMcsPercent: number
  style?: { headerColor: string }
}

const CellCard: FC<CellCardProps> = (
  {
    title,
    form,
    selected,
    setSelected,
    //style,
    connectRtiMcsCount,
    connectRtiMcsPercent,
    totalRtiMcsCount,
  }) => {
  /*
  const data = {
    labels: ['접속', '미접속'],
    datasets: [{
      data: [connectRtiMcsPercent, 100 - connectRtiMcsPercent],
      backgroundColor: chartColors,
    }],
    text: `${connectRtiMcsPercent}%`,
  }
  const option = {
    legend: {
      display: false,
    },
    cutoutPercentage: 60,
    tooltips: {
      enabled: false
    },
    plugins: {
      datalabels: {display: false}
    },
  }
  */

  const onClick = () => {
    if (title === "전체 지역") {
      form.setFieldsValue({cellName: undefined})
    } else {
      form.setFieldsValue({cellName: title})
    }

    setSelected(title)
  }

  const isSelected = () => selected === title

  return (
    <Stack
      w={60}
      h={80}
      border={isSelected() ? "0.5px solid #404040" : "0.5px solid #a0a0a0"}
      cursor="pointer"
      onClick={onClick}
      boxShadow={isSelected() ? '0px 0px 4px 2px rgba(32, 32, 32, 0.5)' : 'none' }
    >
      <Box 
        background={isSelected() ? '#69c0ff' : '#e6f7ff'}
        textAlign="center"
        borderBottom={isSelected() ? "0.5px solid #404040" : "0.5px solid #a0a0a0"}
        width="100%"
        height={20}
      >
        <Typography.Text strong style={{color: '#404040'/*'white'*/, fontSize: 12}}>
          {title}
        </Typography.Text>
      </Box>

      <Form.Item name="cellName" hidden>
      </Form.Item>

      <Stack justify="center" align="center" spacing={1}>
        <Box position="relative" alignSelf="center" width={32}>
          <Progress type="circle" width={32} percent={connectRtiMcsPercent}
            strokeWidth={6}
            status='normal'
            format={percent => `${percent}%`}/>
          {/*
                    <Doughnut
                        data={data}
                        options={option}
                        width={36}
                        height={36}
                    />
                    <Box position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" textAlign="center">
                        <Box fontSize="0.6rem">{connectRtiMcsPercent}%</Box>
                    </Box>
                    */}
        </Box>

        <Box fontSize="0.6rem">
          {connectRtiMcsCount} / {totalRtiMcsCount}
        </Box>
      </Stack>

    </Stack>
  )
}


interface Props {
  rtiMcs: RtiMcDto[]
  form: FormInstance
}

const CellsComponent: FC<Props> = ({rtiMcs, form}) => {
  const {allDtos: allCells} = useDtos<CellDto, Cells>({
    query: GET_CELLS,
    queryResultKey: 'cells',
    subscription: SUBSCRIBE_CELL,
    updateQueryFunction: afterProvided({
      queryResultKey: 'cells',
      subscriptionResultKey: 'subscribeCell',
      dataKey: 'cell'
    })
  })

  const cellNames = allCells.map(c => c.name)

  const [selected, setSelected] = useState<CellName>("전체 지역")
  const cells = _.groupBy(rtiMcs, (r) => r.mediacenter.cell.name)

  return (
    <Box display="flex">
      <Box p="8px" boxShadow='0px 0px 8px 3px rgba(192, 192, 192, 0.5)'>
        <CellCard selected={selected} setSelected={setSelected} form={form}
          title="전체 지역" {...TvConnection.getRtiMcStatistics(rtiMcs)} />
      </Box>

      <Box w="100%" p="8px" ml={16} /*border="1px solid black"*/ justify="space-between" overflowX="scroll"
        boxShadow='0px 0px 8px 3px rgba(192, 192, 192, 0.5)'
      >
        <Space>
          {cellNames.map(cellName => (
            <CellCard selected={selected} setSelected={setSelected} form={form} key={cellName}
              style={{headerColor: '#aaa'}}
              title={cellName} {...TvConnection.getRtiMcStatistics(cells[cellName])} />
          ))}
        </Space>
      </Box>
    </Box>
  )
}

export default CellsComponent
