import React, { useRef } from 'react'
import { Button, Space, Table, Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { CloudOutlined } from '@ant-design/icons'
import { PairingDto, Playlist, } from 'api/graphql/pairing'
import { PairingDrawerId, PairingEdit } from './edit'
import { Box, Stack } from '@chakra-ui/layout'
import { useTableContext } from '../../contexts/TableContextProvider'
import usePageSize from '../../hooks/usePageSize'
import usePairing from '../../hooks/usePairing'
import { sortByNumber } from '../../utils'
import { createdAtColumn } from '../../common/created-at-column'
import useRtiMcs from '../../hooks/useRtiMcs'
import { columnText } from '../../components/table'

function UpdatePairingList() {
  const { setDrawerState } = useTableContext()
  const { rtiMcs } = useRtiMcs()
  const { allPairings: pairings } = usePairing()
  const { pageSize, setPageSize } = usePageSize('pairing')

  const columns: ColumnsType<PairingDto> = [
    columnText("편성표 이름", "name", "name", 250, "left",
      { searchInputRef: useRef(null) },
      (pairing) => {
        setDrawerState({
          open: { id: PairingDrawerId, status: true },
          tableRow: { id: pairing.id, data: pairing }
        })
      }
    ),
    {
      title: '적용 그룹',
      dataIndex: 'playlists',
      key: 'groupName',
      render: (playlists: Playlist[]) => {
        const sorted = [...playlists]
        sorted.sort((p1, p2) => (p1.group.name > p2.group.name) ? 1 : -1)
        return sorted.map(playlist => <Tag key={playlist.id}>{playlist.group.name}</Tag>)
      }
    },
    {
      title: '매장 수',
      //dataIndex: 'shopCount',
      key: 'shopCount',
      width: 100,
      render: (_, pairing) => {
        return (
          <span>
            { 
              rtiMcs.filter(rtiMc => rtiMc.pairingId === pairing.id).length
            }
          </span>
        )
      },
      sorter: (pairing1, pairing2) => {
        const shopCount1 = rtiMcs.filter(rtiMc => rtiMc.pairingId === pairing1.id).length
        const shopCount2 = rtiMcs.filter(rtiMc => rtiMc.pairingId === pairing2.id).length
        return sortByNumber(shopCount1, shopCount2)
      },
      showSorterTooltip: false,
    },
    createdAtColumn('createdAt', (e1, e2) => sortByNumber(e1.createdAt, e2.createdAt)),
  ]

  const onAdd = () => {
    setDrawerState({open: {id: PairingDrawerId, status: true}})
  }

  return (
    <Stack>
      <Box w="100%" textAlign="right" mb="10px">
        <Space direction="horizontal">
          <Button
            type="primary"
            icon={<CloudOutlined/>}
            onClick={onAdd}
          >
            편성표 추가
          </Button>
        </Space>
      </Box>

      <Table
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={pairings}
        scroll={{ x: 1000/*true*/ }}
        onChange={(pagination) => setPageSize(pagination.pageSize)}
        pagination={{ showSizeChanger: true, pageSize }}
      />

      <PairingEdit editable/>
    </Stack>
  )
}

export { UpdatePairingList }
