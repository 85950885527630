import React, { FC, useState } from 'react'
import { Row, Col } from 'antd'
import { ChannelDto } from "../../api/graphql/channel"
import { DistManager } from "models/dist-manager"
import { DistEditChannelList } from './edit-channel-list'
import { DistEditChannelShopList } from './edit-channel-shop-list'

interface Props {
  channels: ChannelDto[]
  distManagerRef: React.MutableRefObject<{ 
    update: (id: string, distManager: DistManager) => void,
    map: Map<string, DistManager>
  }>
  needToRefresh: Map<string, boolean>
}

const DistEditChannel: FC<Props> = ({ channels, distManagerRef, needToRefresh }) => {
  const [selChannelId, setSelChannelId] = useState<string>('')

  const onChannelChanged = (channelId: string) => {
    if (channels.find(channel => channel.id === channelId))
      setSelChannelId(channelId)
    else
      setSelChannelId('')
  }    

  const selChannel = channels.find(ch => ch.id === selChannelId)

  return (
    <Row style={{ 
      width: "100%",
      height: "510px",
      overflowY: "auto",
      border: "1px solid #d0d0d0",
    }}>
      <Col span={12} style={{ padding: 8 }}>
        <DistEditChannelList
          channels={channels}
          distManagerRef={distManagerRef}
          onChannelChange={onChannelChanged}
          needToRefresh={needToRefresh}
        />
      </Col>
      <Col span={12} style={{ height: "100%", padding: 8 }}>
        {
          selChannelId === '' ?
            <DistEditChannelShopList
              key="000000"
              channel={undefined}
              distManagerRef={distManagerRef}
              distManager={undefined}
              needToRefresh={needToRefresh}
            />
            :
            <DistEditChannelShopList
              key={selChannelId}
              channel={selChannel}
              distManagerRef={distManagerRef}
              distManager={distManagerRef.current.map.get(selChannelId)}
              needToRefresh={needToRefresh}
            />
        }
      </Col>
    </Row>
  )
}

export { DistEditChannel }
