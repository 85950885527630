import React, { useState, useEffect } from 'react'
import { useMutation } from '@apollo/client'
import { 
  Col, Drawer, Row, Typography, Select, Input, Switch, Progress,
  notification
} from 'antd'
import { RtiMcDto } from 'api/graphql/rti-mc'
import { PairingDto } from "../../api/graphql/pairing"
import { DistDto } from "../../api/graphql/dist"
import Button from "antd/lib/button"
import { format } from "date-fns"
import { getResponsiveDrawerWidth } from 'common/layouts'
import DrawerSection from "components/common/drawer-section"
import { SET_RTI_MC_PAIRING } from "api/graphql/rti-mc/mutations"

interface Props {
  rtiMc: RtiMcDto
  visible: boolean
  pairings: PairingDto[]
  dists: DistDto[]
  onClose: () => void
}

const UpdateRtiShopDetail: React.FC<Props> = (
  { rtiMc, visible, pairings, dists, onClose }
) => {
  const [byManual, setByManual] = useState<boolean>(rtiMc.isManual)
  const [pairingId, setPairingId] = useState<string>(rtiMc.pairingId)
  const pairing = pairings.find(p => p.id === rtiMc.pairingId)
  const dist = dists.find(d => d.id === rtiMc.distId)

  const percent = !rtiMc.media ? 0 :
    Math.floor(rtiMc.media.confirmedCount * 100 / rtiMc.media.assignedCount)
  const assignedAt = rtiMc.media?.assignedAt ? 
    format(new Date(rtiMc.media?.assignedAt), "yyyy/MM/dd HH:mm:ss") : ''
  const confirmedAt = rtiMc.media?.confirmedAt ?
    format(new Date(rtiMc.media?.confirmedAt), "yyyy/MM/dd HH:mm:ss") : ''

  const [setRtiMcPairing] = useMutation(SET_RTI_MC_PAIRING, {
    onCompleted() {
      notification.success({
        message: `매장 편성표 정보가 변경되었습니다.`,
      })
    },
  })

  useEffect(() => {
    setPairingId(rtiMc.pairingId)
  }, [rtiMc.pairingId])

  const applyRtiMcPairing = () => {
    const variables = {
      id: rtiMc.id,
      isManual: byManual,
      pairingId
    }

    if (rtiMc.isManual && byManual && rtiMc.pairingId === pairingId) {
      notification.success({
        message: `현재 편성된 편성표 정보와 동일합니다.`
      })
    } else {
      setRtiMcPairing({ variables })
        .catch(() => {
          notification.error({
            message: `매장 편성표 정보를 변경할 수 없습니다.`
          })
        })
    }
  }
  
  return (
    <>
      <Drawer
        title="매장 배포 현황 상세 정보"
        width={getResponsiveDrawerWidth(400)}
        placement="right"
        onClose={onClose}
        visible={visible}
        footer={
          <div
            style={{
              textAlign: 'right',
            }}
          >
            <Button onClick={onClose} style={{marginRight: 8}}>
              닫기
            </Button>
          </div>
        }
      >
        <Row align="middle" style={{ border: "solid 1px #808080", background: "#fff7e6", padding: "4px", borderRadius: "4px" }}>
          <Col offset={1} span={17}>
            <Typography.Text
              style={{fontSize: '1rem', marginRight: '6px'}}
              strong
              ellipsis
            >
              {rtiMc.mediacenter.shop.name}
            </Typography.Text>
            <Typography.Text style={{color: '#606060'}} ellipsis>
                        ({rtiMc.mediacenter.channel.name}/{rtiMc.mediacenter.cell.name})
            </Typography.Text>
          </Col>
        </Row>

        <DrawerSection title="편성표 정보">
          <Row>
            <Col span={12}>
              <Switch
                defaultChecked={byManual}
                checkedChildren="수동 배포"
                unCheckedChildren="자동 배포"
                onChange={() => {
                  setByManual(!byManual)
                }}
                style={{ marginBottom: 8 }}
              />
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <Button type="primary" size="small"
                onClick={applyRtiMcPairing}
              >
                적용
              </Button>
            </Col>
          </Row>
          {
            byManual
              ?
              <Select
                placeholder="적용된 편성표가 없습니다."
                defaultValue={pairing ? pairing.name : ''}
                value={pairingId}
                dropdownMatchSelectWidth={false}
                onChange={(value) => { setPairingId(value) }}
                style={{ width: "100%" }}
              >
                {
                  pairings.map(
                    pairing =>
                      <Select.Option
                        key={pairing.id}
                        value={pairing.id}
                      >
                        {pairing.name}
                      </Select.Option>
                  )
                }
              </Select>
              :
              <Input
                placeholder="적용된 편성표가 없습니다."
                value={pairing ? pairing.name : ''}
                style={{ backgroundColor: byManual ? "#ffffff" : "#e0e0e0" }}
              />
              
          }
        </DrawerSection>

        <DrawerSection title="편성표 배포 정보">
          <Input
            placeholder="편성표 배포 정보가 존재하지 않습니다."
            value={dist ? dist.name : ''}
            style={{ backgroundColor: "#e0e0e0" }}
          />
        </DrawerSection>

        <DrawerSection title="배포 완료율">
          <Typography.Title level={3} style={{ width: "100%", textAlign: "center" }}>
            { isNaN(percent) ? "-" : percent + "%" }
          </Typography.Title>
          <Progress percent={percent} strokeLinecap="square"
            format={() => `${rtiMc.media?.confirmedCount} / ${rtiMc.media?.assignedCount}`}
          />
        </DrawerSection>

        <DrawerSection title="편성표 적용 및 배포 현황 갱신 시각">
          <Row>
            <Col span={11}>
              <Typography.Text> 편성표 적용 </Typography.Text>
              <Input
                placeholder="편성표 적용 시간 정보가 없습니다."
                value={assignedAt}
                style={{ backgroundColor: "#e0e0e0" }}
              />
            </Col>
            <Col span={2}>
              
            </Col>
            <Col span={11}>
              <Typography.Text> 배포 현황 갱신 </Typography.Text>
              <Input
                placeholder="배포 현황 갱신 시간 정보가 없습니다."
                value={confirmedAt}
                style={{ backgroundColor: "#e0e0e0" }}
              />
            </Col>
          </Row>
        </DrawerSection>

      </Drawer>
    </>
  )
}

export { UpdateRtiShopDetail }
