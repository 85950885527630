import {gql} from "@apollo/client"

export const ADD_DIVISION = gql`
    mutation AddDivision($name: String!) {
        addDivision(
            name: $name
        ) {
            id
            name
            desc
            createdAt
            updatedAt
        }
    }
`

export const UPDATE_DIVISION = gql`
    mutation UpdateDivision(
        $id: ID!
        $name: String!
    ) {
        updateDivision(
            id: $id
            name: $name
        ) {
            id
            name
            desc
            createdAt
            updatedAt
        }
    }
`

export const REMOVE_DIVISION = gql`
    mutation RemoveDivision($id: ID!) {
        removeDivision(
            id: $id
        )
    }
`

