import React from 'react'
import {ShopList} from './shop'
import {ListHeader} from "../layout"
import {useForm} from "antd/lib/form/Form"
import {Stack} from "@chakra-ui/layout"
import {SearchItem} from "../components/common/search-item"
import fp from "lodash/fp"
import _ from 'lodash'
import {filterForTable} from "../utils/filter"
import {ShopDto} from "../api/graphql/shop"
import useShops from "../hooks/useShops"

function PageShop() {
  //const [searchForm] = useForm()
  const {allShops, shops, setFilter} = useShops()

  /*
  const searchItems = [
    <SearchItem id="channel" key="channel" title="판매 경로" type="dropbox" placeholder="전체"
      width="100%"
      dropboxOptions={_.uniqBy(allShops.map(s => [s.channel.id, s.channel.name]), (channelPair) => channelPair[0])}/>,
    <SearchItem id="cell" key="cell" title="지역 셀" type="dropbox" placeholder="전체"
      width="100%"
      dropboxOptions={_.uniqBy(allShops.map(s => [s.cell.id, s.cell.name]), (cellPair) => cellPair[0])}/>,
    <SearchItem id="name" key="name" title="매장 이름" width="100%" />,
    <SearchItem id="code" key="code" title="매장 코드" width="100%" />,
  ]
  */

  /*
  const onSearch = () => {
    const searchFields = searchForm.getFieldsValue()

    const filter = fp.flow(
      filterForTable<ShopDto>({fieldValue: searchFields['cell'], type: 'dropbox', path: 'cell.id'}),
      filterForTable<ShopDto>({fieldValue: searchFields['channel'], type: 'dropbox', path: 'channel.id'}),
      filterForTable<ShopDto>({fieldValue: searchFields['code'], type: 'text', path: 'code'}),
      filterForTable<ShopDto>({fieldValue: searchFields['name'], type: 'text', path: 'name'}),
    )

    setFilter({filter})
  }
  */
  return (
    <Stack>
      {/*<ListHeader searchForm={searchForm} title="매장 관리" searchItems={searchItems} onSearch={onSearch}/>*/}
      <ShopList shops={shops}/>
    </Stack>
  )
}

export {PageShop}
