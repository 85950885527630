import { useState } from "react"

export const defaultTablePageSize = 10

function usePageSize(itemSuffix: string) {
  const key = `pagesize-${itemSuffix}`

  const size = localStorage.getItem(key)
  const [pageSize, _setPageSize] = useState(size ? Number(size) : defaultTablePageSize)

  const setPageSize = (size: number | undefined) => {
    localStorage.setItem(key, size ? size.toString() : '')
    _setPageSize(size ?? defaultTablePageSize)
  }

  return {
    pageSize,
    setPageSize
  }
}

export default usePageSize
