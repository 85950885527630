import React, { FC, useRef } from 'react'
import { Button, Form, Space, Table, Tag } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { DatabaseOutlined, } from '@ant-design/icons'
import { Division } from 'api/graphql/division'
import { Box } from '@chakra-ui/layout'
import useModalState from 'hooks/useModalState'
import { DivisionEditDrawer } from './edit'
import usePageSize from '../../hooks/usePageSize'
import { sortByNumber } from '../../utils'
import { createdAtColumn } from '../../common/created-at-column'
import useChannels from '../../hooks/useChannels'
import { columnText } from '../../components/table'

interface Props {
  divisions: Division[]
}

export interface DivisionFormValues {
  id: string
  name: string
  desc?: string
}

const DivisionList: FC<Props> = ({ divisions }) => {
  const { channels } = useChannels()

  const [form] = Form.useForm()
  const { closeModal, modalMode, openAddModal, openEditModal, modalShowable } = useModalState()
  const { pageSize, setPageSize } = usePageSize('division')

  const columns: ColumnsType<Division> = [
    columnText("매장 분류", "name", "name", 160, "left",
      { searchInputRef: useRef() },
      (division) => {
        const formValues: DivisionFormValues = {
          id: division.id,
          name: division.name,
          desc: division.desc,
        }
        form.setFieldsValue(formValues)
        openEditModal()
      }
    ),
    {
      title: '판매 경로 리스트',
      dataIndex: 'channels',
      key: 'channels',
      //width: 160,
      render: (_, division) => {
        return <Space size={[0, 8]} wrap>
          { channels.filter(ch => ch.division.id === division.id).map(ch => <Tag key={ch.id}>{ch.name}</Tag>) }
        </Space>
      },
      showSorterTooltip: false,
    },

    createdAtColumn('createdAt', (e1, e2) => sortByNumber(e1.createdAt, e2.createdAt)),
  ]

  const onDrawerOpen = () => {
    form.resetFields()
    openAddModal()
  }

  return (
    <>
      <Box w="100%" textAlign="right" mb="10px">
        <Space direction="horizontal">
          <Button type="primary" icon={<DatabaseOutlined/>}
            onClick={onDrawerOpen}
          >
            추가
          </Button>
        </Space>
      </Box>
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        scroll={{ x: 1000/*true*/ }}
        dataSource={divisions}
        onChange={(pagination) => setPageSize(pagination.pageSize)}
        pagination={{ showSizeChanger: true, pageSize }}
      />
      <DivisionEditDrawer
        divisions={divisions}
        channels={channels}
        form={form}
        visible={modalShowable}
        modalMode={modalMode}
        onClose={closeModal}
      />
    </>
  )
}

export { DivisionList }
