import format from "date-fns/format"
import React from "react"

export const createdAtColumn = (dataIndex: string | string[], sorter: (e1, e2) => number) => ({
  title: '등록 날짜',
  dataIndex: dataIndex,
  key: 'createdAt',
  width: 100,
  render: createdAt => (
    <span title={format(new Date(createdAt), "yyyy-MM-dd HH:mm:ss")}>
      {format(new Date(createdAt), "yyyy-MM-dd")}
    </span>
  ),
  sorter,
  showSorterTooltip: false,
})
