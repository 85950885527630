import React, {useEffect} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Alert, Button, Form, Input} from 'antd'
import {LockOutlined, UserOutlined} from '@ant-design/icons'
import {AuthLoginStatus, login} from 'features/auth'
import {RootState} from 'app/reducer'
import {Box, Stack} from '@chakra-ui/layout'
import _ from 'lodash'
import {useHistory} from "react-router-dom"
import logo from 'app/logo.png'

function RouteLogin() {
  const [form] = Form.useForm()
  const history = useHistory()

  const authState = useSelector((store: RootState) => store.authReducer)
  const dispatch = useDispatch()

  useEffect(() => {
    if (authState.status === AuthLoginStatus.LOGGED_IN) {
      history.push('/')
    }
  }, [authState.status])

  const handleFinish = (values: { userid: string, password: string }) => {
    dispatch(login(values.userid, values.password))
  }

  return (
    <Stack w="100vw" h="100vh" justify="center" align="center">
      <Box mb="16px">
        <img src={logo} alt="Logo" />
      </Box>
      <Box w="300px">
        <Form
          form={form}
          name="login"
          className="login-form"
          initialValues={{remember: true}}
          onFinish={handleFinish}
        >
          {authState.status === AuthLoginStatus.LOGIN_FAILED && authState.message &&
                    <Form.Item>
                      <Alert type="error" message={authState.message} showIcon/>
                    </Form.Item>
          }
          <Form.Item
            name="userid"
            rules={[
              {required: true, message: '아이디가 입력되지 않았습니다.'},
            ]}>
            <Input prefix={<UserOutlined className="site-form-item-icon"/>} type="text" placeholder="id"/>
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{required: true, message: '패스워드가 입력되지 않았습니다.'}]}>
            <Input
              prefix={<LockOutlined className="site-form-item-icon"/>} type="password"
              placeholder="password"
            />
          </Form.Item>

          <Form.Item shouldUpdate>
            {() => (
              <Button
                type="primary"
                htmlType="submit"
                disabled={
                  !form.isFieldsTouched(true) ||
                                    _.some(form.getFieldsError(), (o) => o.errors.length > 0)
                }
                block
              >
                                로그인
              </Button>
            )}
          </Form.Item>
        </Form>
      </Box>
    </Stack>
  )
}

export {RouteLogin}
