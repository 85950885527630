import React from 'react'
import {RtiShopList} from './status-shop'
import {Stack} from "@chakra-ui/layout"
import Typography from "antd/lib/typography"
import {Divider} from "antd"
import RtiMcsStatistics from "./status-shop/rtimcs-statistics"
import {RtiMcDto} from "../api/graphql/rti-mc"
import useRtiMcs from "../hooks/useRtiMcs"

export interface RtiMcFilter {
    filter: (rtiMcs: RtiMcDto[]) => RtiMcDto[];
}

function PageStatusShop() {
  const {allRtiMcs, setFilter, rtiMcs} = useRtiMcs()

  return (
    <Stack>
      <Stack spacing={16}>
        <Typography.Title level={3}>
                    접속 현황
        </Typography.Title>

        <Divider/>
      </Stack>

      <RtiMcsStatistics allRtiMcs={allRtiMcs} setFilter={setFilter}/>
      <RtiShopList rtiMcs={rtiMcs}/>
    </Stack>
  )
}

export {PageStatusShop}
