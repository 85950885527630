import React, {FC} from 'react'
import {AccountList} from './account'
import {AccountDto, Accounts, GET_ACCOUNTS, SUBSCRIBE_ACCOUNT} from "../api/graphql/account"
import {Stack} from "@chakra-ui/layout"
import {ListHeader} from 'layout'
import {SearchItem} from "../components/common/search-item"
import {useForm} from "antd/lib/form/Form"
import {Account} from "../models"
import fp from 'lodash/fp'
import {afterProvided} from "../utils"
import {filterForTable} from "../utils/filter"
import useDtos from "../hooks/useDtos"
import useChannels from "../hooks/useChannels"

export interface AccountFormValues {
    userid: string;
    name: string;
    password: string;
    level: number;
    role: number;
    phoneno: string;
    email: string;
    desc: string;
    channelId: string;
    shopId?: string;
    cellId?: string;
    regionAndCellId?: [string, string];
}

export const PageAccount: FC = () => {
  const [searchForm] = useForm()

  const {dtos: accounts, setFilter} = useDtos<AccountDto, Accounts>({
    query: GET_ACCOUNTS,
    subscription: SUBSCRIBE_ACCOUNT,
    queryResultKey: 'accounts',
    updateQueryFunction: afterProvided({
      queryResultKey: 'accounts',
      subscriptionResultKey: 'subscribeAccount',
      dataKey: 'account'
    }),
  })
  const {channels: channelDtos} = useChannels()

  const searchItems = [
    <SearchItem key="level" id="level" title="사용자 레벨" placeholder="전체" type="dropbox"
      width="100%"
      dropboxOptions={Array.from(Account.levels().entries())}/>,
    <SearchItem key="role" id="role" title="사용자 종류" type="dropbox" placeholder="전체"
      width="100%"
      dropboxOptions={Array.from(Account.roles().entries())}/>,
    <SearchItem key="userid" id="userid" title="아이디" width="100%" />,
    <SearchItem key="name" id="name" title="사용자 이름" width="100%" />,
  ]

  const filters = (searchFields) => fp.flow(
    filterForTable<AccountDto>({fieldValue: searchFields['level'], type: 'dropbox', path: 'level'}),
    filterForTable<AccountDto>({fieldValue: searchFields['role'], type: 'dropbox', path: 'role'}),
    filterForTable<AccountDto>({fieldValue: searchFields['shopName'], type: 'text', path: 'shop.name'}),
    filterForTable<AccountDto>({fieldValue: searchFields['userid'], type: 'text', path: 'userid'}),
    filterForTable<AccountDto>({fieldValue: searchFields['name'], type: 'text', path: 'name'}),
    filterForTable<AccountDto>({fieldValue: searchFields['channelName'], type: 'text', path: 'channel'}),
  )

  const onSearch = () => {
    const searchFields = searchForm.getFieldsValue()
    setFilter({filter: filters(searchFields)})
  }

  return (
    <Stack>
      <ListHeader searchForm={searchForm} title="사용자 관리" searchItems={searchItems} onSearch={onSearch}/>
      <AccountList channels={channelDtos} accounts={accounts}/>
    </Stack>
  )
}
