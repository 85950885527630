import React, {FC} from 'react'
import {HStack, Flex, Spacer, Box} from '@chakra-ui/layout'
import {Button} from 'antd'
import {
  CheckOutlined, CloseOutlined, QuestionCircleOutlined, DeleteOutlined
} from '@ant-design/icons'
import Popconfirm from 'antd/lib/popconfirm'

interface Props {
  formId: string
  isNew: boolean
  onUpdate?: () => void
  onRemove?: () => void
  close: () => void
}

const DrawerFooter: FC<Props> = ({ formId, isNew, onRemove, onUpdate, close }) => {
  return (
    <Flex>
      {isNew ? (
        <Button key="submit" form={formId} type="primary" style={{marginRight: '4px'}}
          htmlType="submit" icon={<CheckOutlined/>}
        >
          추가
        </Button>
      ) : (
        <HStack>
          <Box>
            <Button form={formId} type="primary"
              icon={<CheckOutlined/>} onClick={() => onUpdate && onUpdate()}
              style={{marginRight: '4px'}}
            >
              수정
            </Button>
          </Box>
          <Box>
            <Popconfirm
              onConfirm={onRemove}
              title="삭제하시겠습니까?"
              icon={<QuestionCircleOutlined style={{color: 'red'}}/>}
            >
              <Button
                icon={
                  <DeleteOutlined/>
                }
                style={{marginRight: '4px'}}
                type="primary"
                danger
              >
                삭제
              </Button>
            </Popconfirm>
          </Box>
        </HStack>
      )}
      <Spacer />
      <Button onClick={close} icon={<CloseOutlined />} style={{ borderColor: '#00000' }}>
        닫기
      </Button>
    </Flex>
  )
}

export default DrawerFooter
