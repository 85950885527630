import React from 'react'
import {Tag} from 'antd'
import {McState} from 'api/graphql/rti-mc'
import McStateViewModel from "models/mc-state"

interface Props {
    state: McState;
}

const RtiShopDetailShopState: React.FC<Props> = ({state}) => {
  let tag

  const stateString = new McStateViewModel(state).toString()

  if (state === McState.UNKNOWN || state === McState.OFFLINE) {
    tag = (<Tag color='#bfbfbf'>{stateString}</Tag>)
  } else if (state === McState.ONLINE) {
    tag = (<Tag color='#52c41a'>{stateString}</Tag>)
  } else {
    tag = (<Tag color='#f5222d'>{stateString}</Tag>)
  }

  return (
    tag
  )
}


export {RtiShopDetailShopState}
