import React, { FC, useState, useRef } from 'react'
import { Button, Form, Space, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { FileAddOutlined } from '@ant-design/icons'
import { Box } from '@chakra-ui/layout'
import _ from 'lodash'
import useModalState from '../../hooks/useModalState'
import { GroupDto } from '../../api/graphql/group'
import usePageSize from '../../hooks/usePageSize'
import { sortByNumber } from '../../utils'
import { createdAtColumn } from '../../common/created-at-column'
import usePairings from '../../hooks/usePairing'
import useRtiMcs from '../../hooks/useRtiMcs'
import { GroupEdit } from './edit'
import { columnText } from '../../components/table'

interface Props {
  groups: GroupDto[]
}

const TvGroupList: FC<Props> = ({groups}) => {
  const { pairings } = usePairings()
  const { rtiMcs } = useRtiMcs()

  const [drawerForm] = Form.useForm()
  const [selectedGroup, setSelectedGroup] = useState<GroupDto>()

  const {closeModal, modalMode, openAddModal, openEditModal, modalShowable} = useModalState()
  
  const { pageSize, setPageSize } = usePageSize('tvgroup')

  const onGroupClick = (group: GroupDto) => {
    setSelectedGroup(group)
    openEditModal()
  }

  const onDrawerOpen = () => {
    drawerForm.resetFields()
    openAddModal()
  }

  const onDrawerClose = () => {
    drawerForm.resetFields()
    setSelectedGroup(undefined)

    closeModal()
  }

  const columns: ColumnsType<GroupDto> = [
    columnText("그룹 이름", "name", "name", 240, "left",
      { searchInputRef: useRef(null) },
      (group) => onGroupClick(group)
    ),
    columnText("식별 패턴", "regex", "regex", 0, "left",
      { searchInputRef: useRef(null) }
    ),
    {
      title: '편성표 수',
      //dataIndex: 'shopCount',
      key: 'pairingCount',
      width: 100,
      render: (_, group) => {
        return (
          <span>
            { 
              pairings.filter(pairing => 
                pairing.playlists?.findIndex(p => p.group.id === group.id) !== -1
              ).length
            }
          </span>
        )
      },
      sorter: (group1, group2) => {
        const pairingCount1 = pairings.filter(pairing => 
          pairing.playlists?.findIndex(p => p.group.id === group1.id) !== -1
        ).length
        const pairingCount2 = pairings.filter(pairing => 
          pairing.playlists?.findIndex(p => p.group.id === group2.id) !== -1
        ).length
        return sortByNumber(pairingCount1, pairingCount2)
      },
      showSorterTooltip: false,
    },
    {
      title: '매장 수',
      //dataIndex: 'shopCount',
      key: 'shopCount',
      width: 100,
      render: (_, group) => {
        return (
          <span>
            { 
              rtiMcs.filter(rtiMc => 
                rtiMc.groups.findIndex(g => g.id === group.id) !== -1
              ).length
            }
          </span>
        )
      },
      sorter: (group1, group2) => {
        const shopCount1 = rtiMcs.filter(rtiMc => 
          rtiMc.groups.findIndex(g => g.id === group1.id) !== -1
        ).length
        const shopCount2 = rtiMcs.filter(rtiMc => 
          rtiMc.groups.findIndex(g => g.id === group2.id) !== -1
        ).length
        return sortByNumber(shopCount1, shopCount2)
      },
      showSorterTooltip: false,
    },
    createdAtColumn('createdAt', (e1, e2) => sortByNumber(e1.createdAt, e2.createdAt)),
  ]

  return (
    <>
      <Box w="100%" textAlign="right" mb="10px">
        <Space direction="horizontal">
          <Button type="primary" icon={<FileAddOutlined/>}
            onClick={onDrawerOpen}
          >
            추가
          </Button>
        </Space>
      </Box>
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={groups}
        scroll={{ x: 1000/*true*/ }}
        onChange={(pagination) => setPageSize(pagination.pageSize)}
        pagination={{ showSizeChanger: true, pageSize }}
      />
      <GroupEdit
        rtiMcs={rtiMcs}
        form={drawerForm}
        selectedGroup={selectedGroup}
        visible={modalShowable}
        modalMode={modalMode}
        onClose={onDrawerClose}
      />
    </>
  )
}

export { TvGroupList }
