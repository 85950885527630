import { gql } from '@apollo/client'

export const REMOVE_MEDIA = gql`
  mutation RemoveMedia($id: ID!) {
    removeMedia(
      id: $id
    )
  }
`

export const GENERATE_MEDIA_THUMBNAIL = gql`
  mutation GenerateMediaThumbnail($id: ID!) {
    generateMediaThumbnail(
      id: $id
    )
  }
`