import { GET_DIVISIONS, Division, Divisions, SUBSCRIBE_DIVISION } from "../api/graphql/division"
import useDtos, { LoadingAndError } from "./useDtos"
import { afterProvided } from "../utils"

interface Result extends LoadingAndError {
  allDivisions: Division[]
  divisions: Division[]
}

const useDivisions = (): Result => {
  const { allDtos: allDivisions, dtos: divisions, loading, error } = useDtos<Division, Divisions>({
    query: GET_DIVISIONS,
    queryResultKey: 'divisions',
    subscription: SUBSCRIBE_DIVISION,
    updateQueryFunction: afterProvided({
      queryResultKey: 'divisions',
      subscriptionResultKey: 'subscribeDivision',
      dataKey: 'division'
    })
  })

  return { allDivisions, divisions, loading, error }
}

export default useDivisions
