import {useState} from "react"
import {ModalMode} from "../types"

function useModalState() {
  const [modalMode, setModalMode] = useState<ModalMode>('')

  const closeModal = () => {
    setModalMode('')
  }

  const openAddModal = () => {
    setModalMode('추가')
  }

  const openEditModal = () => {
    setModalMode('수정')
  }

  return {
    closeModal,
    openAddModal,
    openEditModal,
    modalMode,
    modalShowable: modalMode !== ''
  }
}

export default useModalState
