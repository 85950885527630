class Paginate<T> {
  constructor(private currentPage: number, private pageSize: number, private items: T[]) {
  }

  moveToNext(): Paginate<T> {
    this.currentPage += 1
    return new Paginate<T>(this.currentPage, this.pageSize, this.getItems())
  }

  getItems(): T[] {
    return this.items.slice(0, this.currentPage * this.pageSize)
  }

  hasMore(): boolean {
    return this.getItems().length !== this.items.length
  }

  filter(pred: (item: T) => boolean): Paginate<T> {
    return new Paginate<T>(1, this.pageSize, this.items.filter(pred))
  }
}

export default Paginate
