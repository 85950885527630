import {gql} from "@apollo/client"
import {amcodeFragment} from "./queries"

export const ADD_AMCODE = gql`
    mutation AddAmcode(
        $name: String!
    ) {
        addAmcode (
            name: $name
        ) {
            ...amcodeParts
        }
    }
    ${amcodeFragment}
`

export const UPDATE_AMCODE = gql`
    mutation UpdateAmcode(
        $id: ID!
        $name: String!
    ) {
        updateAmcode (
            id: $id
            name: $name
        ) {
            ...amcodeParts
        }
    }
    ${amcodeFragment}
`

export const REMOVE_AMCODE = gql`
    mutation RemoveAmcode(
        $id: ID!
    ) {
        removeAmcode (
            id: $id
        )
    }
`
