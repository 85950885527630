import { gql } from '@apollo/client'

export const cellFragment = gql`
  fragment cellParts on Cell {
    id
    name
    desc
    region {
      id
      name
    }
    createdAt
    updatedAt
  }
`

export const GET_CELLS = gql`
  query GetCells {
    cells {
      ...cellParts
    }
  }
  ${cellFragment}
`

export const SUBSCRIBE_CELL = gql`
  subscription {
    subscribeCell {
      op
      cell {
        ...cellParts
      }
    }
  }
  ${cellFragment}
`
