import React, { useState, useEffect, useRef } from "react"
import { McState, RtiMcDto, RtiMcMedia } from '../../api/graphql/rti-mc'
import { ColumnsType } from 'antd/es/table'
import { Table, Tag } from 'antd'
import usePageSize from '../../hooks/usePageSize'
import { format } from 'date-fns'
import { PairingDto } from '../../api/graphql/pairing'
import { DistDto } from '../../api/graphql/dist'
import { sortByNumber, sortByText } from '../../utils'
import { PairingDrawerId, PairingEdit } from '../pairing/edit'
import { DistEdit } from '../dist/edit'
import { useTableContext } from '../../contexts/TableContextProvider'
import { DistDrawerId } from '../dist'
import { UpdateRtiShopDetail } from './detail'
import { FlagTwoTone } from '@ant-design/icons'
import {
  CheckCircleOutlined, CloseCircleOutlined, SyncOutlined, ClockCircleOutlined
} from '@ant-design/icons'
import { columnText, columnShopName } from '../../components/table'
import useChannels from '../../hooks/useChannels'
import useCells from '../../hooks/useCells'

interface Props {
  rtiMcs: RtiMcDto[]
  pairings: PairingDto[]
  dists: DistDto[]
}

const UpdateStatusList: React.FC<Props> = ({ rtiMcs, pairings, dists }) => {
  const { pageSize, setPageSize } = usePageSize('update')
  const { setDrawerState } = useTableContext()

  const { channels } = useChannels()
  const { cells } = useCells()

  const columns: ColumnsType<RtiMcDto> = [
    columnShopName(
      ['mediacenter', 'shop', 'name'],
      (e1, e2) => sortByText(e1.mediacenter.shop.name, e2.mediacenter.shop.name),
      (value, e1) => e1.mediacenter.shop.name.includes(value),
      useRef(null),
      'center',
      (record) => handleItemClick(record.id)
    ),
    columnText("판매 경로", ["mediacenter", "channel", "name"], "channel", 120, "left",
      { filters: channels.map((channel) => ({ text: channel.name, value: channel.name })) }
    ),
    columnText("지역", ["mediacenter", "cell", "name"], "cell", 100, "left",
      { filters: cells.map((cell) => ({ text: cell.name, value: cell.name })) }
    ),
    {
      title: '상태',
      dataIndex: 'state',
      key: 'state',
      width: 80,
      //align: 'center',
      render: state => {
        if (state === McState.ONLINE) {
          return <Tag color='#52c41a'>접속</Tag>
        } else {
          return <Tag color='#bfbfbf'>미접속</Tag>
        }
      },
      sorter: (rtiMc1, rtiMc2) => sortByNumber(rtiMc1.state, rtiMc2.state),
      showSorterTooltip: false,
    },
    {
      title: '배포 현황',
      dataIndex: 'media',
      key: 'status',
      width: 120,
      align: 'center',
      render: (media, record) => {
        return (!media || media.assignedCount === 0) ?
          <Tag icon={<CloseCircleOutlined /> } color="error">정보 없음</Tag>
          :
          media.assignedCount === media.confirmedCount ?
            <Tag icon={<CheckCircleOutlined />} color="success">배포 완료</Tag>
            :
            record.state === McState.ONLINE ?
              <Tag icon={<SyncOutlined spin />} color="processing">진행 중</Tag>
              :
              <Tag icon={<ClockCircleOutlined />}>일시 멈춤</Tag>
      }
    },
    {
      title: '완료율',
      dataIndex: 'media',
      key: 'percent',
      width: 100,
      align: 'center',
      render: media => {
        return <span>
          {
            (media && media.assignedCount > 0) ?
              `${Math.floor(media.confirmedCount / media.assignedCount * 100)}% ` +
              `(${media.confirmedCount}/${media.assignedCount})`
              :
              "-"
          }
        </span>
      }
    },
    {
      title: '편성표 정보',
      key: 'pairing',
      dataIndex: 'pairingId',
      render: (text, record) => {
        const p = pairings.find(p => p.id === record.pairingId)

        const onClick = () => {
          if (p) {
            setDrawerState({open: {id: PairingDrawerId, status: true}, tableRow: {id: p.id, data: p}})
          }
        }

        return (
          <>
            {
              record.isManual && 
                <>
                  <FlagTwoTone twoToneColor="red" style={{ fontSize: "18px" }} />
                  &nbsp;&nbsp;
                </>
            }
            <a href="#" onClick={onClick}>{p ? p.name : ''}</a>
          </>
        )
      }
    },
    {
      title: '배포 정보',
      key: 'dist',
      dataIndex: 'distId',
      render: (text, record) => {
        const d = dists.find(d => d.id === record.distId)

        const onClick = () => {
          if (d) {
            setDrawerState({open: {id: DistDrawerId, status: true}, tableRow: {id: d.id, data: d}})
          }
        }

        const style = record.isManual ? { color: "#C0C0C0" } : {}
        return (
          <a href="#" onClick={onClick} 
            style={style}
          >
            {d ? d.name : ''}
          </a>
        )
      }
    },
    {
      title: '배포 적용',
      dataIndex: 'media',
      key: 'assignedAt',
      width: 100,
      render: (media: RtiMcMedia) => {
        const { title, text } = media && new Date(media.assignedAt).getTime() !== 0 ?
          {
            title: format(new Date(media.assignedAt), "yyyy-MM-dd HH:mm:ss"),
            text: format(new Date(media.assignedAt), "yyyy-MM-dd")
          } : {
            title: '', text: ''
          }
        
        return <span title={title}>{text}</span>
      }
    },
    {
      title: '현황 갱신',
      dataIndex: 'media',
      key: 'confirmedAt',
      width: 100,
      render: (media: RtiMcMedia) => {
        const { title, text } = media && new Date(media.confirmedAt).getTime() !== 0 ?
          {
            title: format(new Date(media.confirmedAt), "yyyy-MM-dd HH:mm:ss"),
            text: format(new Date(media.confirmedAt), "yyyy-MM-dd")
          } : {
            title: '', text: ''
          }
        
        return <span title={title}>{text}</span>
      }
    },
  ]

  const [selRtiMc, setSelRtiMc] = useState<RtiMcDto>()
  const [visibleDetailDrawer, setVisibleDetailDrawer] = useState(false)

  useEffect(() => {
    if (visibleDetailDrawer && selRtiMc) {
      const rtiMc = rtiMcs.find(rtiMc => rtiMc.id === selRtiMc.id)
      setSelRtiMc(rtiMc)
    }
  }, [rtiMcs])

  function handleItemClick(mediacenterId) {
    const rtiMc = rtiMcs.find(rtiMc => rtiMc.id === mediacenterId)

    setSelRtiMc(rtiMc)
    setVisibleDetailDrawer(true)
  }

  function handleDrawerAndModalClosed() {
    setSelRtiMc(undefined) 

    setVisibleDetailDrawer(false)
  }

  return (
    <>
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={rtiMcs}
        scroll={{ x: 1000/*true*/ }}
        onChange={(pagination) => setPageSize(pagination.pageSize)}
        pagination={{ showSizeChanger: true, pageSize }}
      />

      {selRtiMc && visibleDetailDrawer && (
        <UpdateRtiShopDetail
          rtiMc={selRtiMc}
          visible={visibleDetailDrawer}
          pairings={pairings}
          dists={dists}
          onClose={() => handleDrawerAndModalClosed()}
        />
      )}

      <PairingEdit editable={false}/>
      <DistEdit editable={false}/>
    </>
  )
}

export default UpdateStatusList
