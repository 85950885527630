import {AuthorizationLevel} from '../features/auth'

export enum Roles {
    ADMIN = 0,
    MANUFACTURER = 1,
    SALES_CHANNEL = 2,
    SHOP = 3,
}

export class Account {
  static levels() {
    return new Map<AuthorizationLevel, string>([
      [AuthorizationLevel.ADMIN, '관리자'],
      [AuthorizationLevel.OPERATOR, '운영자'],
      /*
            [AuthorizationLevel.USER, '사용자'],
            [AuthorizationLevel.VISITOR, '방문자'],
            */
    ])
  }

  static roles() {
    return new Map<Roles, string>([
      // [Roles.ADMIN, '관리자'],
      [Roles.ADMIN, '디지털존'],
      [Roles.MANUFACTURER, '제조사'],
      /*
                [Roles.SALES_CHANNEL, '판매 채널'],
                [Roles.SHOP, '매장']
                */
    ]
    )
  }

  constructor(private readonly _level: AuthorizationLevel, private readonly _role: Roles) {
  }

  get level(): AuthorizationLevel {
    return this._level
  }

  get role(): Roles {
    return this._role
  }
}
