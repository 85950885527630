import { combineReducers } from '@reduxjs/toolkit'
import authReducer from '../features/auth/slice'
import groupReducer from 'features/group'
import menuReducer from 'features/menu'
import {GroupDto} from "../api/graphql/group"

export interface IStore {
    groups: GroupDto[];
}

const rootReducer = combineReducers({
  authReducer,
  groups: groupReducer,
  menu: menuReducer
})

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer
