import {gql} from "@apollo/client"
import {firmwareFragment} from "./queries"

export const ADD_FIRMWARE = gql`
    mutation AddFirmware(
        $firmwareInput: FirmwareInput!
    ) {
        addFirmware(
            firmwareInput: $firmwareInput
        ) {
            id
        }
    }
`

export const UPDATE_FIRMWARE = gql`
    mutation UpdateFirmware(
        $firmwareInput: FirmwareInput!
    ) {
        updateFirmware(
            firmwareInput: $firmwareInput
        ) {
            ...firmwareParts
        }
    }
    ${firmwareFragment}
`

export const REMOVE_FIRMWARE = gql`
    mutation RemoveFirmware(
        $id: ID!
    ) {
        removeFirmware(
            id: $id
        )
    }

`