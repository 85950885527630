import { gql } from '@apollo/client'

export const GET_DIVISIONS = gql`
  query GetDivisions {
    divisions {
      id
      name
      desc
      createdAt
      updatedAt
    }
  }
`

export const SUBSCRIBE_DIVISION = gql`
  subscription {
    subscribeDivision {
      op
      division {
        id
        name
        desc
        createdAt
        updatedAt
      }
    }
  }
`
