import React, { FC, useMemo, useRef } from 'react'
import { Button, Form, Space, Table, } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { DatabaseOutlined, } from '@ant-design/icons'
import { CellDto } from 'api/graphql/cell'
import { Box } from '@chakra-ui/layout'
import useModalState from 'hooks/useModalState'
import { CellEditDrawer } from './edit'
import usePageSize from '../../hooks/usePageSize'
import { sortByNumber } from '../../utils'
import { createdAtColumn } from '../../common/created-at-column'
import useRtiMcs from '../../hooks/useRtiMcs'
import { columnText } from '../../components/table'

interface Props {
  cells: CellDto[]
}

export interface CellFormValues {
  id: string
  name: string
  regionId: string
  desc?: string
}

const CellList: FC<Props> = ({ cells }) => {
  const { rtiMcs } = useRtiMcs()

  const [form] = Form.useForm()
  const { closeModal, modalMode, openAddModal, openEditModal, modalShowable } = useModalState()
  const { pageSize, setPageSize } = usePageSize('cell')

  const columns: ColumnsType<CellDto> = [
    columnText("지역셀", "name", "name", 160, "left",
      { searchInputRef: useRef(null) },
      (cell) => {
        const formValues: CellFormValues = {
          id: cell.id,
          name: cell.name,
          regionId: cell.region.id,
          desc: cell.desc,
        }
        form.setFieldsValue(formValues)
        openEditModal()
      }
    ),
    columnText("지역", ["region", "name"], "region", 160, "left",
      { searchInputRef: useRef(null) },
    ),
    {
      title: '매장 수',
      //dataIndex: 'shopCount',
      key: 'shopCount',
      width: 100,
      render: (_, cellDto) => {
        return (
          <span>
            { rtiMcs.filter(rtiMc => rtiMc.mediacenter.cell.id === cellDto.id).length }
          </span>
        )
      },
      sorter: (cellDto1, cellDto2) => {
        const shopCount1 = rtiMcs.filter(rtiMc => rtiMc.mediacenter.cell.id === cellDto1.id).length
        const shopCount2 = rtiMcs.filter(rtiMc => rtiMc.mediacenter.cell.id === cellDto2.id).length
        return sortByNumber(shopCount1, shopCount2)
      },
      showSorterTooltip: false,
    },

    createdAtColumn('createdAt', (e1, e2) => sortByNumber(e1.createdAt, e2.createdAt)),
  ]

  const onDrawerOpen = () => {
    form.resetFields()
    openAddModal()
  }

  return (
    <>
      <Box w="100%" textAlign="right" mb="10px">
        <Space direction="horizontal">
          <Button type="primary" icon={<DatabaseOutlined/>}
            onClick={onDrawerOpen}
          >
            추가
          </Button>
        </Space>
      </Box>
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        scroll={{ x: 1000/*true*/ }}
        dataSource={cells}
        onChange={(pagination) => setPageSize(pagination.pageSize)}
        pagination={{ showSizeChanger: true, pageSize }}
      />
      <CellEditDrawer
        cells={cells}
        form={form}
        visible={modalShowable}
        modalMode={modalMode}
        onClose={closeModal}
      />
    </>
  )
}

export { CellList }
