import {
  Button, Card, Form, Image, Input, List, Modal, Tooltip, Typography, Row, Col,
  Badge
} from "antd"
import { Box, VStack, Spacer, Flex, Center } from "@chakra-ui/layout"
import { CheckOutlined, MinusOutlined, PlusOutlined } from "@ant-design/icons"
import React, { useEffect, useState } from "react"
import _ from "lodash"
import { GroupManager } from "../../models"
import useMedia from "../../hooks/useMedia"
import { useForm } from "antd/lib/form/Form"
import Paginate from "../../models/paginate"
import { MediaDto } from "../../api/graphql/media"
import { toMegabytes } from "../../utils"
import InfiniteScroll from 'react-infinite-scroller'
import { getResponsiveModalWidth } from 'common/layouts'
import { isMobile } from 'react-device-detect'
import { urlJoin } from '../../utils/url'
import { fallback } from '../../common/images/fallback'
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd'

const { Text } = Typography

type VideoItemMode = "add" | "remove"

const VideoItem: React.FC<{
  mode: VideoItemMode,
  selectedIdx: number,
  media: MediaDto,
  onAdd?: (media: MediaDto) => void
  onRemove?: (mediaId: string) => void
}> = ({ mode, selectedIdx, media, onAdd, onRemove }) => {
  
  const [isFocused, setFocus] = useState(false)

  return (
    <Tooltip title={media.filename}>
      <Card
        style={{ 
          width: isMobile ? 64 : 128,
          background: selectedIdx !== -1 ? "#808080" : "#ffffff",
          border: selectedIdx !== -1 ? "0.5px solid #1890ff" : "0.5px solid #e0e0e0"
        }}
        bordered={false}
        hoverable
        cover={
          <Center w="100%">
            <Image
              width={ isMobile ? 64 : 128 }
              height={ isMobile ? 36 : 72 }
              preview={false}
              src={urlJoin(process.env.REACT_APP_THUMBNAIL_URL, media.thumbnail) +
                `?${new Date(media.updatedAt).getTime()}`}
              fallback={fallback}
              style={{ borderBottom: "1px solid #e0e0e0"}}
            />
          </Center>
        }
        onMouseOver={() => !isFocused && setFocus(true)}
        onMouseEnter={() => setFocus(true)}
        onMouseLeave={() => setFocus(false)}
        bodyStyle={{ margin:0, padding: 0, paddingLeft: 4, paddingRight: 4 }}
      >
        <VStack w="100%">
          <Center w="100%" h={24}>
            <Text style={{ fontSize: 11, color: selectedIdx !== -1 ? "#ffffff" : "#202020" }} ellipsis>{media.filename}</Text>
          </Center>
          <Row style={{ width: "100%", height: 24, marginTop: 0, padding: 0 }}>
            <Col span={6} style={{ textAlign: "left", paddingTop: 2 }}>
              { mode === "add" && selectedIdx !== -1 &&
                <Badge count={selectedIdx+1} size="small"
                  style={{
                    fontSize: 10, backgroundColor: '#52c41a'
                  }}
                />
              }
              { mode === "remove" && isFocused &&
                  <Button
                    type="primary" danger
                    disabled={selectedIdx !== -1 ? true : false}
                    icon={<MinusOutlined style={{
                      fontSize: "inherit", display: "table", margin: "0 auto"
                    }} />}
                    style={{ fontSize: 12, width: 20, height: 20,
                      display: "inline-block", verticalAlign: "top"
                    }}
                    onClick={() => onRemove && onRemove(media.id)}
                  />
              }
            </Col>
            <Col span={12} style={{ textAlign: "center" }}>
              <Text style={{
                fontSize: 8, color: selectedIdx !== -1 ? "#d0d0d0" : "#808080",
              }}>{toMegabytes(media.size)}MB</Text>
            </Col>
            <Col span={6} style={{ textAlign: "right", paddingTop: 2 }}>
              { mode === "add" && isFocused &&
                  <Button
                    type="primary"
                    disabled={selectedIdx !== -1 ? true : false}
                    icon={<PlusOutlined style={{
                      fontSize: "inherit", display: "table", margin: "0 auto"
                    }} />}
                    style={{ fontSize: 12, width: 20, height: 20,
                      display: "inline-block", verticalAlign: "top"
                    }}
                    onClick={() => onAdd && onAdd(media)}
                  />
              }
            </Col>
          </Row>
        </VStack>
      </Card>
    </Tooltip>
  )
}

export const PairingEditGroupFileModal: React.FC<{
  groupManager: GroupManager,
  onGroupManagerUpdated?: (newGroupManager) => void,
  onClose: () => void
}> = ({ groupManager, onClose, onGroupManagerUpdated }) => {
  const [searchForm] = useForm()

  const [selectedMedias, setSelectedMedias] = useState<MediaDto[]>(groupManager.getMedias())
  //const [paginate, setPaginate] = useState<Paginate<MediaDto>>(new Paginate(1, 10, []));
  const [paginate, setPaginate] = useState<Paginate<MediaDto>>(new Paginate(1, 1000, []))

  const { mediaDtos: medias } = useMedia()

  useEffect(() => {
    if (!_.isEmpty(medias)) {
      setPaginate(new Paginate(1, 1000, medias))
    }
  }, [medias])


  function addMediaToPlaylist(media: MediaDto) {
    const playlistMedias = [...selectedMedias, media]

    setSelectedMedias(playlistMedias)
  }

  function removeMediaFromPlaylist(mediaId: string) {
    const playlistMedias = selectedMedias.filter(m => m.id !== mediaId)

    setSelectedMedias(playlistMedias)
  }

  const handleInfiniteOnLoad = () => {
    setPaginate(paginate.moveToNext())
  }

  function handleMediaAdd() {
    const newGroupManager = groupManager.setMedias(selectedMedias)

    onGroupManagerUpdated && onGroupManagerUpdated(newGroupManager)
    onClose()
  }

  const onSearch = (value: { fileName: string }) => {
    if (_.isEmpty(value.fileName)) {
      setPaginate(new Paginate<MediaDto>(1, 1000/*10*/, medias))
    } else {
      setPaginate(new Paginate<MediaDto>(1, 1000/*1000*/, medias.filter((item: MediaDto) => item.filename.includes(value.fileName))))
    }
  }

  const onMediaDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }

    if (result.source.index === result.destination.index) {
      return
    }

    const newMedias = [...selectedMedias]

    const tmp = newMedias[result.source.index]
    newMedias.splice(result.source.index, 1)
    newMedias.splice(result.destination.index, 0, tmp)

    setSelectedMedias(newMedias)
  }

  return (
    <Modal
      title="미디어 선택"
      width={getResponsiveModalWidth(1200)}
      okText="등록"
      cancelText="취소"
      onOk={handleMediaAdd}
      onCancel={onClose}
      visible
      bodyStyle={{ width: 1200, height: 600 }}
    >
      <Row style={{ width: "100%", height: "100%" }}>
        <Col span={20} style={{ height: 580, padding: 6 }}>
          <VStack w="100%" h="100%">
            <Box w="100%">
              <Form
                form={searchForm}
                onFinish={onSearch}
              >
                <Flex mb={12}>
                  <Center minW={80}>
                    <Typography.Text strong>
                      파일 이름
                    </Typography.Text>
                  </Center>
                  <Box flexGrow={1} mr={16}>
                    <Form.Item name="fileName" noStyle>
                      <Input /*style={{width: 160}}*/ allowClear/>
                    </Form.Item>
                  </Box>
                              
                  <Button
                    style={{
                      alignSelf: 'flex-end',
                    }}
                    key="submit"
                    type="primary"
                    htmlType="submit"
                    icon={<CheckOutlined/>}
                  >
                    검색
                  </Button>
                </Flex>
              </Form>
            </Box>
            <Spacer p={8} style={{ overflowX: "hidden", overflowY: "auto",
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)"
            }}>
              <InfiniteScroll
                initialLoad={true}
                pageStart={0}
                loadMore={handleInfiniteOnLoad}
                hasMore={false}
                useWindow={false}
              >
                <List
                  grid={{ gutter: 2, column: 7 }}
                  dataSource={paginate.getItems()}
                  renderItem={(media) => (
                    <List.Item>
                      <VideoItem
                        mode="add"
                        selectedIdx={selectedMedias.findIndex(m => m.id === media.id)}
                        media={media}
                        onAdd={(media) => addMediaToPlaylist(media)}/>
                    </List.Item>
                  )}
                />
              </InfiniteScroll>
            </Spacer>
          </VStack>
        </Col>
        <Col span={4} style={{ height: 580, padding: 8, backgroundColor: "#f0f0f0" }}>
          <VStack w="100%" h="100%">
            <Center w="100%" h={36} bg="#fffbe6" borderRadius={4} 
              boxShadow="0 2px 4px rgba(0, 0, 0, 0.15)"
            >
              <Text style={{ fontSize: 14, fontWeight: 700 }}>{groupManager.selectedGroup?.name}</Text>
              &nbsp;&nbsp;
              <Text style={{ fontSize: 12, fontWeight: 400 }}>({selectedMedias.length})</Text>
            </Center>
            <Box w="100%" h="100%" overflowX="auto">
              <Box>
                <DragDropContext onDragEnd={onMediaDragEnd}>
                  <Droppable droppableId="selectedMediaList">
                    { provided => (
                      <div ref={provided.innerRef} {...provided.droppableProps}
                        style={{ width: "100%", height: "100%", textAlign: "center"}}
                      >
                        {
                          selectedMedias.map((media, index) =>
                            <Draggable key={'d2'+media.id} draggableId={'d2'+media.id}
                              index={index}>
                              {
                                providedItem => (
                                  <div
                                    ref={providedItem.innerRef}
                                    {...providedItem.draggableProps}
                                    {...providedItem.dragHandleProps}
                                  >
                                    <Badge count={index+1} offset={[0, 10]}
                                      style={{ backgroundColor: '#52c41a' }}
                                    >
                                      <List.Item>
                                        <VideoItem
                                          mode="remove"
                                          selectedIdx={-1}
                                          media={media}
                                          onRemove={(mediaId) => removeMediaFromPlaylist(mediaId)}/>
                                      </List.Item>
                                    </Badge>
                                  </div>
                                )
                              }
                            </Draggable>
                          )
                        }
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </Box>
            </Box>
          </VStack>
        </Col>
      </Row>
    </Modal>
  )
}
