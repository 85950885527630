import React, { FC } from 'react'
import { GroupDto } from '../../api/graphql/group'
import { Space, Alert, Select, Divider, Tag } from 'antd'
import { GroupManager } from "../../models"

interface Props {
  groups: GroupDto[]
  groupManager: GroupManager
  onGroupAdded?: (groupId: string) => void
  onGroupSelected: (groupId: string) => void
  onGroupRemoved?: (groupId: string) => void
}

const PairingEditGroupList: FC<Props> = ({
  groups, groupManager, onGroupAdded, onGroupSelected, onGroupRemoved
}) => {

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      {
        onGroupAdded &&
          <Select
            bordered
            placeholder="그룹을 선택해 주세요."
            value="그룹을 선택해 주세요."
            dropdownMatchSelectWidth={false}
            onSelect={(value) => onGroupAdded(value)}
            style={{ width: "100%" }}
          >
            {
              groups.map(group => (
                <Select.Option
                  key={group.id}
                  value={group.id}
                  disabled={groupManager.creatableGroups.findIndex(({ id: groupId }) => groupId === group.id) !== -1}
                >
                  {group.name}
                </Select.Option>
              ))
            }
          </Select>
      }
      {
        onGroupAdded && 
          <Divider style={{ marginTop: "4px", marginBottom: "4px" }} />
      }
      
      {
        groupManager.creatableGroups.map((creatableGroup) => {
          return <Alert
            key={creatableGroup.id}
            message={creatableGroup.name}
            type="info"
            action={
              <Tag color="geekblue" style={{ width: "28px", textAlign: "right" }}>
                {creatableGroup.playlist.length}
              </Tag>
            }
            //showIcon
            closable={onGroupRemoved ? true : undefined}
            afterClose={() => onGroupRemoved && onGroupRemoved(creatableGroup.id)}
            onClick={() => onGroupSelected(creatableGroup.id)}
            
            style={creatableGroup.id === groupManager.selectedGroup?.id ? 
              {
                borderRadius: "4px",
                borderColor: "#808080", cursor: "pointer",
                boxShadow: "0px 0px 3px 1px rgba(32, 32, 32, 0.5)"
              }
              :
              {
                borderRadius: "4px",
                cursor: "pointer"
              }
            }
          />
        })
      }
    </Space>
  )
}


export { PairingEditGroupList }