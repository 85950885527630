import {McState, RtiMcDto} from "../api/graphql/rti-mc"
import fp from "lodash/fp"
import _ from "lodash"

export default class TvConnection {
  static getGroupStatistics(rtiMcs: RtiMcDto[] = [], filterPred: (rtiMc: RtiMcDto) => boolean = () => true) {

    const groups =
            fp.flow(
              fp.filter(filterPred),
              fp.flatMap(rtiMc => rtiMc.groups),
              fp.compact,
            )(rtiMcs)

    const initialValue = {
      totalTvCount: 0,
      connectCount: 0,
      unknownCount: 0,
      unconnectCount: 0,
      errorCount: 0,
      connectPercent: 0,
      unconnectPercent: 100,
      errorPercent: 0,
    }

    const countData = groups.reduce((acc, group) => {
      acc.totalTvCount += group.totalCount

      acc.connectCount += group.onlineCount
      acc.unconnectCount += group.offlineCount
      acc.errorCount += group.errorCount
      acc.unknownCount += group.unknownCount

      return acc
    }, initialValue)

    const safeDivider = countData.totalTvCount === 0 ? 1 : countData.totalTvCount
    countData.connectPercent = _.floor(countData.connectCount / safeDivider * 100)
    countData.unconnectPercent = _.floor(countData.unconnectCount / safeDivider * 100)
    countData.errorPercent = _.floor(countData.errorCount / safeDivider * 100)

    return countData
  }

  static getRtiMcStatistics(rtiMcs: RtiMcDto[] = []) {
    const groupByState = _.groupBy(rtiMcs, (rtiMc) => rtiMc.state)

    let connectRtiMcs: RtiMcDto[] = []
    if (!_.isEmpty(groupByState[McState.ONLINE])) {
      connectRtiMcs = groupByState[McState.ONLINE]
    }

    const acc = {
      totalRtiMcsCount: rtiMcs.length,
      connectRtiMcsCount: connectRtiMcs.length,
      connectRtiMcsPercent: 0
    }

    acc.connectRtiMcsPercent = _.floor(acc.connectRtiMcsCount / (acc.totalRtiMcsCount === 0 ? 1 : acc.totalRtiMcsCount) * 100)
    return acc
  }

  static getTvCount(rtiMc: RtiMcDto) {
    return rtiMc.groups.reduce((acc, group) => {
      acc += group.totalCount
      return acc
    }, 0)
  }
}
