import React, { FC } from 'react'
import { RtiMcDto } from "../../api/graphql/rti-mc"
import CellsComponent from './cells'
import ChannelsComponent from './channels'
import { Stack } from "@chakra-ui/layout"
import { useForm } from "antd/lib/form/Form"
import Form from "antd/lib/form"
import { RtiMcFilter } from "../page-status-shop"

interface Props {
  allRtiMcs: RtiMcDto[]
  setFilter: React.Dispatch<React.SetStateAction<RtiMcFilter>>
}

const RtiMcsStatistics: FC<Props> = ({ allRtiMcs, setFilter }) => {
  const [searchForm] = useForm()

  return (
    <Form form={searchForm}>
      <Stack>
        <ChannelsComponent rtiMcs={allRtiMcs} form={searchForm} />
        <CellsComponent rtiMcs={allRtiMcs} form={searchForm} />
      </Stack>
    </Form>
  )
}

export default RtiMcsStatistics
