import React, { FC, useState } from 'react'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { Cascader, Drawer, Form, Input, notification, Select } from 'antd'
import { GET_CHANNELS_CELLS_AMCODES } from 'api/graphql/channel'
import { ModalMode } from "../../types"
import DrawerFooter from "../../components/common/drawer-footer"
import _ from 'lodash'
import DrawerErrorAlert from "../../components/common/drawer-error-alert"
import { FormInstance } from "antd/lib/form"
import { ShopFormValues } from "./list"
import { ADD_SHOP, REMOVE_SHOP, UPDATE_SHOP } from "api/graphql/shop/mutations"
import { GET_SHOPS, ShopDto } from "../../api/graphql/shop"
import { afterRemoved } from "../../utils"
import { getResponsiveDrawerWidth } from 'common/layouts'
import { Smds, GET_SMDS } from 'api/graphql/smd'
import TextArea from "antd/lib/input/TextArea"

interface Props {
  shops: ShopDto[]
  form: FormInstance
  visible: boolean
  modalMode: ModalMode
  onClose: () => void
}

const ShopDrawer: FC<Props> = ({ form, shops, visible, onClose, modalMode }) => {
  const [shopError, setMutationError] = useState<ApolloError>()
  const { data: bundle } = useQuery(GET_CHANNELS_CELLS_AMCODES)
  const { data: dataSmds } = useQuery<Smds>(GET_SMDS)

  const channels = _.defaultTo(bundle?.channels, [])
  const cells = _.defaultTo(bundle?.cells, [])
  const amcodes = _.defaultTo(bundle?.amcodes, [])

  const [addShop] = useMutation(ADD_SHOP)
  const [updateShop] = useMutation(UPDATE_SHOP)
  const [removeShopMutation] = useMutation(REMOVE_SHOP, {
    update: afterRemoved({query: GET_SHOPS, queryResultKey: 'shops', mutationResultKey: 'removeShop'})
  })

  const cascaderOptions = _.uniqBy(cells.map(cell => {
    const region = cell.region

    return {
      value: region.id,
      label: region.name,
      children: cells.filter(cell =>
        cell.region.id === region.id
      ).map(cell => {
        return {
          value: cell.id,
          label: cell.name,
        }
      })
    }
  }), (o: { value: string }) => o.value)

  const getUpsertVariables = (values: ShopFormValues) => {
    const {
      mac,
      regionCell
    } = values

    const MAC = mac?.toUpperCase()

    return {
      ..._.omit(values, 'regionCell'),
      mac: MAC,
      regionId: regionCell[0],
      cellId: regionCell[1]
    }
  }

  function onShopCreate(values: ShopFormValues) {
    const variables = getUpsertVariables(values)

    const shopByName = shops.map(s => s.name).find(name => name === variables.name)
    if (shopByName) {
      setMutationError(new ApolloError({errorMessage: "이미 존재하는 매장 이름입니다."}))
      return
    }

    const shopByMac = shops.find(
      shop => shop.mediacenters && shop.mediacenters.length > 0 && 
        shop.mediacenters[0].mac === variables.mac
    )
    if (shopByMac) {
      setMutationError(new ApolloError({errorMessage: "이미 존재하는 MAC 주소입니다."}))
      return
    }

    const shopInput = {
      variables: {
        shopInput: {
          ...variables
        }
      }
    }

    addShop(shopInput)
      .then(() => {
        notification.success({
          message: `성공적으로 매장을 생성했습니다.`
        })

        cleanFormData()
        onClose()
      })
      .catch((e) => {
        notification.error({
          message: `매장을 추가 중에 에러가 발생했습니다.`
        })

        setMutationError(e)
      })
  }

  const onShopUpdate = () => {
    if (form.getFieldError('mac').length > 0) {
      notification.error({
        message: `MAC 정보가 올바르지 않습니다.`
      })
      return
    }

    const variables = getUpsertVariables(form.getFieldsValue())
    //const cell = cells.find(cell => cell.id === variables.regionCell[1])

    updateShop({
      variables: {
        ...variables
      }
    })
      .then(() => {
        notification.success({
          message: `성공적으로 매장을 업데이트했습니다.`
        })

        cleanFormData()
        onClose()
      })
      .catch((e) => {
        notification.error({
          message: `매장을 업데이트 중에 에러가 발생했습니다.`
        })

        setMutationError(e)
      })
  }

  const onShopRemove = () => {
    removeShopMutation({
      variables: {
        id: form.getFieldValue('id'),
      }
    })
      .then(() => {
        notification.success({
          message: `성공적으로 매장을 제거했습니다.`
        })

        cleanFormData()
        onClose()
      })
      .catch((e) => {
        notification.error({
          message: `매장을 제거 중에 에러가 발생했습니다.`
        })

        setMutationError(e)
      })
  }

  const cleanFormData = () => {
    form.resetFields()
    setMutationError(undefined)
  }

  const validateMac = async (_, value: string) => {
    // empty value is validated already at mac's rules (required)
    if (!value || value.length === 0) {
      return Promise.resolve()  // MAC 주소를 입력해 주세요.
    }

    const mac = value.toUpperCase()
    if (shops.find(shop => shop.mediacenters && shop.mediacenters.length > 0 && 
      shop.mediacenters[0].mac === mac))
    {
      return Promise.reject('MAC 주소가 중복되었습니다.')
    } else {
      return Promise.resolve()
    }
  }

  return (
    <Drawer
      title={`매장 ${modalMode}`}
      width={getResponsiveDrawerWidth(400)}
      placement="right"
      closable
      onClose={onClose}
      visible={visible}
      footer={
        <DrawerFooter
          formId="formAddShop"
          close={() => {
            onClose()
            setMutationError(undefined)
          }
          }
          isNew={modalMode === '추가'}
          onUpdate={onShopUpdate}
          onRemove={onShopRemove}
        />
      }
    >
      <Form
        id="formAddShop"
        form={form}
        layout="vertical"
        labelAlign="right"
        onFinish={onShopCreate}
        colon
      >
        <Form.Item name="id" hidden>
        </Form.Item>

        <Form.Item
          name="name"
          label="매장 이름"
          rules={[{required: true, message: '매장 이름을 입력해 주세요.'}]}
        >
          <Input placeholder="매장 이름을 입력해 주세요."/>
        </Form.Item>

        <Form.Item
          name="channelId"
          label="판매 경로"
          rules={[{required: true, message: '판매 경로를 선택해 주세요.'}]}
        >
          <Select
            placeholder="판매 경로를 선택해 주세요."
            dropdownMatchSelectWidth={false}
          >
            {
              channels.map(
                channel =>
                  <Select.Option
                    key={channel.id}
                    value={channel.id}
                  >
                    {channel.name}
                  </Select.Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          initialValue={[]}
          name="regionCell"
          label="지역"
          rules={[{required: true, message: '지역을 선택해 주세요.'}]}
        >
          <Cascader
            options={cascaderOptions}
            placeholder="지역을 선택해 주세요."
          />
        </Form.Item>

        <Form.Item
          name="mac"
          label="미디어센터 MAC 주소"
          rules={[
            //{ required: true, message: 'MAC 주소를 입력해 주세요. (ex. \'0014CDAABBCC\')' },
            { pattern: /[0-9A-Fa-f]{12}/, message: 'MAC 주소 형식에 맞지 않는 문자입니다.' },
            { len: 12, message: '12자리 MAC 주소를 입력해 주세요.' },
            { validator: validateMac }
          ]}
          validateTrigger="onChange"
        >
          <Input
            placeholder="MAC 주소 입력해 주세요."
            maxLength={12}
            style={{ textTransform: 'uppercase' }}
          />
        </Form.Item>
        
        {/*
        <Form.Item
          name="amcodeId"
          label="AMCODE"
        >
          <Select
            placeholder="AMCODE를 선택해 주세요."
            dropdownMatchSelectWidth={false}
          >
            {
              amcodes.map(
                amcode =>
                  <Select.Option
                    key={amcode.id}
                    value={amcode.id}
                  >
                    {amcode.name}
                  </Select.Option>
              )
            }
          </Select>
        </Form.Item>
        */}

        <Form.Item
          name="smdId"
          label="SMD"
        >
          <Select
            placeholder="SMD를 선택해 주세요."
            dropdownMatchSelectWidth={false}
          >
            {
              dataSmds?.smds.map(
                smd =>
                  <Select.Option
                    key={smd.id}
                    value={smd.id}
                  >
                    {smd.name}
                  </Select.Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          name="address"
          label="매장 주소"
        >
          <Input placeholder="매장 주소를 입력해 주세요."/>
        </Form.Item>
        <Form.Item
          name="code"
          label="매장 코드"
        >
          <Input placeholder="매장 코드를 입력해 주세요."/>
        </Form.Item>
        <Form.Item
          name="desc"
          label="매장 설명"
        >
          <TextArea maxLength={255} autoSize={{ minRows: 3, maxRows: 3 }} 
            placeholder="매장 설명을 입력해 주세요."
          />
        </Form.Item>
      </Form>

      <DrawerErrorAlert error={shopError}/>
    </Drawer>
  )
}


export { ShopDrawer }
