import useDtos, {DtoFilter, LoadingAndError} from "./useDtos"
import {afterProvided} from "../utils"
import React from "react"
import {GET_PAIRINGS, PairingDto, Pairings, SUBSCRIBE_PAIRING} from "../api/graphql/pairing"

interface Result extends LoadingAndError {
    pairings: PairingDto[];
    allPairings: PairingDto[];
    setFilter: React.Dispatch<React.SetStateAction<DtoFilter<PairingDto>>>;
}

const usePairing = (): Result => {
  const {allDtos: allPairings, dtos: pairings, setFilter, loading, error} = useDtos<PairingDto, Pairings>({
    query: GET_PAIRINGS,
    queryResultKey: 'pairings',
    subscription: SUBSCRIBE_PAIRING,
    updateQueryFunction: afterProvided({
      queryResultKey: 'pairings',
      subscriptionResultKey: 'subscribePairing',
      dataKey: 'pairing'
    })
  })

  return {allPairings, pairings, setFilter, loading, error}
}

export default usePairing
