import React, { FC, useState, useEffect } from 'react'
import { ChannelDto } from '../../api/graphql/channel'
import { DistManager } from 'models/dist-manager'
import { Space, Alert, Select, Divider, Tag } from 'antd'
import { useTableContext } from "../../contexts/TableContextProvider"

interface Props {
  channels: ChannelDto[]
  distManagerRef: React.MutableRefObject<{ 
    update: (id: string, distManager: DistManager) => void,
    map: Map<string, DistManager>
  }>
  onChannelChange: (channelId: string) => void
  needToRefresh: Map<string, boolean>
}

const DistEditChannelList: FC<Props> = ({ channels, distManagerRef, onChannelChange, needToRefresh }) => {

  const { drawerState } = useTableContext()

  const [managedChannelIds, setManagedChannelIds] = useState<string[]>([])
  const [selChannelId, setSelChannelId] = useState<string>('')
 
  useEffect(() => {
    if (drawerState.open.status) {
      const channelIds: string[] = []
      channels.forEach(channel => {
        const distManager = distManagerRef.current.map.get(channel.id)
        if (distManager && distManager.hasAnyChecked()) {
          channelIds.push(channel.id)
        }
      })
      setManagedChannelIds(channelIds)
    } else {
      setSelChannelId('')
      setManagedChannelIds([])
    }
  }, [drawerState])

  useEffect(() => {
    if (selChannelId === '' && managedChannelIds.length > 0) {
      setSelChannelId(managedChannelIds[0])
    }
    onChannelChange(selChannelId)
  }, [selChannelId, managedChannelIds])

  const onChannelAdded = (channelId: string) => {
    setManagedChannelIds([...managedChannelIds, channelId])

    const newDistManager = distManagerRef.current.map.get(channelId)!.selectAll({ checked: true })
    distManagerRef.current.update(channelId, newDistManager)

    setSelChannelId(channelId)
  }

  const onChannelRemoved = (channelId: string) => {
    setManagedChannelIds(managedChannelIds.filter(id => id !== channelId))

    const newDistManager = distManagerRef.current.map.get(channelId)!.selectAll({ checked: false })
    distManagerRef.current.update(channelId, newDistManager)

    setSelChannelId('')
  }

  const onChannelSelected = (channelId: string, index: number) => {
    setSelChannelId(channelId)
  }

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <Select
        bordered
        placeholder="판매 경로를 선택해 주세요."
        value="판매 경로를 선택해 주세요."
        dropdownMatchSelectWidth={false}
        onSelect={onChannelAdded}
        style={{ width: "100%" }}
      >
        {
          channels.map(channel => (
            <Select.Option
              key={channel.id}
              value={channel.id}
              disabled={managedChannelIds.findIndex(chId => chId === channel.id) !== -1}
            >
              {channel.name}
            </Select.Option>
          ))
        }
      </Select>
      <Divider style={{ marginTop: "4px", marginBottom: "4px" }} />
      {
        managedChannelIds.map((managedChannelId) => {
          const index = channels.findIndex(ch => ch.id === managedChannelId)
          const channel = index !== -1 ? channels[index] : null
          
          if (channel) {
            const distManager = distManagerRef.current.map.get(channel.id)
            return <Alert
              key={channel.id}
              message={channel.name}
              type={(distManager && distManager.hasAnyChecked()) ?
                "success" : "error"
              }
              action={
                <Tag color="geekblue">
                  {distManager ? distManager.getCheckedShopCount() : 0}/
                  {distManager ? distManager.getShopCount() : 0}
                </Tag>
              }
              showIcon
              closable
              afterClose={() => onChannelRemoved(channel.id)}
              onClick={() => onChannelSelected(channel.id, index)}
              style={channel.id === selChannelId ? 
                { borderColor: "#808080", cursor: "pointer",
                  boxShadow: "0px 0px 3px 1px rgba(32, 32, 32, 0.5)"
                }
                :
                { cursor: "pointer" }
              }
            />
          } else {
            return null
          }
        })
      }
    </Space>
  )
}


export { DistEditChannelList }