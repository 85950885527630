import React, { FC, useState, useRef } from 'react'
import { ApolloError, useMutation, useQuery } from '@apollo/client'
import { Drawer, Form, Input, Select, notification } from 'antd'
import { ModalMode } from "../../types"
import DrawerFooter from "../../components/common/drawer-footer"
import _ from 'lodash'
import DrawerErrorAlert from "../../components/common/drawer-error-alert"
import { FormInstance } from "antd/lib/form"
import { CellFormValues } from "./list"
import { ADD_CELL, REMOVE_CELL, UPDATE_CELL } from "api/graphql/cell/mutations"
import { GET_CELLS, CellDto } from "../../api/graphql/cell"
import { GET_REGIONS, Regions } from "../../api/graphql/region"
import { afterRemoved } from "../../utils"
import { getResponsiveDrawerWidth } from 'common/layouts'
import TextArea from "antd/lib/input/TextArea"
import useRtiMcs from "../../hooks/useRtiMcs"

interface Props {
  cells: CellDto[]
  form: FormInstance
  visible: boolean
  modalMode: ModalMode
  onClose: () => void
}

const CellEditDrawer: FC<Props> = ({ form, cells, visible, onClose, modalMode }) => {
  const [cellError, setMutationError] = useState<ApolloError>()
  // const { data: bundle } = useQuery(GET_CELLS_CELLS_AMCODES)

  const [addCell] = useMutation(ADD_CELL)
  const [updateCell] = useMutation(UPDATE_CELL)
  const [removeCell] = useMutation(REMOVE_CELL, {
    update: afterRemoved({query: GET_CELLS, queryResultKey: 'cells', mutationResultKey: 'removeCell'})
  })

  const { rtiMcs } = useRtiMcs()
  const { data: dataRegions } = useQuery<Regions>(GET_REGIONS)

  const regions = _.defaultTo(dataRegions?.regions, [])

  const selectedRegion = useRef<string>()

  const cellId = form.getFieldValue('id')

  function onCellCreate(values: CellFormValues) {
    const variables = { ...values }

    const cellByName = cells.map(s => s.name).find(name => name === variables.name)
    if (cellByName) {
      setMutationError(new ApolloError({errorMessage: "이미 존재하는 지역셀입니다."}))
      return
    }

    const cellInput = {
      variables: {
        ...variables
      }
    }

    addCell(cellInput)
      .then(() => {
        notification.success({
          message: `지역셀을 성공적으로 생성하였습니다.`
        })

        cleanFormData()
        onClose()
      })
      .catch((e) => {
        notification.error({
          message: `지역셀을 추가하는 중에 에러가 발생하였습니다.`
        })

        setMutationError(e)
      })
  }

  const onCellUpdate = () => {
    const variables = form.getFieldsValue()

    updateCell({
      variables: {
        ...variables
      }
    })
      .then(() => {
        notification.success({
          message: `지역셀을 성공적으로 수정하였습니다.`
        })

        cleanFormData()
        onClose()
      })
      .catch((e) => {
        notification.error({
          message: `지역셀을 수정하는 중에 에러가 발생하였습니다.`
        })

        setMutationError(e)
      })
  }

  const onCellRemove = () => {
    if (rtiMcs.find(rtiMcs => rtiMcs.mediacenter.cell.id === cellId)) {
      notification.error({
        message: `매장이 있는 지역셀은 삭제할 수 없습니다.`
      })
      return
    }

    removeCell({
      variables: {
        id: form.getFieldValue('id'),
      }
    })
      .then(() => {
        notification.success({
          message: `지역셀을 성공적으로 삭제하였습니다.`
        })

        cleanFormData()
        onClose()
      })
      .catch((e) => {
        notification.error({
          message: `지역셀을 삭제하는 중에 에러가 발생하였습니다.`
        })

        setMutationError(e)
      })
  }

  const cleanFormData = () => {
    form.resetFields()
    setMutationError(undefined)
  }

  const onRegionChanged = (regionId: string) => {
    if (selectedRegion.current !== regionId) {

      selectedRegion.current = regionId
    }
  }

  return (
    <Drawer
      title={`지역셀 ${modalMode}`}
      width={getResponsiveDrawerWidth(400)}
      placement="right"
      closable
      onClose={onClose}
      visible={visible}
      footer={
        <DrawerFooter
          formId="formAddCell"
          close={() => {
            onClose()
            setMutationError(undefined)
          }
          }
          isNew={modalMode === '추가'}
          onUpdate={onCellUpdate}
          onRemove={onCellRemove}
        />
      }
    >
      <Form
        id="formAddCell"
        form={form}
        layout="vertical"
        labelAlign="right"
        onFinish={onCellCreate}
        colon
      >
        <Form.Item name="id" hidden>
        </Form.Item>

        <Form.Item
          name="name"
          label="지역셀 이름"
          rules={[{required: true, message: '지역셀 이름을 입력해 주세요.'}]}
        >
          <Input placeholder="지역셀 이름을 입력해 주세요."/>
        </Form.Item>

        <Form.Item
          name="regionId"
          label="지역"
          rules={[{required: true, message: '지역을 선택해 주세요.'}]}
          validateTrigger="onSubmit"
        >
          <Select
            placeholder="지역을 선택해 주세요."
            dropdownMatchSelectWidth={false}
            onChange={onRegionChanged}
          >
            {
              regions.map(
                region =>
                  <Select.Option
                    key={region.id}
                    value={region.id}
                  >
                    {region.name}
                  </Select.Option>
              )
            }
          </Select>
        </Form.Item>

        <Form.Item
          name="desc"
          label="지역셀 설명"
        >
          <TextArea maxLength={255} autoSize={{ minRows: 3, maxRows: 3 }} 
            placeholder="지역셀 설명을 입력해 주세요."
          />
        </Form.Item>
      </Form>

      <DrawerErrorAlert error={cellError}/>
    </Drawer>
  )
}


export { CellEditDrawer }
