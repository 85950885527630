import {AppThunk} from 'app/store'
import {loginFailed, loginSuccess} from 'features/auth/slice'
import {API} from 'api'
import {urlJoin} from '../../utils/url'

export const login = (
  userid: string,
  password: string
): AppThunk => dispatch => {
  return API.auth.login(
    urlJoin(process.env.REACT_APP_API_URL, 'login'),
    userid,
    password
  )
    .then((result) => {dispatch(loginSuccess(result))})
    .catch((e) => dispatch(loginFailed(e.message)))
}
