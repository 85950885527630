import React, {useContext} from "react"

export type DrawerOpenState = { status: boolean, id?: string };

export type DrawerState = {
    open: DrawerOpenState;
    tableRow?: { id?: string, data: any };
    setTableRow?: React.Dispatch<React.SetStateAction<any>>;
}

interface TableContextProps {
    drawerState: DrawerState;
    setDrawerState: React.Dispatch<React.SetStateAction<DrawerState>>;
    closeDrawer: () => void;
}

const TableContext = React.createContext<TableContextProps>({} as TableContextProps)

export default TableContext
export const useTableContext = () => useContext(TableContext)

export const TableContextProvider: React.FC = ({children}) => {
  const [drawerState, setDrawerState] = React.useState<DrawerState>({open: {status: false}})

  function closeDrawer() {
    setDrawerState({open: {status: false}})
  }

  return (
    <TableContext.Provider
      value={{
        drawerState,
        setDrawerState,
        closeDrawer,
      }}>
      {children}
    </TableContext.Provider>
  )
}
