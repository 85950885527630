import React, {useState} from 'react'
import {useQuery,} from '@apollo/client'
import {ColumnsType} from 'antd/es/table'
import { Table, Tag, Tooltip } from 'antd'
import {CheckCircleOutlined, CloseCircleOutlined, MinusCircleOutlined} from '@ant-design/icons'
import {ConnRate} from 'components/mediacenter/conn-rate'
import {ConnStatus} from 'components/mediacenter/conn-status'
import {GET_RTI_MCS, McState, RtiMcDto, RtiMcs, TvState} from 'api/graphql/rti-mc'
import {RtiMcDetail} from './detail'
import TvConnection from "../../models/tv-connection"
import useGroups from "../../hooks/useGroups"
import { sortByNumber } from "../../utils"
import { createdAtColumn } from "../../common/created-at-column"

function RtiMcList() {
  const {groups: dataGroups} = useGroups()
  const {data: dataRtiMcs} = useQuery<RtiMcs>(GET_RTI_MCS)

  const columns: ColumnsType<RtiMcDto> = [
    {
      title: 'no',
      key: 'index',
      width: 40,
      render: (text, record, index) => index + 1,
    },
    {
      title: 'MAC 주소',
      dataIndex: ['mediacenter', 'mac'],
      key: 'mac',
      width: 120,
      render: (text, record) =>
        <a onClick={() => {
          handleItemClick(record.id)
        }} href="#/">{text}</a>
    },
    {
      title: '판매 경로',
      dataIndex: ['mediacenter', 'channel', 'name'],
      key: 'channelName',
      width: 100,
    },
    {
      title: '매장',
      dataIndex: ['mediacenter', 'shop', 'name'],
      key: 'shopName',
      width: 100,
    },
    {
      title: '지역',
      dataIndex: ['mediacenter', 'cell', 'name'],
      key: 'cellName',
      width: 100,
    },
    {
      title: '상태',
      dataIndex: 'state',
      key: 'state',
      width: 100,
      align: 'center',
      render: state => {
        if (state === McState.UNKNOWN || state === McState.OFFLINE) {
          return <Tag icon={<MinusCircleOutlined/>} color='#bfbfbf'>OFF</Tag>
        } else if (state === McState.ONLINE) {
          return <Tag icon={<CheckCircleOutlined/>} color='#52c41a'>ON</Tag>
        } else {
          return <Tag icon={<CloseCircleOutlined/>} color='#f5222d'>ERR</Tag>
        }
      }
    },
    {
      title: 'TV 대수',
      dataIndex: 'groups',
      key: 'tvCount',
      width: 80,
      align: 'center',
      render: (groups, rtiMcDto) => <span>{TvConnection.getTvCount(rtiMcDto)}</span>
    },
    {
      title: '접속률',
      dataIndex: 'groups',
      key: 'connRate',
      width: 80,
      align: 'center',
      render: groups => {
        const counts = groups.reduce((acc, group) => {
          group.tvs.forEach(tv => {
            if (tv.state === TvState.UNKNOWN || tv.state === TvState.OFFLINE) {
              acc.offline++
            } else if (tv.state === TvState.ONLINE) {
              acc.online++
            } else {
              acc.error++
            }
            acc.total++
          })

          return acc
        }, {total: 0, online: 0, offline: 0, error: 0})

        return <ConnRate
          totalCount={counts.total}
          onlineCount={counts.online}
          offlineCount={counts.offline}
          errorCount={counts.error}
          disabled={false}
        />
      }
    },
    {
      title: 'TV그룹',
      dataIndex: 'groups',
      key: 'connStatus',
      width: 160,
      render: groups => {
        const statuses = groups.map(group => {
          const status = group.tvs.reduce((acc, tv) => {
            if (tv.state === TvState.ONLINE) {
              acc.active++
            } else {
              acc.inactive++
            }

            return acc
          }, {active: 0, inactive: 0})

          const gr = dataGroups.find(g => g.id === group.id)
          return <ConnStatus
            name={gr?.name}
            color="#a0a0a0"
            activeCount={status.active}
            inactiveCount={status.inactive}
          />
        })

        // return <Space size="small" style={{ margin: 0 }}> {statuses} </Space>
        return <div style={{display: 'inline'}}> {statuses} </div>
      },
    },
    createdAtColumn('createdAt', (e1, e2) => sortByNumber(e1.createdAt, e2.createdAt)),
  ]
  const [selRtiMc, setSelRtiMc] = useState<RtiMcDto>()
  const [isDetailVisiable, setDetailVisible] = useState(false)

  function handleItemClick(mediacenterId) {
    const rtiMc = dataRtiMcs?.rtiMcs.find(rtiMc => rtiMc.id === mediacenterId)
    setSelRtiMc(rtiMc)

    setDetailVisible(true)
  }

  function handleDetailClose() {
    setDetailVisible(false)
  }

  return (
    <div>
      <div>
        <Table
          size="small"
          rowKey="id"
          columns={columns}
          dataSource={dataRtiMcs?.rtiMcs}
          pagination={{showSizeChanger: true, pageSize: 10}}
        />
      </div>
      <RtiMcDetail
        rtiMc={selRtiMc}
        visible={isDetailVisiable}
        onClose={handleDetailClose}
        dataGroups={dataGroups}
      />
    </div>
  )
}

export {RtiMcList}
