import React, {FC} from "react"
import {Stack, Box} from "@chakra-ui/layout"

const DrawerSection: FC<{ title: string}> = ({ title, children }) => {

  return (
    <Stack mt={10}>
      <Box
        p={2}
        pl={6}
        color="#404040"//"solid 6px #002040"
        borderLeft="solid 6px #808080"//"solid 6px #002040"
        borderBottom="solid 1px #e0e0e0"
        fontWeight="bold"
      >
        {title}
      </Box>
      <Box
        p={8}
      >
        {children}
      </Box>
    </Stack>
  )
}

export default DrawerSection