import {GET_FIRMWARES, FirmwareDto, Firmwares, SUBSCRIBE_FIRMWARE} from "../api/graphql/firmware"
import useDtos, {LoadingAndError} from "./useDtos"
import {afterProvided} from "../utils"

interface Result extends LoadingAndError {
    firmwares: FirmwareDto[];
}

const useFirmwares = (): Result => {
  const {allDtos: firmwares, loading, error} = useDtos<FirmwareDto, Firmwares>({
    query: GET_FIRMWARES,
    queryResultKey: 'firmwares',
    subscription: SUBSCRIBE_FIRMWARE,
    updateQueryFunction: afterProvided({
      queryResultKey: 'firmwares',
      subscriptionResultKey: 'subscribeFirmware',
      dataKey: 'firmware'
    })
  })

  return {firmwares, loading, error}
}

export default useFirmwares
