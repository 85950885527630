import {ApolloError} from "@apollo/client"
import _ from "lodash"
import {GraphQLError} from "graphql"

export class GraphqlErrorFormatter {
  constructor(private readonly error: ApolloError) {
  }

  toString(): string {
    const error = _.head(this.error.graphQLErrors)
    if (error) {
      return this.errorToString(error)
    } else {
      return this.error.message
    }
  }

  private errorToString(error: GraphQLError): string {
    if (error.message.includes('duplicate key')) {
      return "중복된 항목이 존재합니다."
    }

    return "알 수 없는 오류가 발생했습니다. 관리자에게 문의해주세요."
  }
}
