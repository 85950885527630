import {Stack} from "@chakra-ui/layout"
import React, {FC} from "react"
import Typography from "antd/lib/typography"
import {Divider} from "antd"
import {ReportList} from "./report"
import useReports from "../hooks/useReports"

const PageReport: FC = () => {
  const {reports} = useReports()

  return (
    <Stack>
      <Typography.Title level={3}>
                리포트 관리
      </Typography.Title>

      <Divider/>

      <ReportList reports={reports}/>
    </Stack>
  )
}


export {PageReport}
