import React from 'react'
import { RtiMcList } from './status-mediacenter'

function PageStatusMediacenter() {
  return (
    <div>
      <RtiMcList />
    </div>
  )
}


export { PageStatusMediacenter }
