import React, { useRef } from 'react'
import { Button, notification, Spin, Table } from 'antd'
import { ContainerOutlined} from '@ant-design/icons'
import { DistDto } from 'api/graphql/dist'
import { DistEdit} from './edit'
import { ColumnsType } from 'antd/lib/table'
import { useTableContext } from '../../contexts/TableContextProvider'
import { Box, Stack } from '@chakra-ui/layout'
import useDists from '../../hooks/useDists'
import format from 'date-fns/format'
import usePageSize from '../../hooks/usePageSize'
import { PairingDrawerId, PairingEdit } from '../pairing/edit'
import { columnText } from '../../components/table'

export const DistDrawerId = 'dist'

function DistList() {
  const {setDrawerState} = useTableContext()

  const { pageSize, setPageSize } = usePageSize('dist')

  function handleNameClicked(distDto: DistDto) {
    setDrawerState({
      open: { id: DistDrawerId, status: true },
      tableRow: { id: distDto.id, data: distDto }
    })
  }

  function handlePairingClicked(dist: DistDto) {
    setDrawerState({
      open: { id: PairingDrawerId, status: true },
      tableRow: { id: dist.pairing.id, data: dist.pairing }
    })
  }

  const columns: ColumnsType<DistDto> = [
    columnText("배포 식별 이름", "name", "name", 200, "left",
      { searchInputRef: useRef(null) },
      (record) => { handleNameClicked(record) }
    ),
    columnText("편성표", ["pairing", "name"], "pairing", 200, "left",
      { searchInputRef: useRef(null) },
      (record) => { handlePairingClicked(record) }
    ),
    {
      title: '배포 적용 시각',
      dataIndex: 'appliedAt',
      key: 'appliedAt',
      width: 150,
      render: appliedAt => appliedAt && <span>{format(new Date(appliedAt), "yyyy/MM/dd HH:mm")}</span>

    },
    {
      title: '예약 시각',
      dataIndex: 'reserveAt',
      key: 'reserveAt',
      width: 150,
      render: reserveAt => reserveAt && <span>{format(new Date(reserveAt), "yyyy/MM/dd HH:mm")}</span>
    },
    {
      title: '작성 시각',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: createdAt => <span>{format(new Date(createdAt), "yyyy/MM/dd HH:mm:ss")}</span>
    },
  ]

  const {allDists, loading, error} = useDists()

  if (loading) {
    return <Spin size="large" />
  }
  if (error) {
    notification.error({
      message: `편성표를 불러오는 중에 에러가 발생했습니다.`
    })
  }

  function handleAdd() {
    setDrawerState({open: {id: DistDrawerId, status: true}})
  }

  return (
    <Stack>
      <Box
        w="100%"
        textAlign="right"
        mb="10px"
      >
        <Button
          type="primary"
          icon={<ContainerOutlined/>}
          onClick={handleAdd}
        >
          추가
        </Button>
      </Box>

      <Table
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={allDists}
        scroll={{ x: 1000/*true*/ }}
        onChange={(pagination) => setPageSize(pagination.pageSize)}
        pagination={{ showSizeChanger: true, pageSize }}
      />

      <DistEdit editable/>
      <PairingEdit editable={false}/>
    </Stack>
  )
}

export { DistList }
