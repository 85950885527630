import {gql} from "@apollo/client"

export const ADD_PAIRING = gql`
  mutation AddPairing(
    $pairingInput: PairingInput!
  ) {
    addPairing(
      pairingInput: $pairingInput
    ) {
      id
    }
  }
`

export const UPDATE_PAIRING = gql`
    mutation UpdatePairing(
        $pairingInput: PairingInput!
    ) {
        updatePairing(
            pairingInput: $pairingInput
        ) {
            id
        }
    }
`
export const REMOVE_PAIRING = gql`
    mutation RemovePairing(
        $id: ID!
    ) {
        removePairing(
            id: $id
        )
    }
`
