import _ from "lodash"

type SortDirection = "ASC" | "DESC";

export function sortByCreatedAt<T extends {createdAt: number}>(xs: T[], direction: SortDirection = "DESC"): T[] {
  const sorted = _.sortBy(xs, (x) => new Date(x.createdAt))
  if (direction === "DESC") {
    return _.reverse(sorted)
  }
  return sorted
}

export function sortByText(str1?: string, str2?: string): number {
  if (str1 && str2) {
    return str1.localeCompare(str2)
  }

  return -1
}

export function sortByNumber(number1: number, number2: number): number {
  return number1 - number2
}
