import React, { FC, useEffect } from 'react'
import { Button, Space, Table } from 'antd'
import { Box, } from '@chakra-ui/layout'
import { ColumnsType } from 'antd/es/table'
import { ShopOutlined } from '@ant-design/icons'
import { MediaDto } from 'api/graphql/media'
import { MediaUpload, UploadDrawerId } from './upload'
import { MediaDetail } from './detail'
import { useTableContext } from '../../contexts/TableContextProvider'
import usePageSize from '../../hooks/usePageSize'
import { isMobile } from 'react-device-detect'

interface Props {
  medias: MediaDto[]
  columns: ColumnsType<MediaDto>
  insideDrawer?: boolean
}

const MediaList: FC<Props> = ({ medias, /*insideDrawer,*/ columns }) => {
  const { drawerState, setDrawerState } = useTableContext()
  const { pageSize, setPageSize } = usePageSize('media')

  useEffect(() => {
    if (drawerState!.open.status === true) {
      const media: MediaDto = drawerState!.tableRow?.data
      const found = medias.find(m => m.id === media.id)
      if (found) {
        setDrawerState({ open: { status: true, id: drawerState!.open.id }, tableRow: { data: found }})
      }
    }
  }, [medias])

  function handleAdd() {
    setDrawerState({ open: {status: true, id: UploadDrawerId }})
  }

  return (
    <>
      <Box w="100%" textAlign="right" mb="10px">
        <Space direction="horizontal">
          <Button type="primary" icon={<ShopOutlined/>}
            onClick={handleAdd} disabled={isMobile ? true : false}
          >
            추가
          </Button>
        </Space>
      </Box>
      <Table
        size="small"
        rowKey="id"
        columns={columns}
        dataSource={medias}
        scroll={{ x: 1000/*true*/ }}
        onChange={(pagination) => setPageSize(pagination.pageSize)}
        pagination={{ showSizeChanger: true, pageSize }}
      />
      <MediaUpload />
      <MediaDetail />
    </>
  )
}

export { MediaList }
