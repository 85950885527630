import React, { useState } from 'react'
import { Drawer, } from 'antd'
import { MediaFile, MediaUploadExecutor } from './upload-executor'
import { MediaUploadList } from './upload-list'
import { useTableContext } from "../../contexts/TableContextProvider"

export const UploadDrawerId = 'upload'

export type MediaFileList = { fileList: MediaFile[] }

const MediaUpload: React.FC = () => {
  const {drawerState, setDrawerState} = useTableContext()
  const [fileList, setFileList] = useState<MediaFile[]>([])

  function handleClose() {
    setDrawerState({ open: { status: false, id: UploadDrawerId }})
    setFileList([])
  }

  const visible = drawerState.open.status && drawerState.open.id === UploadDrawerId

  return (
    <Drawer
      title="컨텐츠 업로드"
      width={600}
      placement="right"
      closable
      onClose={handleClose}
      visible={visible}
    >
      <MediaUploadExecutor fileList={fileList} setFileList={setFileList} />
      <MediaUploadList fileList={fileList} setFileList={setFileList} />
    </Drawer>
  )
}


export { MediaUpload }
