import { GET_REGIONS, RegionDto, Regions, SUBSCRIBE_REGION } from "../api/graphql/region"
import useDtos, { LoadingAndError } from "./useDtos"
import { afterProvided } from "../utils"

interface Result extends LoadingAndError {
  allRegions: RegionDto[]
  regions: RegionDto[]
}

const useRegions = (): Result => {
  const { allDtos: allRegions, dtos: regions, loading, error } = useDtos<RegionDto, Regions>({
    query: GET_REGIONS,
    queryResultKey: 'regions',
    subscription: SUBSCRIBE_REGION,
    updateQueryFunction: afterProvided({
      queryResultKey: 'regions',
      subscriptionResultKey: 'subscribeRegion',
      dataKey: 'region'
    })
  })

  return { allRegions, regions, loading, error }
}

export default useRegions
