import React, { FC } from 'react'
import { Empty, Typography, Tag } from 'antd'
import { CloseCircleOutlined, PaperClipOutlined } from '@ant-design/icons'
import { MediaFile, UploadStatus } from "./upload-executor"
import { Box, Stack } from "@chakra-ui/layout"
import Button from "antd/lib/button"
import { toMegabytes } from "../../utils"

interface ItemProps {
  file: MediaFile
  onItemDelete: (uid: string) => void
  uploadStatus: UploadStatus
}

const MediaUploadExecutorFilelistItem: FC<ItemProps> = ({ file, onItemDelete, uploadStatus }) => {

  return (
    <li style={{ paddingLeft: 4, verticalAlign: 'middle', listStyleType: "none" }}>
      <Stack align="center" isInline>
        <PaperClipOutlined style={{ color: file.isNew ? "#000000" : "#ff7f00" }}/>
        <Box mx="4px" fontSize={12}>{file.name}</Box>

        <Typography.Text>({toMegabytes(file.size)}MB)</Typography.Text>

        <Button
          type="link"
          disabled={uploadStatus === 'uploading'}
          onClick={() => {
            onItemDelete(file.uid)
          }}><CloseCircleOutlined/></Button>
        {file.status === 'error' && file?.uploadErrorMessage && (
          <Typography.Text type="danger" style={{marginLeft: 16}}>{file.uploadErrorMessage}</Typography.Text>)}
      </Stack>
    </li>
  )
}

interface Props {
  files: MediaFile[]
  uploadStatus: UploadStatus
  onItemDelete: (uid: string) => void
}

const MediaUploadExecutorFilelist: FC<Props> = ({ files, onItemDelete, uploadStatus }) => {
  return (
    <div style={{marginTop: 8, height: 134, overflow: 'auto'}}>
      {
        (files.length === 0) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Files"/>
      }
      <ul style={{ margin: 0, padding: 0 }}>
        {
          files.map((file) =>
            <MediaUploadExecutorFilelistItem
              key={file.uid}
              uploadStatus={uploadStatus}
              file={file}
              onItemDelete={onItemDelete}
            />
          )
        }
      </ul>
    </div>
  )
}

export { MediaUploadExecutorFilelist }
