import {RtiMcDto} from "../api/graphql/rti-mc"
import _ from "lodash"
import {format, isAfter} from "date-fns"

interface UpdateStatus {
  assignedCount: number
  confirmedCount: number
  updateCompletePercent: number
  confirmedAt?: string
}

class PairingUpdateStatus {
  static getUpdateStatus(rtiMcs: RtiMcDto[]): UpdateStatus {
    const defaultValues: UpdateStatus = {
      assignedCount: 0,
      confirmedCount: 0,
      updateCompletePercent: 0,
      confirmedAt: undefined,
    }

    if (_.isEmpty(rtiMcs)) {
      return defaultValues
    }
    const acc = rtiMcs.reduce((acc, rtiMc) => {
      if (rtiMc.media) {
        acc.assignedCount += rtiMc.media.assignedCount
        acc.confirmedCount += rtiMc.media.confirmedCount
      }

      return acc
    },
    defaultValues)

    acc.updateCompletePercent = Math.floor(
      acc.assignedCount === 0 || acc.confirmedCount === 0 ? 0 : 
        (acc.confirmedCount > acc.assignedCount) ? 100 : acc.confirmedCount / acc.assignedCount * 100
    )
    
    const sorted = rtiMcs
      .filter(r => !_.isNil(r.media))
      .sort((r1, r2) => isAfter(new Date(r1.media!.confirmedAt), new Date(r2.media!.confirmedAt)) ? -1 : 1)

    if (sorted.length > 0) {
      acc.confirmedAt = format(new Date(sorted[0].media!.confirmedAt), "yyyy/MM/dd")
    }

    return acc
  }
}

export default PairingUpdateStatus
