import React from 'react'
import {DashboardSummary} from './dashboard'
import {useDispatch} from "react-redux"
import {storeGroups} from 'features/group'
import useGroups from "../hooks/useGroups"

function PageDashboard() {
  const dispatch = useDispatch()
  const { groups } = useGroups()

  React.useEffect(() => {
    if (groups) {
      dispatch(storeGroups(groups))
    }
  }, [groups])

  return (
    <DashboardSummary />
  )
}

export { PageDashboard }
