import { ApolloCache, ApolloClient, DocumentNode } from "@apollo/client"
import { FetchResult } from "@apollo/client/link/core"
import _ from "lodash"

interface Args {
  query: DocumentNode
  queryResultKey: string
  mutationResultKey: string
}

interface Entity {
  id: string
}

export function afterCreated<TQueryResult>({query, queryResultKey, mutationResultKey}: Args) {
  return (cache: ApolloCache<any>, mutationResult: FetchResult<any>) => {
    const data = cache.readQuery<TQueryResult>({query})

    if (data) {
      const updated = {}
      updated[queryResultKey] = [...data[queryResultKey], mutationResult.data[mutationResultKey]]

      cache.writeQuery({
        query,
        data: updated,
      })
    }
  }
}

export function afterUpdated<TQueryResult>({query, queryResultKey, mutationResultKey}: Args) {
  return (cache: ApolloCache<any>, mutationResult: FetchResult<any>) => {
    const data = cache.readQuery<TQueryResult>({query})
    if (data) {
      const updatedObj = mutationResult.data[mutationResultKey]

      const i = data[queryResultKey].findIndex(a => a.id === updatedObj.id)
      if (i !== -1) {
        const a = _.cloneDeep(data[queryResultKey])
        a[i] = updatedObj

        const updated = {}
        updated[queryResultKey] = a

        cache.writeQuery({
          query,
          data: updated,
        })
      }
    }
  }
}

export function afterRemoved<TQueryResult>({query, queryResultKey, mutationResultKey}: Args) {
  return (cache: ApolloCache<any>, mutationResult: FetchResult<any>) => {
    const data = cache.readQuery<TQueryResult>({query})
    if (data) {
      const a: Entity[] = _.cloneDeep(data[queryResultKey])
      _.remove(a, dto => dto.id === mutationResult.data[mutationResultKey])

      const updated = {}
      updated[queryResultKey] = a

      cache.writeQuery({
        query,
        data: updated,
      })
    }
  }
}

export function afterProvided<TQuery>({queryResultKey, subscriptionResultKey, dataKey}) {
  return (query: DocumentNode, {
    client,
    subscriptionData
  }: { client: ApolloClient<any>, subscriptionData: { data?: any } }) => {
    const data = subscriptionData.data[subscriptionResultKey]
    const prevData = client.cache.readQuery<TQuery>({ query })

    let updated: typeof data = prevData
    switch (data.op) {
    case "CREATE": {
      if (prevData) {
        if (prevData[queryResultKey].findIndex(a => a.id === data[dataKey].id) === -1) {
          updated = {
            [queryResultKey]: [data[dataKey], ...prevData[queryResultKey]]
          }
        }
      } else {
        updated = {
          [queryResultKey]: [data[dataKey]]
        }
      }

      break
    }
    case "UPDATE": {
      if (prevData) {
        const i = prevData[queryResultKey].findIndex(a => a.id === data[dataKey].id)

        if (i !== -1) {
          const a = _.cloneDeep(prevData[queryResultKey])
          a[i] = data[dataKey]

          updated = {[queryResultKey]: a}
        }
      }
      break
    }
    case "DELETE": {
      if (prevData) {
        updated = {
          [queryResultKey]:
            _.values(
              _.omitBy(prevData[queryResultKey], o => o.id === data[dataKey].id)
            )
        }
      }

      break
    }
    default:
      updated = prevData
    }

    client.writeQuery({query, data: updated})
    // client.writeQuery({query, data: prevData});
  }
}
